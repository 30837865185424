<template>






    <div class="topcontent-div">
      <!-- showNetworkPopUp --><transition name="fade">
        <div v-show="showNetworkPopUp" class="modal-overlayfrombottomblur" style="z-index: 999;">
          <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 80%; height: 85%"> 
            <!-- put in table for dev speed need to learn more about flex -->
            <table border=0 width=85%> 
              <tr>
                <td>
                  <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
                    Lost internet connection
                  </div>
                </td>
              </tr>
              <tr v-if="this.paymentInProgress===true">
                <td>
                  <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
                    {{ this.transactionInterruptedMsg }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
                    Your connection to the internet has been interrupted... this message will disappear as soon as ThankU detects your internet connection has been restored
                  </div>
                </td>
              </tr>
            </table>       
          </div>
        </div>
      </transition>
      <!-- showPopUpOk --><transition name="fade">
        <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 998;">
          <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 80%"> 
            <!-- put in table for dev speed need to learn more about flex -->
            <table border=0 width=85%> 
              <tr>
                <td>
                  <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
                  <span v-if="this.showPopupPreTitle === true" ref="preTitle" style="display: inline-block">{{ this.popUpMsgPreTitle }}&nbsp;<img style="width: clamp(20px, 45%, 28px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></span><br v-if="this.showPopupPreTitle === true"><br v-if="this.showPopupPreTitle === true"><span :style="{ 'visibility': this.setMainPopupMsgVisibility }">{{ this.popUpMsgTitle }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', 'visibility': this.setMainPopupMsgVisibility, }">                 
                  {{ this.popUpMsgBody }}
                  </div>
                </td>
              </tr>
              <tr v-if="this.showProgress===true">
                <td>
                  <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">{{ this.progressIndicator }}</div>
                </td>
              </tr>
              <tr v-if="this.showTwoOptionsOnMainPopup===false && this.hideOKButton === false">
                <td style="height: 10vh">
                  <div><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOK();">Ok</button></div>
                </td>
              </tr>
              <tr v-if="this.showTwoOptionsOnMainPopup===true" >
                  <td>
                    <table width=100% :style="{ 'visibility': this.setMainPopupMsgVisibility }">
                      <tr>
                       
                        <td style="height: 10vh">
                            <div><button class="widebtn" ref="show-popup-ok" style="width: 70%; height: 6vh; " @click="this.doPopUpCancel();">No</button></div>
                        </td>
                         <td style="height: 10vh">
                          <div><button class="widebtn" ref="show-popup-ok" style="width: 70%; height: 6vh; " @click="this.doPopUpOK();">Yes</button></div>
                        </td>
                      </tr>
                    </table>
                  </td>
              </tr>
            </table>       
          </div>
        </div>
      </transition>
      <!-- showPopUpTwoOptions --><transition name="fade">
        <div v-show="showPopUpTwoOptions" class="modal-overlayfrombottomblur" style="z-index: 997;">
          <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 80%"> 
            <!-- put in table for dev speed need to learn more about flex -->
            <table border=0 width=85%> 
              <tr>
                <td>
                  <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
                  {{ this.PopUpTwoOptionsTitle }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
                  {{ this.PopUpTwoOptionsMessage }}
                  </div>
                </td>
              </tr>
              <tr>
                <td style="padding-top:2vh; padding-bottom-1vh">
                  <div><button class="widebtn" style="width: 95%; height: 5vh; " @click="doShowLogin()">Log in</button></div>
                </td>
              </tr>
              <tr>
                <td style="padding-top:1vh; padding-bottom-2vh">
                  <div><button class="widebtn" style="width: 95%; height: 5vh; " :style="{ 'font-size': this.fontSizePopUpMsg * 1.1 + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }" @click="this.showPopUpTwoOptions=false;">choose another email</button></div>
                </td>
              </tr>
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
            </table>       
          </div>
        </div>
      </transition>
      <!-- showLogin --><transition name="fade">
        <div v-show="showLogin" class="modal-overlayfrombottomblur" style="z-index: 996;">
          <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh"> 
            <!-- put in table for dev speed need to learn more about flex -->
            <table border=0 width=90%> 
              <tr>
                <td>
                    <div class="divl" style="height: 6vh"><button class="text-only-button cancel" @click="this.showLogin=false">Cancel</button></div>
                </td>
              </tr>
             <tr>
                <td>
                  <div class="wrapper-todiv" style="height: 10vh">
                    <div style="" >
                      <img class="tulogo"  style="height: 7vh;" alt="ThankU logo" src="../assets/tutxtlogo.png">
                    </div>
                    
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style="height: 6vh; display: flex;">
                    <input type="email" class="standard-textbox input" ref="email-login" placeholder="email" disabled>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style="height: 6vh; display: flex;">
                    <input data-private="ipsum" type="textbox" class="standard-textbox input" ref="pw-login" placeholder="password" autocorrect="off" autocapitalize="none" autocomplete="off">
                  </div>
                </td>
              </tr>
              <tr v-if="showPasswordResetEmailSent">
                <td>
                  <div class="lt-blue notes-text">
                  If you have an email registered with ThankU, please check your email for the password reset email just sent to you - just click the link it contains and enter a new password, then come back to this tab to login with your new password.
                  </div><br>
                </td>
              </tr>
              <tr>
                <td style="height: 12vh">
                  <div><button class="widebtn" style="width: 70%; height: 5vh;" @click="this.doLoginDB();">Log in</button></div>
                </td>
              </tr>
              <tr>
                <td>
                    <div class="divc tb-padding"><button class="text-only-button padded" ref="forgotten-password" @click="this.showForgottenPassword=true">forgotten your password?</button></div>
                </td>
              </tr>
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
            </table>       
          </div>
        </div>
      </transition>
      <!-- showForgottenPassword --><transition name="fade">
        <div v-show="showForgottenPassword" class="modal-overlayfrombottomblur" style="z-index: 997;">
          <div class="modal" style="height: 65vh;"> 
            <!-- put in table for dev speed need to learn more about flex -->
            <table border=0 width=90%> 
              <tr>
                <td>
                    <div class="divl"><button class="text-only-button cancel" @click="this.showForgottenPassword=false;">Cancel</button></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="lt-blue txtc"  :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
                  Reset password
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style="height: 6vh; display: flex;">
                    <input type="email" class="standard-textbox input" ref="email-for-reset" placeholder="email">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div><button class="widebtn" style="width: 75%; height: 5vh; " @click="this.doResetPassword();">Reset Password</button></div>
                </td>
              </tr>
              <tr>
                <td style="display: flex;">
                  <div class="lt-blue notes-text">
                  If you have an email registered with ThankU, you will receive a password reset email - just click the link it contains and enter a new password, then login here with your new password.
                  </div><br>
                </td>
              </tr>
             
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
            </table>       
          </div>
        </div>
      </transition>
      <!-- showEmailForReceiptInput --><transition name="screenslideup">
        <div v-show="showEmailForReceiptInput" class="modal-overlayfrombottomblur">
          <div class="modal" style="height: 65vh;"> 
            <!-- put in table for dev speed need to learn more about flex -->
            <table border=0 width=90%> 
              <tr>
                <td>
                    <div class="divl"><button class="text-only-button cancel" ref="cancel-receipt" @click="this.doCancelShowEmailForReceiptInput();">Cancel</button></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="lt-blue txtc"  :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
                  Receipt?
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style="height: 6vh; display: flex;">
                    <input type="email" class="standard-textbox input" ref="new-email" placeholder="email address...">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div><button class="widebtn" style="width: 65%; height: 5vh; " ref="send-receipt" @click="this.doSaveEmail();">Send receipt</button></div>
                </td>
              </tr>
              <tr>
                <td style="display: flex;">
                  <div class="lt-blue notes-text">
                  If you would like a payment receipt, please enter your email address. ThankU will remember your email address so you don't have to provide it again next time. We won't share your email address with anybody, period.
                  </div><br>
                </td>
              </tr>
              <tr>
                <td>
                    <div class="divc tb-padding"><button class="text-only-button padded" ref="not-now-receipt" @click="this.doCancelShowEmailForReceiptInput();">not now</button></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="divc tb-padding"><button class="text-only-button padded" ref="dont-show-again-receipt" @click="this.doShowEmailForReceiptInputDontShowAgain();">don't show again</button></div>
                </td>
              </tr>
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
            </table>       
          </div>
        </div>
      </transition>
      <!-- showDisplayNameForReceiptInput --><transition name="screenslideup">
        <div v-show="showDisplayNameForReceiptInput===true" class="modal-overlayfrombottomblur" style="z-index: 995;">
          <div class="modal" style="height: 65vh;"> 
            
            <!-- put in table for dev speed need to learn more about flex -->
            <table border=0 width=90%> 
              <tr>
                <td>
                    <div class="divl"><button class="text-only-button cancel" ref="cancel-displayname" @click="this.doCancelShowDisplayNameForReceiptInput()">Cancel</button></div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <div class="lt-blue txtc"  :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
                  Let {{ this.allrecipientDisplaynames }} know<br>this tip is from you?
                  </div> -->
    
                  <div class="lt-blue txtc"  :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }" v-if="this.tipsArray.length > 0"> Let 
                  
                    <span v-for="(tip, index) in this.tipsArray" :key="index">
                      <RecipientConfirm :key="componentKey" :indexNum=index :indexLen=this.tipsArray.length :recipientId=tip.recipient.objectId  :recipientDisplayname=tip.recipient.displayname :recipientCurrencySymbol=tip.recipient.currencySymbol :recipientAmountChosen=tip.amountChosen  :recipientCancelImageData=recipientCancelImageData :doTipDeletionsAndAdditions=this.doTipDeletionsAndAdditions @userCancelSelected="userCancelSelected"/> 
                    
                    </span> 
                    know <span v-if="this.tipsArray.length > 1"> these tips are</span><span v-if="this.tipsArray.length < 2">this tip is</span> from you?
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style="height: 6vh; display: flex;">
                    <input type="textbox" class="standard-textbox input" ref="new-displayname" placeholder="e.g. your first name...">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div><button class="widebtn" style="width: 65%; height: 5vh; " ref="save-displayname" @click="this.doSaveDisplayName();">done</button></div>
                </td>
              </tr>
              <tr>
                <td style="display: flex;">
                  <div class="lt-blue notes-text">
                  Enter e.g. your first name above so {{ this.allrecipientDisplaynames }} will know that this tip came from you. ThankU will remember this so you don't have to provide it again next time.
                  </div><br>
                </td>
              </tr>
              <tr>
                <td>
                    <div class="divc tb-padding"><button class="text-only-button padded" ref="not-now-displayname" @click="this.doCancelShowDisplayNameForReceiptInput();">not now</button></div>
                </td>
              </tr>
              <tr>
                <td>
                    <div class="divc tb-padding"><button class="text-only-button padded" ref="dont-show-again-displayname" @click="this.doShowDisplayNameForReceiptInputDontShowAgain();">don't show again</button></div>
                </td>
              </tr>
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
            </table>       
          </div>
        </div>
      </transition>
    
      <!-- showPWForUserAccountInput --><transition name="screenslideup">
        <div v-show="showPWForUserAccountInput" class="modal-overlayfrombottomblur">
          <div class="modal" style="height: 80vh;"> 
            <!-- put in table for dev speed need to learn more about flex -->
            <table border=0 width=90%> 
                <tr>
                <td>
                    <div class="divl"><button class="text-only-button cancel" ref="cancel-password" @click="this.doCancelShowPWForUserAccountInput()">Cancel</button></div>
                </td>
              </tr>
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
              <tr>
                <td>
                  <div class="lt-blue txtc"  :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
                  use ThankU on another<br>device or browser?
                  </div>
                </td>
              </tr>
              <tr> <!-- spacer -->
                <td>
                    <div></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style="height: 6vh; display: flex;">
                    <input data-private="ipsum" type="textbox" class="standard-textbox input" ref="new-password" placeholder="Choose a password...">
                  </div>
                </td>
              </tr>
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
              <tr>
                <td>
                  <div><button class="widebtn" style="width: 65%; height: 5vh; " ref="save-password" @click="this.doSavePW();">done</button></div>
                </td>
              </tr>
              <tr>
                <td style="display: flex;">
                  <div class="lt-blue notes-text">
                  when using another device or browser look for the login button on the first screen - you can enter your email address and password and ThankU will connect to your user account so you can use all the same details again including your secure payment token. 
                  </div><br>
                </td>
              </tr>
              <tr>
                <td>
                    <div class="divc tb-padding"><button class="text-only-button padded" ref="not-now-pw" @click="this.doCancelShowPWForUserAccountInput()">not now</button></div>
                </td>
              </tr>
              <tr>
                <td>
                    <div class="divc tb-padding"><button class="text-only-button padded" ref="dont-show-again-pw" @click="this.doShowPWForUserAccountInputDontShowAgain();">don't show again</button></div>
                </td>
              </tr>
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
              <tr> <!-- spacer --> <td><div></div></td>
              </tr>
            </table>       
          </div>
        </div>
      </transition>
      <!-- paymentSubmitted --><transition name="fade">
      <div v-if="this.showPaymentScreen" id="showPaymentScreen" class="wrapper-todiv">
        <div v-show="this.paymentSubmitted" id="paymentSubmitted">
          <div class="payment-submitted-screen-top">
              <img  class="tulogo" @click="this.doShowHome()" alt="ThankU logo" src="../assets/tutxtlogo.png">
              <p></p>
              <img  class="tulogo" style="" alt="payment submitted!" src="../assets/whitetick.png">
          </div>
          <div class="success-screen-main">
            <span v-if="this.tipsArray.length === 0" class="primary-general-text" style="font-size: 24px; font-style: bold; font-weight: bold">ThankU from {{ this.recipient.displayname }}!</span>
            <span v-if="this.tipsArray.length > 0" class="primary-general-text" style="font-size: 24px; font-style: bold; font-weight: bold">ThankU from {{ this.allrecipientDisplaynames }}!</span>
            <p></p>
            <div v-if="this.atLeaseOneRecipientHasPhoto===false || this.tipsArray.length===0"><img :src="this.recipientImageDataURL" style="height: 12vh; border-radius:50%"></div>
            <div v-if="this.atLeaseOneRecipientHasPhoto===true">
              <table width=100%>
                <tr>
                  <span :key="tip.objectId" v-for="tip in this.tipsArray">
                    <td>
                      <img v-if="this.tipsArray.length < 3" :src="tip.recipient.recipientImageDataURL" style="height: 12vh; border-radius:50%">
                      <img v-if="this.tipsArray.length === 3" :src="tip.recipient.recipientImageDataURL" style="height: 11vh; border-radius:50%">
                      <img v-if="this.tipsArray.length === 4" :src="tip.recipient.recipientImageDataURL" style="height: 9vh; border-radius:50%">
                      <img v-if="this.tipsArray.length > 4" :src="tip.recipient.recipientImageDataURL" style="height: 6vh; border-radius:50%">
                    </td>
                  </span>
                </tr>
              </table>
            </div>
    
            <p />
            <p></p>
            <div class="userinfoframe" style="height: 8vh; width: 90%; margin: auto; background: #C9EFD7" v-show="this.paymentAuthenticating" ><span style="font-size: 20px; font-style: bold; color: #718096 ">3D authenticating{{this.epsilon}}</span>
            </div>
            <div id="paymentConfirmed" class="userinfoframe" style="height: 8vh; width: 90%; margin: auto;" v-show="this.paymentConfirmed" ><span style="font-size: 20px; font-style: bold; font-weight: bold">payment successful</span>
            </div>
          </div>
          <!-- <transition name="fade"> -->
          <div v-show="paymentConfirmed" class="wrapper-todiv" style="display: flex; margin: auto">
            <div class="success-screen-email">
              <span v-if="this.hasSetEmail" class="willsendemailtext">Your receipt will be emailed to you, and your tip history is available on your Account screen</span>
            </div>
          </div>
          <!-- </transition> -->
          <transition name="fade">
          <div v-show="setTipAgainVisible" class="tip-again"><button class="widebtn" @click="this.buttonPressNum = 0; this.doTipAgain();">{{ this.goAgainMessage }} </button></div>
          </transition>
        </div>
      </div>
      </transition>
      <!-- paymentCancelled --><transition name="fade">
      <div v-if="this.showPaymentScreen" class="wrapper-todiv">
        <div id="paymentCancelled" v-show="this.paymentCancelled">
          <div class="payment-submitted-screen-top">
              <img  class="tulogo" @click="this.doShowHome('blankTipArrayTypeValues')" alt="ThankU logo" src="../assets/tutxtlogo.png">
              <p></p>
              <img  class="tulogo" style="padding-top: 9vh" alt="payment cancelled" @click="this.doTipAgain();" src="../assets/greycross.png">
          </div>
          <div class="cancelled-screen-main">
            <span class="primary-general-text" style="font-size: 24px; font-style: bold; font-weight: bold">Cancelled</span>
            <p></p>
            <div v-if="this.tipsArray.length < 2" ><span class="primary-general-text" style="font-size: 20px; font-style: bold; font-weight: bold">your tip to {{ this.recipient.displayname }} has been cancelled, you have not been charged</span>
            </div>
            <div v-if="this.tipsArray.length > 1"><span class="primary-general-text" style="font-size: 20px; font-style: bold; font-weight: bold">your tips to {{ this.allrecipientDisplaynames }} have been cancelled, you have not been charged</span>
            </div>
          </div>
          <div class="tip-again"><button class="widebtn" @click="this.doTipAgain();">{{ this.goAgainMessage }} </button></div>
        </div>
      </div>
      </transition>
      
      <!-- showConfirmation --><transition name="confirmslideup">
        <div v-show="this.showConfirmation===true" class="modal-overlayfrombottomblur">
          <div class="modal" style="margin-top: auto; height: auto;  outline:0.0em solid yellow;"> 
            <div class="modalcontentwrapperwrap" ref="dialogMessageScreen" id="dialogMessageScreen" style="height: auto;  outline:0.0em solid purple">
              <table width="100%" cellpadding=0 cellspacing=0 border=0 style="height: 5vh">
                <tr>
                  <td width="48%"> 
                    <div class=divl><button class="text-only-button cancel" @click="this.savedSourceCancelPayment();">Cancel</button></div> 
                  </td>
                  <td width="4%"> 
                    &nbsp;
                  </td>
                  <td width="48%">
                    <div class=divr><button v-if="this.doTipDeletionsAndAdditions === true" class="text-only-button cancel" style="font-size: 14px" @click="this.doAddAnotherTip()">Add another tip?</button></div>
                  </td>
                </tr>
              </table>
              <span class="primary-general-text divl" style="display: block; line-height: 2vh;">&nbsp;</span> 
              <div v-if="this.tipsArray.length === 0" class=primary-general-text>Confirm your tip to {{ this.recipient.displayname }}</div>
              <div style="display: inline; outline:0.0em solid blue;" v-if="this.tipsArray.length > 0" class=primary-general-text>Confirm your tip{{this.tipsPluralStr}} to 
                
                <span v-for="(tip, index) in this.tipsArray" :key="index">
                  <RecipientConfirm :key="componentKey" :indexNum=index :indexLen=this.tipsArray.length :recipientId=tip.recipient.objectId  :recipientDisplayname=tip.recipient.displayname :recipientCurrencySymbol=tip.recipient.currencySymbol :recipientAmountChosen=tip.amountChosen  :recipientCancelImageData=recipientCancelImageData :doTipDeletionsAndAdditions=this.doTipDeletionsAndAdditions @userCancelSelected="userCancelSelected"/> 
                
                </span> 
              </div>
              <p></p>
              <div v-if="this.atLeaseOneRecipientHasPhoto===false || this.tipsArray.length===0"><img :src="this.recipientImageDataURL" style="height: 12vh; border-radius:50%"></div>
              <div v-if="this.atLeaseOneRecipientHasPhoto===true">
                <table width=100%>
                  <tr>
                    <span :key="tip.objectId" v-for="tip in this.tipsArray">
                      <td>
                        <img v-if="this.tipsArray.length < 3" :src="tip.recipient.recipientImageDataURL" style="height: 12vh; border-radius:50%">
                        <img v-if="this.tipsArray.length === 3" :src="tip.recipient.recipientImageDataURL" style="height: 11vh; border-radius:50%">
                        <img v-if="this.tipsArray.length === 4" :src="tip.recipient.recipientImageDataURL" style="height: 9vh; border-radius:50%">
                        <img v-if="this.tipsArray.length > 4" :src="tip.recipient.recipientImageDataURL" style="height: 6vh; border-radius:50%">
                      </td>
                    </span>
                  </tr>
                </table>
              </div>
    
    
              <!-- recipient: recipientNotByRefObject -->
    
    
              <p />
              <p></p>

             
              <div v-show="this.useRyft===true" class="contain-an-overlaid-button" ref="movedRyftStoredPaymentButtonContainer" id="movedRyftStoredPaymentButtonContainer"> <!-- need ids for inspecting in Chrome Dev Tools -->
                <!-- this div is used when there is a Ryft stored payment method and AP or GP and stored payment is the default. Firstly, the Ryft stored payment button needs to be detached and moved up here, then the Confirm button needs to be detached from the main Ryft-Pay-Form and moved up here, which means we need the container div to allow the placement of the Confirm button over it, then we need to move the extras and notes section up here, leaving the Ryft Pay Form underneath as that will sit where 'use GP or AP instead' is -->
                
              </div>

              <!-- <div ref="blankspacer" id="blankspacer" style="width: 100%; border: 5px solid #ccc; box-sizing: border-box;"> -->
              <div ref="blankspacer" id="blankspacer" style="width: 100%; border: 0px solid #ccc; box-sizing: border-box;">
                  &nbsp;
              </div>

              <!-- Ryft payment section will move to here, don't forget it also includes Stripe -->
              <div ref="completeRyftAndTuPaymentSectionContainerDivOnWalletScreen" id="completeRyftAndTuPaymentSectionContainerDivOnWalletScreen" style="outline:0.0em dashed brown;"> <!-- need ids for inspecting in Chrome Dev Tools -->


              </div>

              
              <!-- this stays on its own / doesn't move and is only ever used for Stripe -->
              <button class="widebtn" style="height: 6vh" ref="savedSourceOrAPorGPConfirmPayment" @click="determineCompletionMethod($event);">Pay <span class="currencySymbol">{{ this.buttonCurrSymbol }}</span>{{ this.amountToPayDisplayLabel }}
                
                  <span class="currEquiv">{{ this.amountChosenCurrEqDisplayLabel }}</span>
                
              </button>

              <div ref="extrasAndNotesOnConfirm" id="extrasAndNotesOnConfirm" >
                <div v-if="this.thankUFeeToAdd > 0" class="infolabel wrapper-todiv" style="display: inline-flex; padding-top: 1.5vh">
                  <div style="margin: auto;"><span style="font-size: 14">a {{ this.buttonCurrSymbol }}{{ this.feeToPayDisplayLabel }} processing fee has been added</span>
                  </div>
                </div>
                <div v-if="this.recipient.feesAtUserOption === true" >
                  <table border=0 align=center style="padding-top: 0px; padding-bottom: 0px">
                    <tr>
                      <td>
                          <input type="checkbox" style="display: inline-block; width: 3.5vh; height: 3.5vh" v-on:change="doToggleUpdateFeesCheckbox" :checked="this.addFeesCheckboxValue">
                      </td>
                      <td>
                        <span class="primary-general-text" style="font-size:16px;">+{{ this.buttonCurrSymbol }}{{ this.addFeesQuestionLabel }} fees?</span>
                      </td>
                      <td>
                        &nbsp;&nbsp; <img style="height: 3.5vh; width: 3.5vh; vertical-align: middle; cursor: pointer;" alt="help on adding fees"  @click="this.feesInfo()" src="../assets/help.png">
                      </td>
                    </tr>
                  </table>
                </div>
                <div v-if="this.recipient.feesAtUserOption === true && this.tipsArray.length < 2" class="lt-blue notes-text" style="width: 100%; font-size:10px; line-height: 10px;">Tick this box to pay {{ this.allrecipientDisplaynames }}'s card processing fees so {{ this.allrecipientDisplaynames }} receives your whole {{ this.buttonCurrSymbol }}{{ this.lastAmountChosen }} tip. 
                </div>
                <div v-if="this.recipient.feesAtUserOption === true && this.tipsArray.length > 1" class="primary-general-text" style="width: 100%; font-size:10px; line-height: 10px;">Tick this box to pay {{ this.allrecipientDisplaynames }}'s card processing fees so they receive your whole tip. 
                </div>

                <div class="lt-blue" style="padding-top: 10px">
                  <div style="margin: auto;">
                    <span style="font-size: 14px">using 
                    <span v-if="this.tipper.last4Digits && this.payingByApplePay === false && this.payingByGooglePay === false">card ending *{{ this.tipper.last4Digits }}</span> </span> 
                    
                    <!-- <span @click="this.$refs.savedSourceOrAPorGPConfirmPayment.click();" v-if="this.payingByApplePay"><img style="height: 5vh; vertical-align: middle;" alt="Apple Pay" src="../assets/apple.png"></span>
                    <span @click="this.$refs.savedSourceOrAPorGPConfirmPayment.click();" v-if="this.payingByGooglePay"><img style="height: 5vh; vertical-align: middle; " alt="Google Pay" src="../assets/google.png"></span> -->
                    <!-- DONT KNOW why on earth we have this.$refs.savedSourceOrAPorGPConfirmPayment.click() but actually what it does is trigger the token payment -->
      
                    <span @click="this.$refs.savedSourceOrAPorGPConfirmPayment.click();" v-if="this.payingByApplePay"><img style="height: 5vh; vertical-align: middle;" alt="Apple Pay" src="../assets/apple.png"></span>
                    <span @click="this.$refs.savedSourceOrAPorGPConfirmPayment.click();" v-if="this.payingByGooglePay"><img style="height: 5vh; vertical-align: middle; " alt="Google Pay" src="../assets/google.png"></span>
      
                    
                    &nbsp;&nbsp;<button class="text-only-button cancel" style="font-size: 14px" @click="this.changePaymentMethod()">Change</button>
                  </div>
                </div>
              </div>
                
                

              <div ref="APorGPInsteadOptionButtonOnConfirm" id="APorGPInsteadOptionButtonOnConfirm">
                <div ref="blankPayButton" v-show="this.tipper.last4Digits && this.showBlankInstead===true" class="lt-blue" style="padding-top: 10px">
                  <div style="margin: auto;"> 
                    &nbsp;&nbsp;<button class="text-only-button cancel" style="font-size: 14px" @click="setToTokenTrans(); this.$refs.savedSourceOrAPorGPConfirmPayment.click(); "> 
                      <span v-show="this.showBlankInstead===true"><img style="height: 5vh; vertical-align: middle;" alt="Apple Pay" src="../assets/blankpay.png"></span>
                      <!-- <span v-show="this.canPayByApplePay===true"><img style="height: 5vh; vertical-align: middle;" alt="Apple Pay" src="../assets/apple.png"></span>
                      <span v-show="this.canPayByGooglePay===true"><img style="height: 5vh; vertical-align: middle; " alt="Google Pay" src="../assets/google.png"></span> -->
                      </button>
                  </div>
                </div>
                <div v-show="this.tipper.last4Digits && this.showBlankInstead===false  && (this.canPayByApplePay === true || this.canPayByGooglePay === true)" class="lt-blue" style="padding-top: 10px">
                  <div style="margin: auto;"> 
                    &nbsp;&nbsp;<button class="text-only-button cancel" style="font-size: 14px" @click="setToTokenTrans(); this.$refs.savedSourceOrAPorGPConfirmPayment.click(); ">Use 
                      <!-- <span v-show="this.showBlankInstead===true"><img style="height: 5vh; vertical-align: middle;" alt="Apple Pay" src="../assets/blankpay.png"></span> -->
                      <span v-show="this.canPayByApplePay===true"><img style="height: 5vh; vertical-align: middle;" alt="Apple Pay" src="../assets/apple.png"></span>
                      <span v-show="this.canPayByGooglePay===true"><img style="height: 5vh; vertical-align: middle; " alt="Google Pay" src="../assets/google.png"></span>
                      instead</button>
                  </div>
                </div>
                <div v-show="this.useRyft===true" ref="APorGPInsteadOptionBlanking" id="APorGPInsteadOptionBlanking" style="height: 25px; display: none; background: white;">&nbsp; </div>
              </div>
            </div>        
          </div>
        </div>
      </transition> 
      
      <!-- showCardInput --><transition name="screenslideup">
        
        <div v-show="showCardInput===true" class="modal-overlay">
          
          <div ref="ryftPayFormEnclosingModal" id="ryftPayFormEnclosingModal" class="modal" style="padding-top: 2vh; padding-bottom: 2vh"> 
    
            <table ref="ryftPayFormEnclosingTable" id="ryftPayFormEnclosingTable" width=95%>
              <tr>
                <td>
                  <table width="100%" cellpadding=0 cellspacing=0 border=0 style="height: 5vh">
                    <tr>
                      <td width="48%"> 
                        <div class=divl><button class="text-only-button cancel" @click.stop="this.cardInputCancel();">Cancel</button></div> 
                      </td>
                      <td width="4%"> 
                        &nbsp;
                      </td>
                      <td width="48%">
                        <div class=divr><button v-if="this.doTipDeletionsAndAdditions === true" class="text-only-button cancel" style="font-size: 14px" @click="this.doAddAnotherTip()">Add another tip?</button></div>
                      </td>
                    </tr>
                  </table>
                  <div v-if="this.tipsArray.length === 0" class=primary-general-text>Confirm your tip to {{ this.recipient.displayname }}
                  </div>
                  <div style="display: inline; outline:0.0em solid blue;" v-if="this.tipsArray.length > 0" class=primary-general-text>Confirm your tip{{this.tipsPluralStr}} to 
                    
                    <span v-for="(tip, index) in this.tipsArray" :key="index">
                      <RecipientConfirm :key="componentKey" :indexNum=index :indexLen=this.tipsArray.length :recipientId=tip.recipient.objectId  :recipientDisplayname=tip.recipient.displayname :recipientCurrencySymbol=tip.recipient.currencySymbol :recipientAmountChosen=tip.amountChosen  :recipientCancelImageData=recipientCancelImageData :doTipDeletionsAndAdditions=this.doTipDeletionsAndAdditions @userCancelSelected="userCancelSelected"/> 
                    
                    </span> 
                  </div>
                </td>
              </tr>
              
              <tr>
                <td>
                  <div v-if="this.atLeaseOneRecipientHasPhoto===false || this.tipsArray.length===0"><img :src="this.recipientImageDataURL" style="height: 12vh; border-radius:50%"></div>
                  <div v-if="this.atLeaseOneRecipientHasPhoto===true">
                    <table width=100%>
                      <tr>
                        <span :key="tip.objectId" v-for="tip in this.tipsArray">
                          <td>
                            <img v-if="this.tipsArray.length < 3" :src="tip.recipient.recipientImageDataURL" style="height: 12vh; border-radius:50%">
                            <img v-if="this.tipsArray.length === 3" :src="tip.recipient.recipientImageDataURL" style="height: 11vh; border-radius:50%">
                            <img v-if="this.tipsArray.length === 4" :src="tip.recipient.recipientImageDataURL" style="height: 9vh; border-radius:50%">
                            <img v-if="this.tipsArray.length > 4" :src="tip.recipient.recipientImageDataURL" style="height: 6vh; border-radius:50%">
                          </td>
                        </span>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
              
              <tr>
                
                <td ref="completeRyftAndTuPaymentSectionContainerTdOnCardInputScreen" id="complete-ryft-and-tu-payment-section-container-td-on-card-input-screen">
    
                  <!-- <form id="payment-form" class="paymentform"> -->
                  <div v-show="this.useRyft===false" class="standard-textbox input" style="display: flex; height: 5vh; min-width: 90%; max-width: 90%; width:90%;">  
                    <div id="card-element" style="width:90%; ">
                      <!-- Elements will create input elements here -->
                    </div>
                  </div>
                  <!-- We'll put the error messages in this element -->
                  <div v-show="this.useRyft===false" id="card-errors" role="alert" style="font-size:0px"></div>
                  <!-- </form>  -->
                  
                  <div ref="completeRyftAndTuPaymentSectionContainerDivOnCardInputScreen" id="completeRyftAndTuPaymentSectionContainerDivOnCardInputScreen" style="outline:0.0em dashed blue;">
                    <div v-show="this.useRyft===true" ref="completeRyftAndTuPaymentSectionContainerDiv" id="completeRyftAndTuPaymentSectionContainerDiv" class="contain-an-overlaid-button" style=""> 
                      <div v-show="this.useRyft===true" style="outline:0.0em dashed black;">

                        <form v-show="this.useRyft===true" @submit.prevent="this.doSubmitRyftPayment()" id="ryft-pay-form" ref="RyftPayForm" style="position: relative; width: 95%; height: 42px; margin: auto;outline:0.0em dashed purple;">
                            <!-- form will be embedded here -->
                            <!-- <button style="display: none;" id="ryft-pay-btn" ref="ryftPayBtn">PAY GBP 10</button> -->
                            <div id="ryft-pay-error"></div>
                        </form>
                      </div>
                      <div v-show="this.useRyft===true" ref="ryftTuPayButtonAndRelatedElementsContainerDiv" id="ryftTuPayButtonAndRelatedElementsContainerDiv">
                        <div ref="tupaybuttondiv" id="tupaybuttondiv" style="position: absolute; top: 0; left: 0; width: 100%; height: 42px; outline:0.0em dashed red; pointer-events: none; background: white;">
                          <button ref="tupaybutton" id="tupaybutton" class="widebtn" style="height: 42px; pointer-events: none; cursor: pointer;" @click="this.cardInputConfirmPlatformSelect();">Pay <span class="currencySymbol">{{ this.buttonCurrSymbol }}</span>{{ this.amountToPayDisplayLabel }}
                          <span class="currEquiv">&nbsp;{{ this.amountChosenCurrEqDisplayLabel }}</span>
                          </button>
                        </div>
                      
                      </div>
                    </div>
                  </div>


                  
                  <div ref="stripetupaybuttonandrelatedelementscontainerdiv" id="stripetupaybuttonandrelatedelementscontainerdiv"> <!-- need ids for inspecting in Chrome Dev Tools -->
                    <div ref="paymentFormExtrasAndNotesOnCardInput" id="paymentFormExtrasAndNotesOnCardInput" >
                      <div v-show="this.showConfirmation===false && this.useRyft===true" class="lt-blue notes-text"  style="padding-top: 0px; width: 100%; ">
                        <span v-show="this.showConfirmation===false && this.useRyft===true" class="primary-general-text" style="width: 100%; font-size:9px; line-height: 11px; padding-top: 0.0px; outline:0.0em solid black;">ThankU does not store your payment details, only a secure digital token from our payment processor.
                        </span>
                      </div>
                      <div v-if="this.recipient.feesAtUserOption === true" style="outline:0.00em solid blue" :style="{ 'padding-top': this.paddingTopFeesOptionsTop + 'vh' }">
                        <table border=0 align=center style="padding-top: 0px; padding-bottom: 0px">
                          <tr>
                            <td>
                                <input type="checkbox" style="display: inline-block; width: 3.5vh; height: 3.5vh" v-on:change="doToggleUpdateFeesCheckbox" :checked="this.addFeesCheckboxValue">
                            </td>
                            <td>
                              <span class="primary-general-text" style="font-size:16px;">+{{ this.buttonCurrSymbol }}{{ this.addFeesQuestionLabel }} fees?</span>
                            </td>
                            <td>
                              &nbsp;&nbsp; <img style="height: 3.5vh; width: 3.5vh; cursor: pointer" @click="this.feesInfo()" alt="help on adding fees"  src="../assets/help.png">
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div v-if="this.recipient.feesAtUserOption === true && (this.tipsArray.length === 0 || this.tipsArray.length === 1)" class="lt-blue notes-text" style="width: 100%; ">
                        <span class="primary-general-text" style="display:block; font-size:9px; line-height: 11px;">
                        Tick this box to pay {{ this.allrecipientDisplaynames }}'s card processing fees so {{ this.allrecipientDisplaynames }} receives your whole {{ this.buttonCurrSymbol }}{{ this.lastAmountChosen }} tip. <span v-show="this.showIfUKPlatformCardCountryUnknown">If you use a non-UK card, up to an additional {{ this.nonEuropeanFeesLabel }} will be added totalling {{ this. totalWithNonEuropeanFeesLabel}}.
                      </span></span>
                      </div>
                      <div v-if="this.recipient.feesAtUserOption === true && this.tipsArray.length > 1" class="lt-blue notes-text" style="width: 100%; ">
                        <span class="primary-general-text" style="display:block; width: 100%; font-size:9px; line-height: 11px;">
                        Tick this box to pay {{ this.allrecipientDisplaynames }}'s card processing fees so they receive your whole tip. <span v-show="this.showIfUKPlatformCardCountryUnknown">If you use a non-UK card, up to an additional {{ this.nonEuropeanFeesLabel }} will be added totalling {{ this. totalWithNonEuropeanFeesLabel}}.
                      </span></span>
                      </div>
                      <div v-if="this.recipient.feesAtUserOption === false" style="width: 100%; ">
                        <span class="primary-general-text" style="font-size:6px; line-height: 6px;">
                          &nbsp;<br>
                      </span>
                      </div>
        
                      <button :disabled="this.canPay === false && this.useRyft === true" :class="(this.canPay===true || this.useRyft === false) ? 'widetipbtnon' : 'widetipbtnoff'" style="height: 6vh; " @click="this.cardInputConfirmPlatformSelect();">Pay <span class="currencySymbol">{{ this.buttonCurrSymbol }}</span>{{ this.amountToPayDisplayLabel }}
                        <!-- <div v-if="brOrNot"> -->
                          <span class="currEquiv">&nbsp;{{ this.amountChosenCurrEqDisplayLabel }}</span>
                        <!-- </div> -->
                      </button>
                      <!-- <button v-show="this.useRyft===true" class="widebtn" style="height: 7vh" @click="this.toggleEvent();">Toggle event
                      </button> -->
        
                      <div v-if="this.thankUFeeToAdd > 0" class="infolabel wrapper-todiv" style="display: inline-flex: padding-top: 1.5vh">
                        <div style="margin: auto"><span style="font-size: 14px">
                          a {{ this.buttonCurrSymbol }}{{ this.feeToPayDisplayLabel }} processing fee has been added
                        </span></div>
                      </div>
                      <div v-show="this.useRyft===false" style="display: inline-flex; outline:0.0em solid black; padding-top: 1.5vh">
                        <div style="margin-top: auto; margin-bottom: auto; outline:0.0em solid red">
                            <input type="checkbox" style="display: inline-block; vertical-align: middle; width: 3.5vh; height: 3.5vh" v-on:change="updateSaveSecurelyCheckboxValue" id="savesecurelycheckbox" :checked="this.saveSecurelyCheckboxValue"><span class="primary-general-text" style="font-size:14px;">&nbsp;&nbsp;Save securely for next time</span>
                        </div>
                      </div>
                      <div v-show="this.useRyft===false" class="lt-blue notes-text"  style="width: 100%; ">
                        <span class="primary-general-text" style="display:block; width: 100%; font-size:9px; line-height: 11px; padding-top: 0.1px">ThankU does not store your payment details, only a secure digital token from our payment processor.</span>
                        <!-- used to say:  Stripe Inc., like Apple Pay and Google Pay -->
                      </div>
                      <!-- <div v-show="this.useRyft===false" style="height: 4vh;">
                        <img alt="powered by Stripe" class="poweredbystripe"  src="../assets/poweredbystripe.png">
                      </div> -->
                    </div>
                  </div>
                </td>
              </tr>
            </table>   
            <!-- be careful with below if not devving it may ignore display: none because of the reactivity in useRyft overriding it by coming after the initial setting, see https://chatgpt.com/share/67a27f30-f4bc-800d-a631-484b4a4e7c35  -->
            <!-- <div v-show="this.useRyft===true" id="thisiswhereiwas" style="display: none;">
              <button
                id="dev-dummy-apple-pay-button" type="button" lang="en"
                style="height: 42px; width: 100%; background: black; cursor: pointer;" @click="this.myalert">Sneepy
              </button>    
            </div> -->
          </div>
        </div>
      </transition>
      <!-- <div ref="ryftpayformgoeshere" id = "ryftpayformgoeshere" style="z-index: 999;"></div> -->
      <!-- showRecipientsList --><div v-if="showRecipientsList" class="wrapper-todiv" style="top: 10px">
        <div v-if="this.showCancelAddTip === false" class="primary-text-title divc" style="padding-left: 0px: "><span v-if="this.venue">{{ this.venue.name }}</span></div>
        <div v-if="this.showCancelAddTip === true" class="primary-text-title divc" style="padding-left: 0px: ">
          
          <table width="100%" cellpadding=0 cellspacing=0 border=0 style="height: 5vh">
            <tr>
              <td width="10%" > 
                <div class=divl><button class="text-only-button cancel" @click="this.doCancelAddTip()" >Back</button></div> 
              </td>
              <td width="80%"> 
                <span v-if="this.venue">{{ this.venue.name }}</span>
              </td>
              <td width="10%">
                &nbsp;
              </td>
            </tr>
          </table>
    
        </div>
        <!--  -->
        
        <div class="primarytextmidcentre" style="padding-top:1vh; font-size: 100%"><span class display:center>Who would you like to tip?</span></div>
        <div class="centreverticaldivsearchvibrant"><input ref="searchbox" v-on:keyup="searchinput()" class="searchinputvibrant" type="text" placeholder="Search.. (e.g. 1st 2-3 letters)" :style="{ backgroundImage: 'url(' + require('@/assets/search50.png') + ')' }"></div>
        <div :key="recipient.objectId" v-for="recipient in linkedUsersArrayObjectFiltered">
          <Recipient :recipientId=recipient.objectId  :recipientDisplayname=recipient.displayname :recipientConnectedAccountStatus=recipient.connectedAccountStatus :recipientImageData=recipient.imageData :recipientImageFileType=recipient.imageFileType :recipientDiffCurrency=recipient.diffCurrency :recipientRecentlyTippedDate=recipient.recentlyTippedDate :recipientLastTippedAmount=recipient.lastTippedAmount :recipientRyftConnAccId=recipient.ryftConnAccId  @userSelected="userSelected"/>
        </div>
        <div v-if="this.showCancelAddTip === true" style="padding-top:1vh; padding-bottom: 3vh; "> 
        <button v-show="this.showCancelAddTip === true" @click="this.doCancelAddTip" class="widebtn" style="height: 5vh; width: 85%; font-size: 100%; ">Cancel additional tip...</button>
        </div>
        <div v-show="this.showGetTipsHere === true" style="padding-top:1vh; padding-bottom: 3vh; "> 
        <button v-show="this.showGetTipsHere === true" @click="this.doGetTipsHere" class="widebtn" style="height: 5vh; width: 50%; font-size: 100%; ">get tips here...</button>
        </div>
        <div v-show="this.showGothankUHome === true" style="padding-top:1vh; padding-bottom: 3vh; "> 
        <button v-show="this.showGothankUHome === true" @click="this.doShowHome" class="text-only-button cancel" style="height: 5vh; width: 50%; font-size: 100%; ">ThankU Home</button>
        </div>
        <div v-if="this.showMakeName" style="padding-top:1vh; padding-bottom: 3vh; "> 
        <button @click="this.doMakeName" class="widebtn" style="height: 5vh; width: 50%; font-size: 100%; ">Make name</button>
        </div>
      </div>
      <!-- showRecipient --><div v-show="showRecipient"> <!-- effectively grouping views -->
        <div class="wrapper-todiv">
          <div>
            <table width="100%" border=0>
                <tr>
                    <td width="30%">
                        <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                    </td>
                    <td width="40%" ><img class="tulogo" @click="this.doShowHome()" alt="ThankU logo" src="../assets/tutxtlogo.png"></td>
                    <td width="30%">
                    <div class=divr>
                    
                        <button @click="this.doShowExport()" class="text-only-button cancel divr"  :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" style="display: none;">Export</button>
                    </div>
                    </td>
    
                </tr>
            </table>
          </div>
        </div>
        <transition name="fade">
        <div v-show="showOwnTip" style = "padding-top: 15px">
          <div class="wrapper-todiv" style="display: flex; ">
            <div class="divl"><button class="text-only-button cancel" ref="cancel-other-amount-input" @click="this.showOwnTip=false;">
              Cancel
              </button>
            </div>
          </div>
          <div class="wrapper-todiv" style="display: flex;">      
            <h1 class="primarytextmid" style="margin: auto; ">How much do you want<br>to tip {{ this.recipient.displayname }}?</h1>
          </div>
          <div class="wrapper-todiv" style="padding-top: 20px; padding-bottom: 35px;">
             <!-- outline:0.1em solid black -->
              <span class="currencySymbolOtherAmount" style="">{{ this.buttonCurrSymbol }}</span><input ref="other-amount-input" v-on:keyup="this.updateButton5" class="lt-blue other-amount-input" type="number" value=0 :style="{ 'width': this.inputAmountWidth + 'px' }">
          </div>
          <div class="wrapper-buttonsdiv">
            <div class="tipwidebuttonframe">
                <table style="height:100%;" border=0 width=97%>
                  <tbody>
                      <tr>
                        <td>
                          <button type="button" class="widebtn" ref="button5" @click="this.doPayment('5', $event);">Tip {{ this.recipient.displayname }} <span class="currencySymbol">{{ this.buttonCurrSymbol }}</span>{{ this.recipient.button5Amount }}
                          <span :v-show="this.tipper.tipperFX !== 1" class="currEquiv">{{ this.recipient.button5CurrEqAmount }}</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
          </div>
           <div v-if="this.thankUFeeToAdd > 0" class="infolabel wrapper-todiv" style="display: inline-flex;">
            <div style="margin: auto;">a {{ this.thankUFeeToAdd }} % processing fee will be applied</div>
          </div>
        </div>
        </transition>
        <div v-show="!showOwnTip">
          <div class="wrapper-todiv">
            <h1 class="primarytextmid" style="">You're tipping:</h1>
            <div class="userinfoframe" style="height: 15vh">
              <table style="height:85%;" border=0 width=100%>
              <thead>
                <tr style="font-size: 0">
                  <td align=left width=25% style="height: 8vh; font-size: 0; text-align: center"><img :src="this.recipientImageDataURL" style="height: 12vh; border-radius:50%"></td>
                  <td >
                    <!-- nested table -->
                    <table width=100%>
                      <tr>
                        <td class="username" style="text-align: left; outline:0.0em solid green;">{{ this.recipient.displayname }}</td>
                      </tr>
                      <tr>
                        <td class="businessname" style="text-align: left; outline:0.0em solid green;"><span v-if="this.additionalField !== ''">{{ this.additionalField }}</span></td> 
                        <!-- {{ this.venue.name }} -->
                        <!-- when this value is blank it shows up as Uncaught TypeError: Cannot read properties of undefined (reading 'name') - should get rid of this with v-if at some point -->
                      </tr>
                    </table>
                  </td>
                </tr>
              </thead>
              </table>
            </div>
            <p></p>
          </div>
          <!-- <div class="wrapper-todiv" style="display: inline-flex;">
            <div style="margin: auto;">optional fees shown on next screen</div>
          </div> -->
          <div class="wrapper-buttonsdiv">
            <div class="tipbuttonsframe">
                <table style="height:100%;" border=0 width=97%>
                  <tbody>
                      <tr align="center">
                        <td v-if="this.recipient.button1Amount != 'hide'" style="width: 50%"> 
                          <!-- put the v-if on cell to widen the remaining button or on the button to leave a blank space where the button was--> 
                          <button type="button" class="btn" @click="this.doPayment('1', $event);" id="button1">
                              <span class="currencySymbol">{{ this.buttonCurrSymbol }}</span>{{ this.recipient.button1Amount }}
                              <div v-if="brOrNot">
                          <span class="currEquiv">{{ this.recipient.button1CurrEqAmount }}</span></div>
                          </button>
                        </td>
                        <td v-if="this.recipient.button2Amount != 'hide'" style="width: 50%">
                          <button type="button"  class="btn" @click="this.doPayment('2', $event);" id="button2">
                          <span class="currencySymbol">{{ this.buttonCurrSymbol }}</span>{{ this.recipient.button2Amount }}
                          <div v-if="brOrNot">
                          <span class="currEquiv">{{ this.recipient.button2CurrEqAmount }}</span></div>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td v-if="this.recipient.button3Amount != 'hide'" style="width: 50%">
                          <button type="button"  class="btn" @click="this.doPayment('3', $event);" id="button3">
                          <span class="currencySymbol">{{ this.buttonCurrSymbol }}</span>{{ this.recipient.button3Amount }}
                          <div v-if="brOrNot">
                          <span class="currEquiv">{{ this.recipient.button3CurrEqAmount }}</span></div>
                          </button>
                        </td>
                        <td v-if="this.recipient.button4Amount != 'hide'" style="width: 50%">
                          <button type="button"  class="btn" @click="this.doPayment('4', $event);" id="button4">
                          <span class="currencySymbol">{{ this.buttonCurrSymbol }}</span>{{ this.recipient.button4Amount }}
                          <div v-if="brOrNot">
                          <span class="currEquiv">{{ this.recipient.button4CurrEqAmount }}</span></div>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
          </div>
          <div class="wrapper-buttonsdiv">
            <div class="tipwidebuttonframe">
                <table style="height:100%;" border=0 width=97%>
                  <tbody>
                      <tr>
                        <td>
                          <button class="widebtn" id="buttonother" @click="this.initOtherAmountScreen()">
                            {{ this.buttonTextOther }} 
                          </button>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
          </div>
          <p></p>
          <div v-if="this.thankUFeeToAdd !== 0" class="infolabel wrapper-todiv" style="display: inline-flex;">
            <div style="margin: auto;">a {{ this.thankUFeeToAdd }} % processing fee will be applied</div>
          </div>
        </div>
      </div>
    </div> 
</template>
     
<script>
      
    
    // import { defineComponent } from 'vue';
    // import Stripe from 'stripe';
    import {loadStripe} from '@stripe/stripe-js/pure';
    const Parse = require('parse/node');
    
    import Recipient from './Recipient.vue';
    import RecipientConfirm from './RecipientConfirm.vue';
    import shared from '../shared.js';
    import LogRocket from 'logrocket';
    import { ref, onMounted } from 'vue';
    
    const usedStorage = ref(0);
    
    // Helper function to estimate the size of a base64 encoded string
    function calculateBase64Size(base64String) {
      // Remove header if present (e.g., 'data:image/png;base64,')
      const base64WithoutHeader = base64String.split(',')[1] || base64String;
      // Every 4 characters of base64 encoded data equals 3 bytes
      const padding = (base64WithoutHeader.match(/==?$/) || []).length;
      const base64Length = base64WithoutHeader.length;
      return (base64Length * 3) / 4 - padding;
    }
    
    const calculateLocalStorageUsage = () => {
      console.log("I'm running");
      let total = 0;
      Object.keys(localStorage).forEach(key => {
        const item = localStorage.getItem(key);
        let itemSize = 0;
        if (key.toLowerCase().includes('image') && item.startsWith('data:')) {
          // Special handling for base64 encoded images
          itemSize = calculateBase64Size(item); // calculate the actual byte size of the base64 content
        } else {
          // Regular calculation for non-image items
          itemSize = item.length * 2; // assume each character is 2 bytes
        }
        // console.log(`Key: ${key}, Size: ${itemSize}`);
        total += key.length * 2 + itemSize; // bytes for the key + bytes for the value
      });
      usedStorage.value = total / 1024; // Convert bytes to kilobytes
      console.log(`Total Used Storage: ${usedStorage.value} KB`);
    };
    
    onMounted(calculateLocalStorageUsage);
    
    // const Stripe = require('Stripe');
    
    // var stripe; // component level gets set in initiateStripePaymentIntent
    
    export default ({
      inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
      name: 'TippingScreen',
      data() {
        return {
          stripe: undefined,
          dimensions: 0,
          inputAmountWidth: 24,
          lastInputAmountValue: 0,
          fontSizeTitle: 2.7, // vh
          fontSizeNormal: 2.4, // vh, not currently used
          fontSizeTitleLineHeight: 5, //vh
          fontSizePopUpMsg: 2.3, // vh
          fontSizePopUpMsgLineHeight: 3.4,// vh
          card: undefined,
          lastButtonTapped: undefined,
          lastAmountChosen: undefined,
          buttonCurrEqAmount: undefined,
          recipients: [],
          tipper: undefined,
          global: undefined,
          recipient: {displayname: ""}, // initial value to avoid UI console errors though seemed to work
          venue: undefined,
          // venuePoolMasterId: "",
          pmt: {},
          paymentSubmitted: false,
          paymentCancelled: false,
          userObjectId: undefined,
          sessionToken: undefined,
          showRecipient: false,
          showRecipientsList: false,
          showOwnTip: false,
          showConfirmation: false,
          showCardInput: false,
          showEmailForReceiptInput: false,
          showDisplayNameForReceiptInput: false,
          showPWForUserAccountInput: false,
          showPaymentScreen: false,
          goAgainMessage: "",
          showPopUpOk: false,
          popUpMsgPreTitle: "",
          popUpMsgTitle: "",
          popUpMsgBody: "",
          hideOKButton: false,
          userSearchAtLeastOnce: false,
          enteredSomethingValidToOtherAmount: false,
          enteredSomethingInvalidToOtherAmount: false,
          linkedUsersArrayObject: undefined,
          linkedUsersArrayObjectFiltered: undefined,
          recipientImageData: undefined,
          recipientImageDataURL: undefined,
          cardPaymentRecipientDisplayLabel: undefined,
          amountToPayDisplayLabel: undefined,
          feeToPayDisplayLabel: undefined, // this was the old full label we were showing
          addFeesQuestionLabel: undefined,
          allFeesToAdd: undefined,
          allFeesToPotentiallyAdd: undefined,
          amountChosenCurrEqDisplayLabel: undefined,
          thankUFeeToAdd: undefined,
          thankUFeePotentiallyToAdd: undefined,
          addFeesCheckboxValue: false,
          nonEuropeanFeesLabel: "",
          totalWithNonEuropeanFeesLabel: "",
          showIfUKPlatformCardCountryUnknown: false,
          payingByApplePay: false, 
          canPayByApplePay: false, 
          payingByGooglePay: false,
          canPayByGooglePay: false, 
          saveSecurelyCheckboxValue: false,
          paymentSubmitted: false,
          paymentConfirmed: false,
          paymentAuthenticating: false,
          epsilon: "",
          setTipAgainVisible: false,
          showConnect: false,
          deviceFullObject: {},
          x: 5,
          email: "", //"bankpon@thanku.app",
          password: "", //"1111111",
          // tuid: '',
          brOrNot: true,
          buttonCurrSymbol: '£',
          // button1Amount: 3,
          // buttonText1CurrEq: '≈$3.70',
          // button2Amount: 5,
          // buttonText2CurrEq: '≈$5.20',
          // button3Amount: 10,
          // buttonText3CurrEq: '≈$13.30',
          // button4Amount: 20,
          // buttonText4CurrEq: '≈$26.80',
          buttonTextOther: 'or enter your own tip',
          stripePublishableKey: process.env.VUE_APP_STRIPE_ENV === "dev" ? process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_TEST : process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_LIVE,
          ryftPublishableKey: process.env.VUE_APP_RYFT_ENV === "dev" ? process.env.VUE_APP_RYFT_PUBLISHABLE_KEY_TEST : process.env.VUE_APP_RYFT_PUBLISHABLE_KEY_LIVE,
          TUData: undefined,
          dummyValue: "?",  
          hasSetEmail: false,
          hasSetPassword: false, 
          showPopUpTwoOptions: false,
          PopUpTwoOptionsTitle: "",
          PopUpTwoOptionsMessage: "",  
          showLogin: false, 
          showForgottenPassword: false, 
          showPasswordResetEmailSent: false,
          tippedWithoutScanning: false,
          showMakeName: false,
          tipper: {
            last4Digits: "", // having to set this as a default to make the UI load
          },
          buttonPressNum: 0,
          paymentInProgress: false, // designed to stop gremlins
          clientRequestId: "",
          pollCount: 0,
          processTokenPayment: false,
          initTipperError: false,
          showNetworkPopUp: false,
          transactionInterruptedMsg: "",
          transactionInterruptedMsgWasShownOnceAlready: false,
          justAttemptedToCreateReusablePaymentSource: false,
          wasProbablyIncognito: false,
          logRocketIsRunning: false,
          previousTip: "",
          previousTipParams: "",
          previousRecipientObjectId: undefined,
          previousRecipientDisplayname: undefined,
          previousRecipientPaymentObjectId: undefined,
          doShowDisplayNameForReceiptInputShowingPrePayment: false,
          feesAtUserOption: false,
          willDefaultToFeesTicked: false,
          tipperStripeFeesFromDB: 0,
          revertRecipientValues: "",
          revertTipperValues: "",
          revertTipperStripeFees: "",
          usingMultiTips: false,
          showTwoOptionsOnMainPopup: false,
          nextAction: "",
          buttonNumEventObject: {},
          tipsArray: [],
          multipleTipsTotalNumber: undefined,
          allrecipientDisplaynamesAndAmounts: undefined,
          allrecipientDisplaynames: undefined,
          atLeaseOneRecipientHasPhoto: false,
          recipientImageDataURLIsNotPhoto: true, //default
          tipsPluralStr: "",
          showGetTipsHere: true,
          isReallyMultiTips: false,
          doNotShowGetTipsHereButton: false,
          recipientCancelImageData: undefined,
          componentKey: false,
          doTipDeletionsAndAdditions: true,
          logRocketOrganisationSlug: "",
          addingATip: false,
          showCancelAddTip: false,
          loggingError: false,
          showGothankUHome: false,
          fromTipErrorPopup: false,
          fromChangePaymentMethodButton: false,
          setMainPopupMsgVisibility: 'visible',
          showPopupPreTitle: false,
          stripeWasUndefined: false,
          delayOnAttemptingPaymentSystemSoFar: 0,
          progressIndicator: ".",
          wasDoingTokenInsteadMethod: false,
          showBlankInstead: true,
          actuallyDoPaymentRepeatObject: {},
          loadStripeAttempts: 0,
          dontTryStripeAgainInThisSession: false,
          hasTriggeredStatusCheck: false,
          testStripeUndefinedCounter: 0,
          showProgress: false,
          killedTransaction: false,
          useRyft: false,
          paddingTopFeesOptionsTop: 1.5,
          canPay: false,
          ryftPaymentSessionId: undefined,
          transactionOutcomeResultObject: undefined,
          executeRebuildUserScreenAndAppState: false,
          rebuildStateValidityMilliseconds: 0,
          additionalField: "",
          savedCardsGridDividerDisplayStyle: "",
          savedCardsGridDividerBlockDisplayStyle: "",
          savedCardsGridDisplayStyle: "",
          newCardsGridDividerDisplayStyle: "",
          newCardsGridDividerBlockDisplayStyle: "",
          ryftTuPayButtonAndRelatedElementsContainerDivDisplayStyle: "",
          ryftFrameStyleHeight: 0,
          ryftSessionType: undefined,
          hasSavedPaymentSource: false,
          paymentSessionClientSecret: "",
          ryftPaymentMethodsJSON: "",
          ryftLoadTries: 0,
          createRyftPaymentRequestForAPandGPStatusOnlyResponse: {},
          lastErrorOnTryInitRyftForAPandGPStatusOnlyResponse: "",
          stripeLoadRetries: 0,
        }
      },
      props: {
        tuid: String,
        showGetTipsHereButton: Boolean,
      },
      components: {
        Recipient,
        RecipientConfirm,
      },
      methods: {
        myalert(){
          alert('Aloha!');
        },
        toggleEvent(){
          console.log("toggling event");
    
          try {
    
          let eventDetails = {
            "eventName": "paymentMethodSelectionChanged",
            "paymentMethod": {
              "id": "pmt_01JEKYXV86RNSGTHCC06G1K56C",
              "type": "Card",
              "card": {
                "scheme": "Mastercard",
                "last4": "2932",
                "expiryMonth": "03",
                "expiryYear": "2027"
              },
              "customerId": "cus_01JEKVJAM1ZJQX8TEN6HAJH462",
              "createdTimestamp": 1733689273
            },
            "validation": {
              "expirationValid": true
            }
          }
    
          var userEvent = new CustomEvent("paymentMethodSelectionChanged", {
            "detail": eventDetails,
          });
    
          // console.log("userEvent::: " + JSON.stringify(userEvent.detail, null, 2));
          
          this.$refs['ryft-pay-form'].dispatchEvent(userEvent);
    
          //       {
          //   "eventName": "paymentMethodSelectionChanged",
          //   "paymentMethod": {
          //     "id": "pmt_01JEKYXV86RNSGTHCC06G1K56C",
          //     "type": "Card",
          //     "card": {
          //       "scheme": "Mastercard",
          //       "last4": "2932",
          //       "expiryMonth": "03",
          //       "expiryYear": "2027"
          //     },
          //     "customerId": "cus_01JEKVJAM1ZJQX8TEN6HAJH462",
          //     "createdTimestamp": 1733689273
          //   },
          //   "validation": {
          //     "expirationValid": true
          //   }
          // }
    
          //  {
          //   "eventName": "paymentMethodSelectionChanged",
          //   "paymentMethod": null,
          //   "validation": {
          //     "expirationValid": false
          //   }
          // }
    
          } catch (e) {
            console.log("error::: " + e.message);
          }
          
        },
        goBack(){
          this.showRecipient = false;
    
          if (this.linkedUsersArrayObject.length > 1){
            this.showRecipientsList = true;
          } else {
            this.showRecipientsList = false;
            this.doShowHome();
          }
        },
        doPopUpOK(){
          // console.log("OK And cancel popup");
          this.showPopUpOk = false;
          this.showTwoOptionsOnMainPopup = false;
    
          if (window.localStorage.getItem("willConnectRealUserDetails") !== null) {
            this.doShowHome();
          }
    
          if (window.localStorage.getItem("masterregisteringqrcode") !== null) {
            this.doShowHome();
          }
    
    
          if (this.initTipperError === true){
            this.doShowHome();
          }
    
          if (this.nextAction === "addAnotherTip"){
            console.log("ok tapped add another tip");
            this.showPopupPreTitle = false; this.setMainPopupMsgVisibility = 'visible'; this.popUpMsgPreTitle = ""; // we've shown that message don't need the extra space
            shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " requested to addAnotherTip " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            let dontBlankTipArrayTypeValues = true;
            this.doMainTipAgainOps(dontBlankTipArrayTypeValues);
            this.nextAction = "";
          }
    
          if (this.fromTipErrorPopup === true) {
            // we are going to blank the tipsArray and start again
            this.fromTipErrorPopup = false; // reset
            window.setTimeout(this.doMainTipAgainOps, 350);
          }
    
          
        },
        doCancelAddTip(){
          // THIS ISN'T WORKING!!!!
    
          this.showPopupPreTitle = false; this.setMainPopupMsgVisibility = 'visible'; this.popUpMsgPreTitle = "";// we've done this, if we need it again we can set it true
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " did doCancelAddTip " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          this.showRecipientsList = false;
          this.nextAction = "addAnotherTip" // just in case it wasn't
    
          this.doPopUpCancel();
    
          
        },
        doPopUpCancel(){
          console.log("cancel popup this.nextAction: " + this.nextAction);
          this.showPopupPreTitle = false; this.setMainPopupMsgVisibility = 'visible'; this.popUpMsgPreTitle = "";// we've done this, if we need it again we can set it true
          this.showTwoOptionsOnMainPopup = false;
          this.showPopUpOk = false;
    
          // this.showRecipient = false; // this is the new one - I can think of no reason why we should continue to show it even if it results in blank screen?
          // ACTUALLY I DECIDED NOT TO DO THIS - I think this would simply result in a blank screen because the problem we are really having is not that the showRecipient screen fails to disappear at the correct moment but rather that the relevant payment screen fails to appear (which wouldn't have disappeared the showRecipient anyway) - strategy for now is to increase userpath logging to try and work out what is really goiung on
    
          if (this.nextAction === "addAnotherTip"){
            // user decided not to, continue with original tip
           
            this.actuallyDoPayment(this.buttonNumEventObject.buttonNum, this.buttonNumEventObject.event, this.lastAmountChosen); // TODO: is this.lastAmountChosen safe as a default where there is no tipsArray
          }
          this.nextAction = "";
        },
        doAddAnotherTip(){
          this.showConfirmation = false;
          this.showCardInput = false;
          let dontBlankTipArrayTypeValues = true;
          this.addingATip = true;
          this.doMainTipAgainOps(dontBlankTipArrayTypeValues);
          this.nextAction = "";
        },
        sendAddingOrDeletingTipsEmail(operation, index){
            
            let tipsArrayNotByRefObject = JSON.parse(JSON.stringify(this.tipsArray));
    
            let tipString = "";
    
            for (let i = 0; i < tipsArrayNotByRefObject.length; i++){
              let tip = tipsArrayNotByRefObject[i];
                tip.recipientImageDataURL = undefined;
                tipString += "<br>" + tip.recipient.displayname + " " + tip.recipient.currencySymbol + tip.amountChosen;
                if (i === index){
                  tipString += " " + operation.toUpperCase();
                }
            }
    
            const params = {
                toEmail: "appalert@thanku.app",
                subject: this.tipper.displayname + " " + operation + " a tip",
                body: "Tipper " + this.tipper.displayname + " id: " + this.tipper.objectId + " " + operation + " a tip: <br>" + tipString,
              };
    
              Parse.Cloud.run("sendEmail", params);    
        },
        async doResetPassword(){
    
          const email = this.$refs['email-for-reset'].value.toLowerCase();
          
          try {
            const response = await Parse.User.requestPasswordReset(email);
            this.showForgottenPassword = false;
            this.showPasswordResetEmailSent = true;
          } catch (e) {
            // /console.log("BROWSER reset password FAILED");
            this.deployLogRocket(this.logRocketOrganisationSlug);
            console.error(e);
            this.showPopUpOk = true;
            this.popUpMsgTitle = "Oops";
            this.popUpMsgBody = e;
            return (e);
          }
        },
        doShowLogin(){
          this.showEmailForReceiptInput = false; 
          this.showPopUpTwoOptions = false; 
          this.$refs['email-login'].value = this.$refs['new-email'].value;
          this.showLogin = true;
        },
        async doLoginDB(){
    
          try {
            
            var email = this.$refs['email-login'].value;
    
            if (shared.validEmail(email)){
              email = email.toLowerCase();
            } else {
              // do nothing as could be one of my easy access display names
            }
    
            const transferringObjectId = this.tipper.objectId;
    
            const LoggedInUserObject = await Parse.User.logIn(email, this.$refs['pw-login'].value);
    
            let params = {
              transferringObjectId: transferringObjectId,
              transferreeObjectId: LoggedInUserObject.id,
            };
    
            // /console.log("transferring params: " + JSON.stringify(params, null, 2));
    
            Parse.Cloud.run("transferDetailsToLoggedInUser", params); // don't wait
            // the above will also set hasSetEmail and hasSetPassword for logged in user
            window.localStorage.setItem("tuisfulluser", true); // flag to signal the user has logged in with email and password, allowing a recipient wanting to connect to a salon to bypass the sign up screen 
            window.localStorage.setItem("tu", LoggedInUserObject.get("sessionToken"));  
             
            this.displayname = window.localStorage.getItem("tudis");
            window.localStorage.setItem("tudis", this.displayname); // remember that any value we got on logging in was the old value 
            window.localStorage.setItem("tuob", LoggedInUserObject.id);
            window.localStorage.setItem("tuhasstconnaccid", LoggedInUserObject.get("strConnAccId") !== undefined);
            window.localStorage.setItem("tuhasryftconnaccid", LoggedInUserObject.get("ryftConnAccId") !== undefined);
            window.localStorage.setItem("tucurrcode", LoggedInUserObject.get("currencyCode"));
            window.localStorage.setItem("tuhasstconnaccextid", LoggedInUserObject.get("strConnAccExternalAccountId") !== undefined);
            window.localStorage.setItem("tuhasryftconnaccextid", LoggedInUserObject.get("ryftConnAccExternalAccountId") !== undefined);
            window.localStorage.setItem("tuisrecipient", LoggedInUserObject.get("isRecipient"));
         
            if (LoggedInUserObject.get("appVersion") === undefined || LoggedInUserObject.get("appVersion") !== "web") {
              Parse.Cloud.run("updatePlatform", {userObjectId: LoggedInUserObject.id,});
            }
    
            this.doLoginFinished(); // in this case we never needed to show the pop up but handle in the same way
    
          } catch (e) {
            // /console.log("BROWSER login FAILED");
            this.deployLogRocket(this.logRocketOrganisationSlug);
            console.error(e);
            this.showPopUpOk = true;
            this.popUpMsgTitle = "Oops";
    
            if (e.code == 101) {
              this.popUpMsgBody = "incorrect password";
            } else {
              this.popUpMsgBody = e;
            }
    
    
            return (e);
          } 
        },
        doLoginFinished(){
          this.showPasswordResetEmailSent = false;
          this.showPopUpTwoOptions = false;
          this.showForgottenPassword = false;
          this.showLogin = false;
    
          this.hasSetEmail = true; // for UI email message
          this.hasSetPassword = true;
    
          this.popUpMsgTitle = "Great";
          let extraMsg = this.wasProbablyIncognito === true ? " or unless your browser is in incognito mode" : "";
          this.popUpMsgBody = "successfully logged in. You won't be asked to log in again unless you log out on the account screen" + extraMsg + ".";
          this.showPopUpOk = true;
    
          this.hasSetEmail = true;
          
          window.setTimeout(this.doSetTipAgainVisible, 1000); 
    
        },
        async doGetTipsHere(){
    
          // console.log("doing doGetTipsHere");
    
            // console.log("localStorage: " + JSON.stringify(window.localStorage, null, 2));
    
              if (window.localStorage.getItem("tuisfulluser") !== null && window.localStorage.getItem("tuisfulluser") === true){
                  var params = {
                    userObjectId: window.localStorage.getItem("tuob"),
                    isRecipient: true, 
                  };
                  await Parse.Cloud.run("saveUserFromWebApp", params);
            }
    
            window.localStorage.setItem("tuconnecttovenue", this.tuid);
            // console.log("DID SET tuconnecttovenue");
            window.localStorage.setItem("tuconnecttovenuename", this.venue.name);
            if (this.useRyft === true){
              window.localStorage.setItem("tuconnecttovenueuseryft", this.useRyft);
            }
            window.localStorage.setItem("tuisrecipient", true);
         
    
            // /console.log("this.devEnv::: " + this.devEnv);
    
            if (this.devEnv === false) {
              // we're live
               window.location = "https://www.thanku.app/";
              //  window.location = "http://localhost:8080/ "
            } else {
               window.location = "http://localhost:8080/ ";
              //  window.location = "https://www.thankuapp.co.uk";
            }
            // window.location = "http://localhost:8080/ "// "https://www.thanku.app/"; nned to learn more about $router.go this vue function works if you remain on the same domain but not otherwise, we have currently implemented the basic JS 
        },
        feesInfo(){
    
            shared.saveToUserPath(this.devEnv, "Tipper pressed more info about fees to add.. " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            var extraMsg = "";
    
            if (this.showIfUKPlatformCardCountryUnknown) {
              extraMsg = " Cards issued outside Europe attract higher fees."
            }
    
            this.popUpMsgTitle = "Pay your recipient's fees?";        
            let paymentProcessor = this.useRyft === true ? "Ryft" : "Stripe Inc." 
            this.popUpMsgBody = "All card payment processors charge fees - when you go to a shop, or buy online, you normally don't pay the transaction fees, the business you are buying from does. Tick this box if you would like to ensure your recipient gets your whole tip without these fees deducted." + extraMsg;
            this.showPopUpOk = true;
        },
        doShowHome(blankTipArrayTypeValues){
          // console.log("we're off!");
    
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " tipping screen " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          if (blankTipArrayTypeValues !== undefined && blankTipArrayTypeValues === "blankTipArrayTypeValues"){
            this.blankTipArrayTypeValues();
          }
    
          let backgroundcolour = '#EDF2F7';
          document.body.style.backgroundColor = backgroundcolour;
    
          let returnJSON = { showHomeScreen: true }; 
          this.$emit('return-parent-json', returnJSON);
    
        },
        searchinput(){
    
          if (this.userSearchAtLeastOnce === false){
            shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " search box to locate recipient " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            this.userSearchAtLeastOnce = true;
          }
          this.linkedUsersArrayObjectFiltered = this.linkedUsersArrayObject.filter(item => item.displayname.toLowerCase().includes(this.$refs.searchbox.value.toLowerCase()));
    
          if (this.$refs.searchbox.value !== ""){
            this.showGetTipsHere = false;
          } else {
            if (this.doNotShowGetTipsHereButton === false || this.showGetTipsHereButton === true) {
              this.showGetTipsHere = true;
            }
          }
        },
        async userCancelSelected(index, recipientId, recipientDisplayname, recipientCurrencySymbol, recipientAmountChosen, startLogrocket){
    
          console.log("REMOVING recipiendId: " + recipientId + " recipientDisplayname:: " + recipientDisplayname + "  amount: " + recipientCurrencySymbol + recipientAmountChosen);
    
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " REMOVED recipiendId: " + recipientId + " recipientDisplayname:: " + recipientDisplayname + "  amount: " + recipientCurrencySymbol + recipientAmountChosen + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          console.log("this.tipsArray.length pre: " + this.tipsArray.length);
    
          if (startLogrocket !== undefined && startLogrocket === true){
            this.deployLogRocket(this.logRocketOrganisationSlug);
          }
    
          this.sendAddingOrDeletingTipsEmail("deleted", index);
    
          // let tipBeingRemoved = this.tipsArray.filter(function(tip){ return tip.recipient.objectId === recipientId; });
          // it's still an array with only [0] index
    
          // this.lastAmountChosen = +this.lastAmountChosen - +tipBeingRemoved[0].amountChosen;
    
          let tipBeingRemoved = this.tipsArray[index];
    
          this.lastAmountChosen = +this.lastAmountChosen - +tipBeingRemoved.amountChosen;
          this.multipleTipsTotalNumber = this.lastAmountChosen;
    
          // this.tipsArray = this.tipsArray.filter(function(tip){ return tip.recipient.objectId !== recipientId; });
    
          this.tipsArray.splice(index, 1);
    
          console.log("this.tipsArray.length POST: " + this.tipsArray.length);
    
          console.log("this.lastAmountChosen::::: " + this.lastAmountChosen);
          // console.log("this.buttonNumEventObject: " + JSON.stringify(this.buttonNumEventObject, null, 2)); // this may have been part of the Stripe problem I recall it typically happens when users delete a tip and the problem is this method is failing at this point but silently, just nothing further below happens - or not, this could just be a problem on localHost, but no need to do this console log anyway
    
          console.log("this.tipsArray POST: " + JSON.stringify(this.tipsArray, null, 2));
    
          this.doRefreshDisplayStrings();
     
          this.doUpdateAddFeesOrNot(); // in case the add fee box was ticked from prior transaction      
     
          if (this.addFeesCheckboxValue === true) {
             this.amountToPayDisplayLabel = +(this.multipleTipsTotalNumber + this.allFeesToPotentiallyAdd).toFixed(2);
          } else {
            this.amountToPayDisplayLabel = +(this.multipleTipsTotalNumber).toFixed(2);
          }
         
          this.addFeesQuestionLabel = this.allFeesToPotentiallyAdd.toFixed(2);
          if (this.tipper.tipperFX !== 1) {
            this.amountChosenCurrEqDisplayLabel = "≈ " + this.tipper.tipperCurrencySymbol + (this.multipleTipsTotalNumber / this.tipper.tipperFX).toFixed(2);
            
          }
    
    
    
          console.log("this.tipsArray[0].recipient:::: " + JSON.stringify(this.tipsArray[0].recipient, null, 2));// THIS SHOULD WORK! why doesn't it?
          // THIS IS PROBLEMATIC - the sequence I tried was at Josh Wood, try first Alice, then add Billy in process - then delete Billy, then add Bantika so it's Bantika and Alica but then it shows Bantika and Bantika although the amounts are correct, the names are wrong
          // if (this.tipsArray.length === 1) {
          //   this.recipient = this.tipsArray[0].recipient; // the purpose of this is to cater to making sure the eventual cancelled message cites the correct remaining recipient - I didn't look beyond that
          // }
    
    
    
    
    
          const confirmButton = this.$refs.savedSourceOrAPorGPConfirmPayment;
          confirmButton.amountChosen = this.multipleTipsTotalNumber;
    
          this.consoleLogConfirmButton("userCancelSelected");
    
          console.log("just DELETED tip so my button amountChosen NOW is: " + confirmButton.amountChosen);
          
          this.componentKey = !this.componentKey;
    
          this.doSaveThisFullDataSetToLocalStorage("userCancelSelected");
    
          try {
            this.card.update({}); // updating no options, just to see if it is still mounted
            console.log("this.card was still mounted after user deleted a tip");
          } catch (e) {
            if (e.message.indexOf("is still mounted") > -1){
              console.log("ALERT! this.card was not mounted after user deleted a tip");
              const params = {
                toEmail: "appalert@thanku.app",
                subject: "ALERT! this.card was not mounted after user deleted a tip",
                body: "Tipper " + this.tipper.objectId + " experienced the problem where this.card (the card element) was not mounted after user deleted a tip",
              };
    
              Parse.Cloud.run("sendEmail", params);    
            }
          }
    
          if (this.useRyft === true){
            console.log("about to run doRyftPaymentPrepOperations after deleting tip");
            this.doRyftPaymentPrepOperations(); // which will initiate payment sequence in order to update the AP/GP button
          }
    
        }, 
        async userSelected(recipientId, recipientDisplayname, connectedAccountStatus, recipientImageData, recipientDiffCurrency, recipientRyftConnAccId){
    
          // console.log("HERE 0");
    
          if (connectedAccountStatus === false) { // TODO need to deal with Poolmaster multicolleaguetags scenario in due course
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = recipientDisplayname + " needs to add their bank details before they can receive tips with thankU";
            this.showPopUpOk = true;
    
             const params = {
                toEmail: "appalert@thanku.app",
                subject: recipientDisplayname + " needs to add their bank details before they can receive tips with thankU",
                body: "recipient " + recipientDisplayname + " id: " + recipientId + " needs to add their bank details before they can receive tips with thankU",
              };
    
              Parse.Cloud.run("sendEmail", params);    
    
          } else {
    
            this.showRecipientsList = false;
            this.showRecipient = true;
            this.recipient.objectId = recipientId;
            if (recipientRyftConnAccId !== undefined){
              this.recipient.ryftConnAccId = recipientRyftConnAccId;
              // this.initRyft(this.recipient.ryftConnAccId); // we can now do this earlier as soon as we get results from initTipper with hasSavedSource or not
            }
    
            if (this.recipient.objectId !== undefined && this.recipient.objectId === "B1aj1Wzew9") {
              // check payments to this account for fraud
              const params = {
                toEmail: "appalert@thanku.app",
                subject: "FRAUD CHECK! for Lana " + this.recipient.objectId + " " + new Date().toISOString(),
                body: this.recipient.objectId + " Lana was the recipient who was receiving a ton of fraudulent tips at TONI&GUY. CHECK THIS PAYMENT and any on the fingerprint for signs it is happening again",
              };
    
              Parse.Cloud.run("sendEmail", params);     
            }
    
            this.recipient.displayname = recipientDisplayname;
            this.recipientImageDataURL = recipientImageData;
    
            if (this.recipientImageDataURL === undefined) {
              this.recipientImageDataURL =require('@/assets/tulogoapp.png');
              // this.recipientImageDataURLIsNotPhoto = true;
              // console.log("this.recipientImageDataURLIsNotPhoto: " + this.recipientImageDataURLIsNotPhoto)
            } 
    
    
            // console.log("HERE this.recipientImageDataURL: " + this.recipientImageDataURL);
            
            if (recipientDiffCurrency !== undefined) {
              // alert("so here is the diff: " + JSON.stringify(recipientDiffCurrency, null, 2));
              this.recipient.currencyCode = recipientDiffCurrency.currencyCode;
              this.recipient.locale = recipientDiffCurrency.locale;
              this.buttonCurrSymbol = recipientDiffCurrency.currencySymbol;
              
              this.tipper.tipperFX = recipientDiffCurrency.tipperFX;
              // alert("recipientDiffCurrency.buttons: " + JSON.stringify(recipientDiffCurrency.buttons, null, 2));
              this.recipient.button1CurrEqAmount = recipientDiffCurrency.buttons.button1CurrEqAmount;
              this.recipient.button2CurrEqAmount = recipientDiffCurrency.buttons.button2CurrEqAmount;
              this.recipient.button3CurrEqAmount = recipientDiffCurrency.buttons.button3CurrEqAmount;
              this.recipient.button4CurrEqAmount = recipientDiffCurrency.buttons.button4CurrEqAmount;
              // alert("this.recipient.button4CurrEqAmount: " + this.recipient.button4CurrEqAmount);
    
    
              // console.log("recipientDiffCurrency.applicableTipperStripeFees: "+ JSON.stringify(recipientDiffCurrency.applicableTipperStripeFees, null, 2));
              if (recipientDiffCurrency.applicableTipperStripeFees !== undefined){
    
                this.tipper.stripeFees = recipientDiffCurrency.applicableTipperStripeFees;
                this.tipper.stripeFees = this.assignThisTipperStripeFeesParseObjectToJOSNObject(this.tipper.stripeFees);
                // console.log("applicableTipperStripeFees applied: " + JSON.stringify(this.tipper.stripeFees, null, 2));
              } 
              
            } else {
              // same currency
              // console.log("original diffCurr reverting: " + JSON.stringify(this.revertRecipientValues, null, 2));
              this.recipient.currencyCode = this.revertRecipientValues.currencyCode;
              this.recipient.locale = this.revertRecipientValues.locale;
              this.buttonCurrSymbol = this.revertRecipientValues.buttonCurrSymbol;
              // console.log(" this.revertRecipientValues.currencySymbol::: " +  this.revertRecipientValues.currencySymbol);
              // console.log("this.buttonCurrSymbol::: " + this.buttonCurrSymbol);
              this.tipper.tipperFX = this.revertTipperValues.tipperFX;
              // console.log("this.tipper.tipperFX: " + this.tipper.tipperFX);
              this.recipient.button1CurrEqAmount = this.revertRecipientValues.button1CurrEqAmount;
              this.recipient.button2CurrEqAmount = this.revertRecipientValues.button2CurrEqAmount;
              this.recipient.button3CurrEqAmount = this.revertRecipientValues.button3CurrEqAmount;
              this.recipient.button4CurrEqAmount = this.revertRecipientValues.button4CurrEqAmount;
    
              this.tipper.stripeFees = this.revertTipperStripeFees.stripeFees;
              this.tipper.stripeFees = this.assignThisTipperStripeFeesParseObjectToJOSNObject(this.tipper.stripeFees);
              // console.log("original stripeFees reverting: " + JSON.stringify(this.tipper.stripeFees, null, 2));
            
            }
    
    
    
            shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " with IP " + this.tipper.tipperIP + " selected " + this.recipient.displayname + " to tip " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          }
        }, 
       
        async tryStripeAgain(origin){
          this.loggingError = true;
          // const params = {
          //     toEmail: "appalert@thanku.app",
          //     subject: "STRIPE OBJECT UNDEFINED, about to run tryStripeAgain... ",
          //     body: "Tipper id is: " + this.tipper.objectId + "   about to tryStripeAgain from: " + origin + "  " + new Date().toISOString(),
          //   };
    
          // Parse.Cloud.run("sendEmail", params);  
          // console.log("trying Stripe again");
          await this.loadStripeObject('tryStripeAgain');
          
          // const params2 = {
          //     toEmail: "appalert@thanku.app",
          //     subject: "STRIPE OBJECT UNDEFINED, ran tryStripeAgain",
          //     body: "Tipper id is: " + this.tipper.objectId + "   ran tryStripeAgain called from " + origin + "  "  + new Date().toISOString(),
          // };
    
          // Parse.Cloud.run("sendEmail", params2);  
        },
        async loadStripeObject(origin) {
    
          if (origin === undefined) {
            origin = "no origin";
          }
    
          let tipperId = "no tipper id";
    
          try {

            // let e = {
            //   message: "test Stripe fail",
            // }
            // throw e;

            if (this.tipper.objectId !== undefined) {
              tipperId = this.tipper.objectId;
            }
            console.log("about to load Stripe in loadStripeObject coming from: " + origin);  
            shared.saveToUserPath(this.devEnv,  "Tipper id: " + tipperId + " about to load Stripe in loadStripeObject coming from " + origin + "    " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            // stripe = await Stripe(this.stripePublishableKey); this was the old way of doing it where we used <script on index.html
            this.stripe = await loadStripe(this.stripePublishableKey); // now we just bring in when needed
            console.log("JUST completed await load Stripe in loadStripeObject coming from: " + origin);  
            shared.saveToUserPath(this.devEnv,  "Tipper id: " + tipperId + " JUST completed await load Stripe in loadStripeObject coming from " + origin + "    " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            // hang about, if it's failed it's not even going to get here
            if (this.stripe === undefined) {
              window.setTimeout(this.loadStripeObject, 1000, origin); // or we could do tryStripeAgain
              console.log("just set loadStripeObject to run again in 1 second, coming from: " + origin);  
              shared.saveToUserPath(this.devEnv,  "Tipper id: " + tipperId + " just set loadStripeObject to run again in 1 second, coming from: " + origin + "    " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              // const params = {
              //   toEmail: "appalert@thanku.app",
              //   subject: "STRIPE Object was undefined! ",
              //   body: "Tipper id: " + tipperId + " loadStripeObject failed to load Stripe coming from " + origin + " at " + new Date().toISOString(),
              // };
    
              // Parse.Cloud.run("sendEmail", params);  
            }
    
            // console.log("STRIPE KEY:::" + this.stripePublishableKey); // /console.log(stripe);
            // console.log("process.env.VUE_APP_STRIPE_ENV === dev :::: " + (process.env.VUE_APP_STRIPE_ENV === "dev"));
            // console.log(process.env.VUE_APP_STRIPE_ENV);
            return;
          } catch (e) {
            console.log("ERROR on loadStripeObject, likely loadStripe, error is: " + e.message);
            shared.saveToUserPath(this.devEnv,  "Tipper id: " + tipperId +  " ERROR on loadStripeObject, likely loadStripe, error is: " + e.message + " loadStripeObject failed to load Stripe coming from " + origin + " at " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            if (this.loadStripeAttempts <= 10) {
              this.loadStripeAttempts += 1;
              // const params = {
              //   toEmail: "appalert@thanku.app",
              //   subject: "STRIPE Object was undefined! ",
              //   body: "Tipper id: " + tipperId + " ERROR on loadStripeObject, likely loadStripe, error is: " + e.message + " loadStripeObject failed to load Stripe, just setting loadStripeObject to run again in 1 second, coming from " + origin + " at " + new Date().toISOString(),
              // };
              // Parse.Cloud.run("sendEmail", params); 
              shared.saveToUserPath(this.devEnv,  "Tipper id: " + tipperId + " just setting loadStripeObject to run again in 1 second, coming from: " + origin + "    " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              window.setTimeout(this.loadStripeObject, 1000, origin); // or we could do tryStripeAgain
            } else {
              this.loadStripeAttempts = 0;
              this.dontTryStripeAgainInThisSession = true;
              const params = {
                toEmail: "appalert@thanku.app",
                subject: "STRIPE Object was undefined! ",
                body: "Tipper id: " + tipperId + " ERROR on loadStripeObject, likely loadStripe, error is: " + e.message + " loadStripeObject failed to load Stripe after max attempts, coming from " + origin + " at " + new Date().toISOString(),
              };
    
              Parse.Cloud.run("sendEmail", params); 
              shared.saveToUserPath(this.devEnv,  "Tipper id: " + tipperId + " just setting loadStripeObject to run again in 1 second, coming from: " + origin + "    " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              this.doCancelTipWithLoadStripeFailedMessage();
    
            }
          }
    
        },
        doCancelTipWithLoadStripeFailedMessage(){
            this.deployLogRocket(this.logRocketOrganisationSlug);
            this.showProgress = false;
            this.progressIndicator = ".";
            this.hideOKButton = false;
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = "ThankU couldn't complete your tip - there was a problem starting the payment system, please try scanning the QR code again. [TU004]";
            this.showPopUpOk = true;
    
            Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "STRIPE Object load FAILED! ", body: "Tipper id: " + this.tipper.objectId + " loadStripeObject failed to load Stripe at " + new Date().toISOString() + "<br/>this.stripeLoadRetries " + this.stripeLoadRetries + "<br/>User was shown message: ThankU couldn't complete your tip - there was a problem starting the payment system, please try scanning the QR code again. [TU004]",});  
    
            this.showPaymentScreen = true; // because it hadn't even been shown yet
            this.doShowCancelled(); 
        },
        getUserThumbnail(recipientObjectId) {
        
          // console.log("getting userThumbnail");
          var params =  {};
          params["userObjectsArray"] = [{objectId: recipientObjectId}];
          params["mainOrThumb"] = "userPhoto";
    
          Parse.Cloud.run("getUserPhotoThumbs", params).then( TUImageData => {
              var i;
              for (i = 0; i < TUImageData.length; i++) {
                if (TUImageData[i][0] === recipientObjectId){
                    // thisStr += ", " + TUImageData[i][2];
                    this.recipientImageDataURL = 'data:image/' + TUImageData[i][2] + ';base64,' + TUImageData[i][1];
                    // console.log("this.recipientImageDataURL: " + this.recipientImageDataURL);
                } 
              }
          }).catch(function(error){
              // console.log("BROWSER getUserPhotoThumbs FAILED");
              console.error("getUserThumbnail ERROR: " + error.message);
              throw error;
          });
        },
        getVenueUserThumbnails(linkedUserObjectsArray) {
         
         
    
          var params =  {};
    
          // FOR SOME REASON, CANNOT PASS params with sub objects in them why is that? check Parse Server documentation 
          // https://stackoverflow.com/questions/33610227/error-parse-objects-not-allowed-here
    
          for (const obj of linkedUserObjectsArray){
            if (obj.diffCurrency !== undefined) {
              obj.diffCurrency = undefined;
              // console.log("did it!");
            }
          }
          params["userObjectsArray"] = linkedUserObjectsArray;
          params["mainOrThumb"] = "userPhotoThumb";
    
          // console.log("getting ALL userThumbnails");
    
          // console.log("params pics is: " + JSON.stringify(params, null, 2));
    
          Parse.Cloud.run("getUserPhotoThumbs", params).then( TUImageData => {
              var i;
    
              // console.log("CAME BACK!");
              // console.log("TUImageData: " + TUImageData);
    
              if (TUImageData !== undefined){
                for (i = 0; i < TUImageData.length; i++) {
    
                  var z;
                  for (z = 0; z < this.linkedUsersArrayObject.length; z++) {
                    if (TUImageData[i][0] === this.linkedUsersArrayObject[z].objectId){
                      // console.log("making array on " + i); 
                      this.linkedUsersArrayObject[z].imageData = TUImageData[i][1];
                      this.linkedUsersArrayObject[z].imageFileType = TUImageData[i][2];
                    }
                  }
                }
              }
          }).catch(function(error){
              // console.log("BROWSER getUserPhotoThumbs FAILED");
              console.error("getVenueUserThumbnails ERROR: " + error.message);
              throw error;
          });
        },
        async initTipper() {
          // /console.log ("initTipper");
    
          // this.tuid = '00000000002'; // set this manually when doing local dev testing
          
          var params = this.getTipperParams();
          
          try {
            // /console.log("timestamp PRE " + new Date().toISOString());
            const TUData = await Parse.Cloud.run("initTipper", params);  
            // takes about 650ms on laptop running local server (shouldn't make a difference)
            // /console.log("timestamp POST " + new Date().toISOString());
            // console.log(JSON.stringify(TUData, null, 2));
            this.TUData = TUData;  
            this.initTipperError = false;
            return;
    
          } catch (e) {
            // /console.log("BROWSER initTipper FAILED");
            // this.deployLogRocket(this.logRocketOrganisationSlug);
            console.error(e);
            this.initTipperError = true;
            throw e;
          } 
        },
    
        async deployReturnedData() {
    
          // trying to do as much as possible using the actual objects returned - remember these objects are not exact replicas of the Parse DB objects, just a few of the fields and one or two with changed field names
          // this.checkTUDataForParseObjects(); // STRIPEFIX DEV
          // try {
          this.venue = this.TUData[0]['TUIDVenue'];
          // } catch {}
    
          console.log("this.venue: " + JSON.stringify(this.venue, null, 2));
          if (this.venue !== undefined && this.venue.addFeesTicked !== undefined && this.venue.addFeesTicked === true){
            
            this.willDefaultToFeesTicked = true;
          }
    
          if (this.venue !== undefined && this.venue.useRyft !== undefined) {
            this.useRyft = this.venue.useRyft;
            this.paddingTopFeesOptionsTop = 0;
            console.log("using Ryft for this venue!");
            try {
              if (Ryft===undefined){ // we have to do try catch because it is a promise somehow
                // will never get here, will be thrown is undefined
              } else {
                console.log("Ryft is defined");
              }
            } catch (e) {
              console.log("Ryft is undefined, starting Ryft");
              this.doLoadRyft();
            }
          } else {
            // Load Ryft anyway for testing
             try {
                if (Ryft===undefined){ // we have to do try catch because it is a promise somehow
                  // will never get here, will be thrown is undefined
                } else {
                  console.log("Ryft is defined");
                }
              } catch (e) {
                console.log("Ryft is undefined but WE AREN'T using Ryft, starting Ryft");
                this.doLoadRyft();
              }
            this.canPay = true; // canPay is only used for Ryft and is used for enabling and disabling the payment confirmation button, which is share with, and always enabled on Stripe, irrespective of how user is going to pay, so need to make sure it is enabled here
          }
    
          if (this.venue.doNotShowGetTipsHereButton !== undefined){
            this.doNotShowGetTipsHereButton = true;
            if (this.showGetTipsHereButton === true){
              this.showGetTipsHere = true;
            } else {
              this.showGetTipsHere = false;
            }
            
          } else {
            this.doNotShowGetTipsHereButton = false;
            this.showGetTipsHere = true; // for completeness
          }
    
          // let logRocketOrganisationSlug;
    
          this.logRocketOrganisationSlug = "";
    
          if (this.venue !== undefined && this.venue.dontUseMultiTip !== undefined && this.venue.dontUseMultiTip === true){
            this.usingMultiTips = false;
            console.log("this.usingMultiTips at 01 is NOW " + this.usingMultiTips);
          } else {
            
            this.usingMultiTips = true; // just to be sure
            console.log("this is how we are on this.usingMultiTips: " + this.usingMultiTips);
          }
    
          this.doTipDeletionsAndAdditions = true; // just in case
    
          if (this.venue.venueID !== undefined){
    
            if (this.venue.venueID === "397") { // HARI's Kings Road Demo
              this.logRocketOrganisationSlug = 'haris-kings-road/haris-kings-road';
            } else if (this.venue.venueID === "398"){ // HARI's Northcote Road
              this.logRocketOrganisationSlug = 'haris-northcote-road/haris-northcote-road';
              // this.doTipDeletionsAndAdditions = true;
              // this.usingMultiTips = true;
            } else if (this.venue.venueID === "399"){ // HARI's Notting Hill
              this.logRocketOrganisationSlug = 'haris-notting-hill/haris-notting-hill';
              // this.usingMultiTips = true;
            } else if (this.venue.venueID === "400"){ // HARI's Parsons Green
              this.logRocketOrganisationSlug = 'haris-parsons-green/haris-parsons-green';
              
            } else if (this.venue.venueID === "401"){ // HARI's South Kensington
              this.logRocketOrganisationSlug = 'haris-south-ken/haris-south-ken';
              // this.doTipDeletionsAndAdditions = true;
    
              // this.usingMultiTips = false;
    
            } else if (this.venue.venueID === "360"){ // Larry King South Kensington
              this.logRocketOrganisationSlug = 'larry-king-south-ken/larry-king-south-ken';
            } else if (this.venue.venueID === "361"){ // Larry Kig Westbourne Grove
              this.logRocketOrganisationSlug = 'larry-king-wbg/larry-king-wbg';
            } else if (this.venue.venueID === "535"){ // Larry King Marylebone
              this.logRocketOrganisationSlug = 'lk-marylebone/lk-marylebone';
            } else if (this.venue.venueID === "218"){ // Josh Wood
              this.logRocketOrganisationSlug = 'josh-wood/josh-wood';
            } else if (this.venue.venueID === "309" || this.venue.venueID === "334" || this.venue.venueID === "335" || this.venue.venueID === "394") {
              this.logRocketOrganisationSlug = 'edward-james/edward-james';
            }
          }
    
          this.recipient =  this.TUData[1]['TUIDRecipient'];
    
         
          // console.log("here 1");
          this.revertRecipientValues = {
            currencyCode: this.recipient.currencyCode,
            locale: this.recipient.locale,
            buttonCurrSymbol: this.recipient.currencySymbol,
            button1CurrEqAmount: this.recipient.button1CurrEqAmount,
            button2CurrEqAmount: this.recipient.button2CurrEqAmount,
            button3CurrEqAmount: this.recipient.button3CurrEqAmount,
            button4CurrEqAmount: this.recipient.button4CurrEqAmount,
          };
    
          // console.log("WE JUST SET this.revertRecipientValues:::: " + JSON.stringify(this.revertRecipientValues, null, 2));
    
          this.recipient.button5Amount = 0; // set initial value
          this.buttonCurrSymbol = this.recipient.currencySymbol;
    
          // console.log("here 2");
          // /console.log("this.recipient.feesAtUserOption 1 ::: " + this.recipient.feesAtUserOption);
          this.global =  this.TUData[2]['GlobalVariables'];
          this.tipper = this.TUData[3]['tipper'];
    
          // console.log("this.tipper: " + JSON.stringify(this.tipper, null, 2));
    
          // this.usingMultiTips = true; // NB CAREFUL!!! this is the global switch on for multitips
          // this.usingMultiTips = false;
    
    
          if (this.logRocketOrganisationSlug !== ""){
            this.deployLogRocket(this.logRocketOrganisationSlug);
          } else {
            this.deployLogRocket(this.logRocketOrganisationSlug);  
          }
    
    
          // console.log("this.tipper.objectId: " + this.tipper.objectId);
    
          if (this.tipper.objectId === "MGn8PRhGgU" || this.tipper.objectId === "PtmG6XrNB5" || this.tipper.objectId === "BGDPl30Hau" || this.tipper.objectId === "QBsfDRkKHy" || this.tipper.objectId === "IuUDkuGpX8" || this.tipper.objectId === "sV8CdytZWR" ){    
    
            // console.log("MET THE CONDITION");
            // die, this guy is a fraudster
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "FRAUDSTER stopped! " + this.tipper.objectId + " " + new Date().toISOString(),
              body: this.tipper.objectId + " was the user who was trying to do a ton of fraudulent tips to Lana at TONI&GUY. They have been stopped from proceeding.",
            };
    
            Parse.Cloud.run("sendEmail", params);
            return;
          } else if (this.tipper.objectId === "kw57ktkRHW") {
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "FRAUDSTER stopped! " + this.tipper.objectId + " " + new Date().toISOString(),
              body: this.tipper.objectId + " was the user Marty who was trying to do a number of fraudulent tips to Anila & Dennis at Marcela's. They have been stopped from proceeding.",
            };
    
            Parse.Cloud.run("sendEmail", params);
            return;
          } else if (this.tipper.objectId === "h5l9NBiHaI") { // ALEXANDER GEORGE to BEATRIZ GAUDELOUPE at Duck HN
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "FRAUDSTER stopped! " + this.tipper.objectId + " " + new Date().toISOString(),
              body: this.tipper.objectId + " was the user ALEXANDER GEORGE who was trying to do a number of fraudulent tips to Anila & Dennis at Marcela's. They have been stopped from proceeding.",
            };
    
            Parse.Cloud.run("sendEmail", params);
            return;
          }
    
    
    
          // if (this.useRyft === true) {
          //   let paymentSession = await Parse.Cloud.run("createBlankRyftPaymentRequest", {userObjectId: this.tipper.objectId});   
          //   console.log("paymentSession details: " + JSON.stringify(paymentSession, null, 2));
          //   this.initRyft(paymentSession.clientSecret);
          //   this.ryftPaymentSessionId = paymentSession.id;
          // }
    
    
          this.revertTipperValues = {
            tipperFX : this.tipper.tipperFX,
          }
    
          // console.log("here 3");
          this.revertTipperStripeFees = {
            stripeFees: this.tipper.stripeFees,
          }
    
          if (this.venue.additionalField !== undefined) {
            this.additionalField = this.venue.additionalField;
          } else if (this.venue !== undefined && this.venue.name !== undefined){
            this.additionalField = this.venue.name;
          }
    
          // console.log("here 4");
    
          this.tipper.stripeFees = this.assignThisTipperStripeFeesParseObjectToJOSNObject(this.tipper.stripeFees); // convert Parse Object to regular JSON object and get rid of .get(" approach, whcih then enables us to reparse all the data on an app reload
          // NOTE these actually get changed in userSelected anyway but here we are just using to pick up this.tipperStripeFeesFromDB which we may not need until later anyway...
          this.tipperStripeFeesFromDB = this.tipper.stripeFees.PlatformTypePercent !== undefined ? this.tipper.stripeFees.PlatformTypePercent : 0; //
          // console.log("IN deployReturnedData: this.tipperStripeFeesFromDB: " + this.tipperStripeFeesFromDB);
          // console.log("IN deployReturnedData: this.tipper.stripeFees: " + JSON.stringify(this.tipper.stripeFees, null, 2));
    
          window.localStorage.setItem("tuob", this.tipper.objectId); // if you don't set it here it won't be picked up
    
          if (this.tipper.willConnectRealUserDetails !== undefined && this.tipper.willConnectRealUserDetails === true){
    
            window.localStorage.setItem("willConnectRealUserDetails", "yes");
            // alert("TIPPING " + window.localStorage.getItem("willConnectRealUserDetails"));
            this.popUpMsgTitle = "Let's connect";
            this.popUpMsgBody = "Welcome to ThankU - it takes about a minute to get set up - please have your bank details to hand...";
            this.showPopUpOk = true;
            console.log("here 5");
            this.linkedUsersArrayObject = []; // make it empty not undefined so it doesn't break the code
            return;
          }
    
          // console.log("here 6");
    
          if (this.tipper.tipperTipFromLastFewMinutes !== undefined && this.tipper.tipperTipFromLastFewMinutes !== "no") {
            // console.log("YES we tipped in last few minutes, deploying logrocket");
            // this.deployLogRocket(this.logRocketOrganisationSlug); // either a new tip to a new recipient or potentially a 'double'/duplicate tip for whatever reason
            // no point doing this from an evidential standpoint as one loses most STripe disputers and they don't allow video evidence anyway
            // console.log("about to show tipperTipFromLastFewMinutes value...");
            window.setTimeout(console.log(this.tipper.tipperTipFromLastFewMinutes), 2000); //  
          } else {
            // console.log("NO TIP in last few minutes");
          }
    
          if (this.tipper.TUIDsOfVenuesConnectedAt) {
            window.localStorage.setItem("TUIDsOfVenuesConnectedAt", this.tipper.TUIDsOfVenuesConnectedAt);
          }
    
          if (this.tipper.hasSetEmail){
            this.hasSetEmail = this.tipper.hasSetEmail;
          }
    
          if (this.tipper.hasSetPassword){
            this.hasSetPassword = this.tipper.hasSetPassword;
          }
    
    
          this.linkedUsersArrayObject =  this.TUData[4]['linkedUsersArrayObject'];
    
          // console.log("this.TUData[4]['linkedUsersArrayObject'];: " + JSON.stringify(this.TUData[4]['linkedUsersArrayObject'], null, 2));
    
          // NOTE this is the section where it sends connected recipients back to their Dashboard if they are trying to tip at their own salon but since we know that a number of them actually tip their assistants etc so we are taking out this functionality for now
    
          // for (const linkedUser of this.linkedUsersArrayObject){
          //   if (this.tipper.objectId === linkedUser.objectId){ // this is to redirect a recipient who scans at their salon
          //     // console.log("going home");
          //     window.location.search = "";
          //     this.doShowHome();
          //     return;
          //   }
    
          // }
    
          if (this.venue.venueID === "397" || this.venue.venueID === "398" || this.venue.venueID === "399"|| this.venue.venueID === "400" || this.venue.venueID === "401" || this.venue.venueID === "547"){ // all the HARIs and Daniel Galvin Marylebone
              this.linkedUsersArrayObject = this.doSortForAssistantsFirst(this.linkedUsersArrayObject);
          }
    
          for (let i = 0; i < this.linkedUsersArrayObject.length; i++){
      
            if (this.linkedUsersArrayObject[i].recentlyTippedDate !== undefined){
            // if (this.linkedUsersArrayObject[i].displayname === "Lilly"){
              // this.linkedUsersArrayObject[i].recentlyTippedDate = "01/11/24";
              // this.linkedUsersArrayObject[i].lastTippedAmount = "£100";
              // this.linkedUsersArrayObject[i].displayname = "Lilly Foccacia";
              // const item = this.linkedUsersArrayObject.splice(i, 1)[0];
              // this.linkedUsersArrayObject.unshift(item);
    
              // Create a copy of the item
              const item = {...this.linkedUsersArrayObject[i]};
              // Unshift the copy to the beginning of the array
              this.linkedUsersArrayObject.unshift(item);
              // Increment i to skip the newly added duplicate in further iterations
              i++;
              
            }
          }
    
          this.linkedUsersArrayObjectFiltered = this.linkedUsersArrayObject;
    
          if (this.tipper.sessionToken) { // there should be
            // /console.log("ok linking this user up...");
            await this.getUser();
          }
    
          if (this.tipper.userPhotoFileType !== undefined) {
            // /alert("set this.tipper.userPhotoFileType: " + this.tipper.userPhotoFileType);
            window.localStorage.setItem("userPhotoFileType", this.tipper.userPhotoFileType);
          } else {
            // /alert(" defaulted userPhotoFileType to JPEG: ");
            window.localStorage.setItem("userPhotoFileType", "jpeg"); // default just in case
          }
    
          const userPhotoFileType = window.localStorage.getItem("userPhotoFileType");
    
          if (this.tipper.userPhotoFilename) {
            // has been updated
              window.localStorage.setItem("userPhotoFilename", this.tipper.userPhotoFilename);
              window.localStorage.setItem("userPhotoFileData", 'data:image/' + userPhotoFileType + ';base64,' + this.tipper.userPhotoFileData);
              // /alert("set localStorage userPhotoFilename ON TIPPING: " + this.tipper.userPhotoFilename);
              // /alert("set localStorage userPhotoFileData: " + this.tipper.userPhotoFileData);
          } else {
              // /alert("did NOT set localstorage userPhotoFilename or userPhotoFileData");
          }
    
          this.TUData = undefined; // clear memory
    
          this.detectShowIfUKPlatformCardCountryUnknown(false);
    
          if (this.recipient.feesAtUserOption !== undefined){
              this.feesAtUserOption = this.recipient.feesAtUserOption; // at this initial point, it will pick up the value whether for an individual, or much more usually, for the poolmaster which is critical to do now before the user gets changed to the actual recipient which won't have this value
          }
    
          if (this.recipient.feesAtUserOption === true){
            // NEW system
            // console.log("at user option");
            this.thankUFeeToAdd = 0.0;
            this.thankUFeePotentiallyToAdd = this.global.TipperFeePercentWeb;
    
            // console.log("this.global.TipperFeePercentWeb: " + this.global.TipperFeePercentWeb);
    
            // the following relied on individually setting the override, prone to error, we now know we are ONLY using 3% on the new QR version    
    
            // no using it now for HARI's
            if (this.recipient.thankUFeePercentOverride !== undefined) {
                this.thankUFeePotentiallyToAdd = this.recipient.thankUFeePercentOverride; // new system
            }
    
            // no need to look at thankUFeePercentTfrToRecipient because it's either happening 100% or its not and will calc on backend - value cant affect what user sees here
    
          } else {
            // OLD system note - we could if we still wanted add 3% 5% whatever to the tip, ie just the ThankU fee - BUT we happen to be using it right now exclusively for transferring the fee to be paid by the recipient
    
            this.thankUFeeToAdd = this.global.TipperFeePercent; //global val
            this.thankUFeePotentiallyToAdd = this.global.TipperFeePercent; // not used but just in case
    
            if (this.recipient.thankUFeePercentOverride !== undefined) {
              this.thankUFeeToAdd = this.recipient.thankUFeePercentOverride; 
            }
    
            if (this.recipient.thankUFeePercentTfrToRecipient !== undefined) {
              this.thankUFeeToAdd = this.thankUFeeToAdd - this.recipient.thankUFeePercentTfrToRecipient; 
            }
    
          }
    
    
          if (this.linkedUsersArrayObject.length > 0 || (this.recipient.isPoolMaster === true && this.recipient.dontShowPoolmasterInList === true)){
    
            // if we want a direct tip relationship, there is still a venue relationship in the background, however switch poolmaster to false on the user record to ensure the 'else' limb triggers here
            // console.log("about to show recipient sscreen");
            this.showRecipientScreen();
            
            if (window.localStorage.getItem("tuob") !== null) {
              if (window.localStorage.getItem("tuob") === "4g278vWUb1"){
                this.showMakeName = true;
              }
            }
            
            this.showRecipient = false;
            this.getVenueUserThumbnails(this.linkedUsersArrayObject); // NOT an await or it will block everything subsequent, equally now we have the data we don't want to wait any longer before firing it off
            
            if (this.useRyft === true) {
              this.getRyftAPandGPStatusFromTestRyftPaymentSession(); // not await, it is just going to return some AP GP status variables
            } else {
              this.getRyftAPandGPStatusFromTestRyftPaymentSession(); // not await, it is just going to return some AP GP status variables AND WE ARE TESTING IN PARRALEL WITH STRIPE
              await this.loadStripeObject("deployReturnedData linkedUsers");
            }
            
          } else {
            
            if (this.recipient.userPhotoThumb) {
              this.getUserThumbnail(this.recipient.objectId);
            }
    
            if (this.useRyft === true) {
              this.getRyftAPandGPStatusFromTestRyftPaymentSession(); // not await, it is just going to return some AP GP status variables
            } else {
              this.getRyftAPandGPStatusFromTestRyftPaymentSession(); // not await, it is just going to return some AP GP status variables AND WE ARE TESTING IN PARRALEL WITH STRIPE
              await this.loadStripeObject("deployReturnedData one user"); 
            }
    
            this.userSelected(this.recipient.objectId, this.recipient.displayname, this.recipient.connectedAccountStatus);
          }
    
        },
        async getRyftAPandGPStatusFromTestRyftPaymentSession(){
          try {

            this.createRyftPaymentRequestForAPandGPStatusOnlyResponse = await Parse.Cloud.run("createRyftPaymentRequestForAPandGPStatusOnly", {userObjectId: this.tipper.objectId});  // objectId is just to get a unique email hash with timestamp
            
            // let e = {
            //   message: "TEST FAILURE on getRyftAPandGPStatusFromTestRyftPaymentSession",
            // }
            // throw e;
            
            // console.log("createRyftPaymentRequestForAPandGPStatusOnlyResponse: " + JSON.stringify(createRyftPaymentRequestForAPandGPStatusOnlyResponse, null, 2));

            // createRyftPaymentRequestForAPandGPStatusOnlyResponse: {
            //   "id": "ps_01JJFJW5YMNHFAH6KRJ5HEYHX3",
            //   "amount": 30,
            // ...
            //   "customerDetails": {
            //     "id": "cus_01JEKVJAM1ZJQX8TEN6HAJH462",
            //     "metadata": {
            //       "userEmailAtTimeOfCreation": "creator@thanku.app",
            //       "userObjectId": "4g278vWUb1"
            //     }
            //   },
            // ...
            //   "ryftPaymentMethodsJSON": {
            //     "items": [
            //       {
            //         "id": "pmt_01JE...",
            //         "type": "Card",
            //         "card": {
            //           "scheme": "Mastercard",
            //           "last4": "2...",
            //           "expiryMonth": "0.",
            //           "expiryYear": "20.."
            //         },
            //         "customerId": "cus_01JEKV...",
            //         "createdTimestamp": 1733689273
            //       }
            //     ]
            //   }
            // }

            // this.paymentSessionClientSecret = createRyftPaymentRequestForAPandGPStatusOnlyResponse.paymentSessionClientSecret;
            
            // OK THIS IS CRITICAL!!!!! If there are NO SAVED PAYMENT DETAILS, THEN THERE WILL BE NO ryft-pay-new-card-divider-block AND without that we have no way of detecting of Google Pay is available - therefore we have to supply some stored payment details which are mine, and I cant EVER delete my original customer on Ryft or this whole thing will fall over. Hence why I am passing ryftPaymentMethodsJSON below...

            this.ryftSessionType = "testAPandGPStatus";
            this.tryInitRyftForAPandGPStatusOnlyResponse();

          } catch (e){

            console.log("ERROR in getRyftAPandGPStatusFromTestRyftPaymentSession: " + e.message);
            Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "RYFT TEST FAILURES ERROR: " + this.tipper.objectId, body: "ERROR response on getRyftAPandGPStatusFromTestRyftPaymentSession: " + e.message + "<br/><br/>UseRyft: " + this.useRyft, });  
            
          }
        },
        tryInitRyftForAPandGPStatusOnlyResponse(){
          
          try {
            if (this.testIsRyftLoaded() === true){
              console.log("testIsRyftLoaded was true")
              this.initRyft(this.createRyftPaymentRequestForAPandGPStatusOnlyResponse.clientSecret, this.createRyftPaymentRequestForAPandGPStatusOnlyResponse.ryftPaymentMethodsJSON, true, true);
            } else {
              console.log("testIsRyftLoaded was FALSE!")
              window.setTimeout(this.testForRetryRyftLoad, 300);
            }
          } catch (e) {
            this.lastErrorOnTryInitRyftForAPandGPStatusOnlyResponse = e.message;
            console.log("ERROR in tryInitRyftForAPandGPStatusOnlyResponse: " + e.message);
            window.setTimeout(this.testForRetryRyftLoad, 300);
          }
        },
        testForRetryRyftLoad(){

          this.ryftLoadTries += 1;

          if (this.ryftLoadTries < 3){
            window.setTimeout(this.tryInitRyftForAPandGPStatusOnlyResponse, 300); // maybe it loaded slow, so retry the whole process
          } else if (this.ryftLoadTries === 3){
            this.doLoadRyft(true); // sounds like it properly failed to load
          } else if (this.ryftLoadTries < 6){
            window.setTimeout(this.tryInitRyftForAPandGPStatusOnlyResponse, 300); // try a couple more times
          } else {
            console.log("ALERT! Ryft FAILED to load for tipper: " + this.tipper.objectId + "ERROR response on tryInitRyftForAPandGPStatusOnlyResponse: " + this.lastErrorOnTryInitRyftForAPandGPStatusOnlyResponse + "\nUseRyft: " + this.useRyft + "\nthis.ryftLoadTries: " + this.ryftLoadTries);
            Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ALERT! Ryft FAILED to load for tipper: " + this.tipper.objectId, body: "ERROR response on tryInitRyftForAPandGPStatusOnlyResponse: " + this.lastErrorOnTryInitRyftForAPandGPStatusOnlyResponse + "<br/>UseRyft: " + this.useRyft + "<br/>this.ryftLoadTries: " + this.ryftLoadTries, });  
          }
            
        },
        testIsRyftLoaded(){

          // if (this.ryftLoadTries < 3){
          //   return false;
          // } else if (this.ryftLoadTries === 3){
          //   return true;
          // } else if (this.ryftLoadTries < 6){
          //   return false;
          // } else {
          //   return false;
          // }
          

          try {
            if (Ryft===undefined){ // we have to do try catch because it is a promise somehow
              // will never get here, will be thrown is undefined
              return false; // for completneness
            } else {
              console.log("Ryft is defined on testIsRyftLoaded");
              return true;
            }
          } catch (e) {
            console.log("Ryft is undefined on testIsRyftLoaded, starting Ryft");
            return false;
          }
        },
        assignThisTipperStripeFeesParseObjectToJOSNObject(stripeFees){
    
          try {
            // this is needed because in all other instances ParseQueryDB passes back data already on selective fields as Parse Objects, but in the case of stripeFees it passed back the whole record as a Stripe object - this could have been done on the backend but to there's a lot going on and this ensures there won't be a disconnect between updating the backend and the front end
            return {
                PlatformId: stripeFees.get("PlatformId") ,
                PlatformTypeOnTopAmount: stripeFees.get("PlatformTypeOnTopAmount"),
                PlatformTypeInboundCurrencyPercent: stripeFees.get("PlatformTypeInboundCurrencyPercent"),
                PlatformTypeOnwardCurrencyPercent: stripeFees.get("PlatformTypeOnwardCurrencyPercent"),
                PlatformTypeThankUPercent: stripeFees.get("PlatformTypeThankUPercent"),
                PlatformTypePayoutVolumePercentFee: stripeFees.get("PlatformTypePayoutVolumePercentFee"),
                Platform: stripeFees.get("Platform"),
                PlatformTypePercent: stripeFees.get("PlatformTypePercent"),
                Type: stripeFees.get("Type"),
                PlatformTypePayoutFlatFee: stripeFees.get("PlatformTypePayoutFlatFee"),
                PlatformTypeCurrency: stripeFees.get("PlatformTypeCurrency"),
            }
          } catch (e) {
            console.log("ERROR on assignThisTipperStripeFeesParseObjectToJOSNObject: " + e.message);
            return stripeFees; // it had probably already been converted
          }
        },
        checkAndLogParseObjects(data) {
          let parseObjects = []; // Array to store found ParseObjects
    
          const findParseObjects = (item) => {
            if (item instanceof Parse.Object) {
              parseObjects.push(item); // Add the ParseObject to the list
            }
            if (Array.isArray(item)) {
              item.forEach(subItem => findParseObjects(subItem)); // Recurse into array elements
            } else if (item !== null && typeof item === 'object') {
              Object.values(item).forEach(value => findParseObjects(value)); // Recurse into object properties
            }
          };
    
          findParseObjects(data);
          return parseObjects;
        },
        checkTUDataForParseObjects() {
          // STRIPEFIX DEV
          const foundParseObjects = this.checkAndLogParseObjects(this.TUData);
          console.log('ParseObjects found in TUData:', foundParseObjects.length > 0 ? JSON.stringify(foundParseObjects, null ,2) : "None found");
    
          // ok so the only ParseObject is the one which threw the error in the first place: this.tipper.stripeFees, and that's because it is the only one being returned whcih comes striaght from the Parse DB result rather than being made into it sown JSON object
    
          
        },
        doSortForAssistantsFirst(linkedUsersArrayObject){
    
          let assistantsArray = linkedUsersArrayObject.filter(item => item.displayname.toLowerCase().includes("assistant "));
          let mainArray = linkedUsersArrayObject.filter(item => !item.displayname.toLowerCase().includes("assistant "));
          
          linkedUsersArrayObject = [...assistantsArray, ...mainArray ]; 
    
          return linkedUsersArrayObject;
        },
        detectShowIfUKPlatformCardCountryUnknown(ignoreSource){
          if (this.recipient.currencyCode == "GBP"){
            if (this.tipper.hasSavedPaymentSource && ignoreSource === false){
              // do nothing we know the issuing country
              // /console.log("has Saved Source")
            } else {
              this.showIfUKPlatformCardCountryUnknown = true;
              // /console.log("this.showIfUKPlatformCardCountryUnknown::: " + this.showIfUKPlatformCardCountryUnknown);
            }
          }
        },
        async getUser(){
    
          // see design rationale in OneNote
    
          // if we created a new user we are going to override localStorage IF this function is called
    
          if (this.tipper !== undefined && this.tipper.sessionToken !== undefined){
            
              // /console.log("setting tipper session");
              window.localStorage.setItem("tu", this.tipper.sessionToken);
              window.localStorage.setItem("tuob", this.tipper.objectId);
    
              if (this.tipper.displayname) {
                window.localStorage.setItem("tudis", this.tipper.displayname);
              }
          }
    
          if (window.localStorage.getItem("tu")){
            this.sessionToken = window.localStorage.getItem("tu");
            // /console.log("Session token: " + this.sessionToken);
          } else {
            // /console.log("no user session yet");
          }
    
          if (window.localStorage.getItem("tuob")){
            this.userObjectId = window.localStorage.getItem("tuob");
            // console.log("getUser userObjectId: " + this.userObjectId);
          } else {
            // console.log("no user yet");
          }
        },
        initOtherAmountScreen(){
          this.showOwnTip = true; 
          this.$refs['other-amount-input'].value=0;
          this.updateOtherAmountButtonString();
        },
        updateButton5(event){
    
          if (
            (!isFinite(event.key) && 
            // event.key !== '.' && actually you know what, noone ever tried to tip 50p, fuck it 
            event.keyCode !== 8 && //Backspace
            event.keyCode !== 46) // Delete
            || event.key === ' ' 
            || event.key === '.' 
            || event.key.toLowerCase() === 'e' // I truly don't know why 'e' is let through, maybe exponential'
            ) {
    
            this.$refs['other-amount-input'].value = ''; // ok so weird thing: if you input a decimal point, it will remain even though it isn't in the character count and doesn't register as part of the value. This line clears it and then setting the old value from the pay button before it's updated works fine
            if (this.recipient.button5Amount === ''){
              this.recipient.button5Amount = 0;
            }
            this.$refs['other-amount-input'].value = this.recipient.button5Amount; // i.e. it's existing value
    
            if (this.enteredSomethingInvalidToOtherAmount === false) {
              shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " keyed INVALID input into other amount: " + event.keyCode + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              this.enteredSomethingInvalidToOtherAmount = true;
            }
            
          } else {
            // ok it's valid input
              if (this.enteredSomethingValidToOtherAmount === false) {
                shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " keyed valid input into other amount: " + event.keyCode + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
                this.enteredSomethingValidToOtherAmount = true;
              }
          }
    
          let isHARIs = (
            this.venue.venueID === "397" || // HARI's Kings Road Demo
            this.venue.venueID === "398" || // HARI's Northcote Road
            this.venue.venueID === "399" || // HARI's Notting Hill
            this.venue.venueID === "400" || // HARI's Parsons Green
            this.venue.venueID === "401"  // HARI's South Kensington)
            ) === true ? true : false;
    
          if (this.$refs['other-amount-input'].value === '') {
            // we always need to have some value or the input box disappears
            this.$refs['other-amount-input'].value = 0;
          } else if(
            this.$refs['other-amount-input'].value.substr(0,1) === '0') {
            // if we had an artifical 0 we need to get rid of it when the user enters a correct value
            this.$refs['other-amount-input'].value = this.$refs['other-amount-input'].value.substr(1);
          } else if (+(this.$refs['other-amount-input'].value) > 400 && (isHARIs === true)){
            this.$refs['other-amount-input'].value = this.recipient.button5Amount; // i.e. the amount before it was chnaged   
            this.popUpMsgTitle = "Sorry";         
            this.popUpMsgBody = "the maximum tip you can give with ThankU is " + this.buttonCurrSymbol + "400";
            this.showPopUpOk = true;
            this.$refs['other-amount-input'].blur();
            // this.$refs['show-popup-ok'].focus();
          } else if ((+(this.$refs['other-amount-input'].value) > 200) && isHARIs === false){
            this.$refs['other-amount-input'].value = this.recipient.button5Amount; // i.e. the amount before it was chnaged   
            this.popUpMsgTitle = "Sorry";         
            this.popUpMsgBody = "the maximum tip you can give with ThankU is " + this.buttonCurrSymbol + "200";
            this.showPopUpOk = true;
            this.$refs['other-amount-input'].blur();
            // this.$refs['show-popup-ok'].focus();
          }
    
          this.updateOtherAmountButtonString();
        },
        updateOtherAmountButtonString(){
          let boxValue = this.$refs['other-amount-input'].value;
          let numChars = boxValue.length;
          this.inputAmountWidth=(24 * numChars); // 24 is estimated width of a character
          this.recipient.button5Amount = boxValue;
          // /console.log("setting this.recipient.button5Amount" + this.recipient.button5Amount);
          if (this.tipper.tipperFX !== 1) {
            this.recipient.button5CurrEqAmount = "≈ " + this.tipper.tipperCurrencySymbol + (boxValue / this.tipper.tipperFX).toFixed(2);
          }
        },
        showPaymentSubmitted(){
          this.showRecipient = false;
          this.showConfirmation = false;
          this.showCardInput = false;
          this.paymentSubmitted = true;
          console.log("showPaymentSubmitted");
        },
        doPayment(buttonNum, event){
    
          let stageVar = 0;
    
          try {
    
            // if we are still getting the ocassional 'undefined is not an object (evaluating 'Co.paymentRequest') TU002 error it seems to be becuase this button occasionally fires twice - we could put it in a triggered flag which then gets cleared?
    
            console.log("I'm doing doPayment, the event is " + JSON.stringify(event, null, 2) + "  selected payment button: " + buttonNum );
    
            shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " selected payment button: " + buttonNum + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            stageVar = 1;
    
            if (this.doCheckRecipientConnectedAccountStatus() !== true){ 
              shared.saveToUserPath(this.devEnv, "Recipient " + this.recipient.displayname + " was unable to receive tips " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              return 
            }
    
            if (this.showOwnTip === true){
              this.showOwnTip = false; // we had situations where for some reason the payment screen doesn't come up and this was left
            }
            
    
            // console.log(buttonNum);
            var amountChosen;
          
            if (buttonNum === "1"){
              amountChosen = this.recipient.button1Amount;
              // this.buttonCurrEqAmount = this.recipient.button1CurrEqAmount;
            } else if (buttonNum === "2") {
              amountChosen = this.recipient.button2Amount;
              // this.buttonCurrEqAmount = this.recipient.button2CurrEqAmount;
            } else if (buttonNum === "3") {
              amountChosen = this.recipient.button3Amount;
              // this.buttonCurrEqAmount = this.recipient.button3CurrEqAmount;
            } else if (buttonNum === "4") {
              amountChosen = this.recipient.button4Amount;
              // this.buttonCurrEqAmount = this.recipient.button4CurrEqAmount;
            } else if (buttonNum === "5") {
              amountChosen = this.recipient.button5Amount;
              // this.buttonCurrEqAmount = this.recipient.button5CurrEqAmount;
            }
            stageVar = 2;
    
            if (buttonNum === "5" && +(this.$refs['other-amount-input'].value) === 0){ 
              this.popUpMsgTitle = "Oops";         
              this.popUpMsgBody = "the tip amount cannot be " + this.buttonCurrSymbol + amountChosen;
              this.showPopUpOk = true;
              this.$refs['other-amount-input'].blur();
              stageVar = 3;
              return; // to make the user have to input something else
            } 
    
            stageVar = 4;
            // console.log("amountChosen: " + amountChosen);
            this.allFeesToPotentiallyAdd = +(this.getAllFeesToPotentiallyAdd(amountChosen));
            stageVar = 5;
            this.lastButtonTapped = buttonNum;
            stageVar = 6;
            if (this.multipleTipsTotalNumber !== undefined){
              stageVar = 7;
              this.lastAmountChosen = this.multipleTipsTotalNumber;
              // console.log("this.multipleTipsTotalNumber in doPayment:" + this.multipleTipsTotalNumber);
            } else {
              stageVar = 8;
              this.lastAmountChosen = amountChosen;
              // console.log("this.multipleTipsTotalNumber was undefined in doPayment");
            }
            
            // console.log("this.usingMultiTips no doubt sir is " + this.usingMultiTips);
    
           
            // console.log("here I am again...");
            // console.log("NO SHIT WE ARE USING MULTITIPS!!");
            stageVar = 9;
            let recipientNotByRefObject = JSON.parse(JSON.stringify(this.recipient));
            stageVar = 10;
            // console.log("this.recipientImageDataURL just PRE: " + this.recipientImageDataURL);
            if (this.recipientImageDataURL === undefined) {
              this.recipientImageDataURL =require('@/assets/tulogoapp.png');
              stageVar = 11;
            } else {
              if (this.recipientImageDataURL.indexOf("tulogoapp") === -1){
                this.atLeaseOneRecipientHasPhoto = true;
                stageVar = 12;
                // console.log("this.atLeaseOneRecipientHasPhoto is " + this.atLeaseOneRecipientHasPhoto);
              }
            }
    
            recipientNotByRefObject.recipientImageDataURL = this.recipientImageDataURL;
            stageVar = 13;
            let tipArrayItem = {
              allFeesToPotentiallyAdd: this.allFeesToPotentiallyAdd,
              amountChosen: amountChosen,
              recipient: recipientNotByRefObject,
            }
    
            this.tipsArray.push(tipArrayItem);
            // console.log("JUST ADDED TIPARRAY ITEM: " + JSON.stringify(tipArrayItem, null, 2));
            // console.log("FULL TIPARRAY IS NOW: " + JSON.stringify(this.tipsArray, null, 2));
    
            stageVar = 14;
    
             if (this.addingATip === true){
              this.addingATip = false;
              this.deployLogRocket(this.logRocketOrganisationSlug);
              this.sendAddingOrDeletingTipsEmail("added", this.tipsArray.length - 1);
            }
            stageVar = 15;
    
    
            this.buttonNumEventObject = {
              buttonNum: buttonNum,
              event: event,
              tipsArray: this.tipsArray, 
            } // need it for later
    
            stageVar = 16;
            this.doRefreshDisplayStrings();
            stageVar = 17;
            console.log("did doRefreshStrings and this.tipsArray.length is " + this.tipsArray.length);
    
            
            this.componentKey = !this.componentKey; //refresh the component list
            stageVar = 18;
            // this.$forceUpdate(); I can't remember what this was doing, but it smells dangerous whilst I still have ghosts in the machine
            stageVar = 19; 
            // console.log("this.allrecipientDisplaynamesAndAmounts::: " + this.allrecipientDisplaynamesAndAmounts);
    
            // console.log("tipsArray::: " + JSON.stringify(this.tipsArray, null, 2));
    
            console.log("this.usingMultiTips in doPayment: " + this.usingMultiTips);
              
              // console.log("buttonNumEventObject: " + JSON.stringify(this.buttonNumEventObject, null, 2));
            if (this.usingMultiTips === true && (this.linkedUsersArrayObject !== undefined && this.linkedUsersArrayObject.length > 0)) { // don't want to ask this question except where there are multiple potential recipients
              console.log("going multitips route");
              stageVar = 20;
              this.isReallyMultiTips = true;
              this.setMainPopupMsgVisibility = 'hidden';
              this.showPopupPreTitle = true;
              window.setTimeout(this.flashPreTitle, 200);   
              window.setTimeout(this.revealMainMessage, 1500);  
              this.popUpMsgPreTitle = "" + this.buttonCurrSymbol + amountChosen + " tip to " + this.recipient.displayname + " added";
              this.popUpMsgTitle = "Add a tip to someone else?";
              this.popUpMsgBody = "Before you pay, would you like to give another tip to someone else?"
              this.nextAction = "addAnotherTip";
              this.showTwoOptionsOnMainPopup = true;
              if(this.tipsArray.length > 0){
                this.showGetTipsHere = false;
              }
              this.showCancelAddTip = true;
              this.showPopUpOk = true;
              stageVar = 21;
            } else {
              console.log("going no multitips route");
              stageVar = 22;
              // we have now restructured so all tips have the same group transfer structure
              this.isReallyMultiTips = false;
              this.usingMultiTips = true;
              this.actuallyDoPayment(this.buttonNumEventObject.buttonNum, this.buttonNumEventObject.event, this.lastAmountChosen);
              stageVar = 23;
              // this.actuallyDoPayment(buttonNum, event, amountChosen); // remember with multiple tips this is wrong, it's just the last one chosen
            }
    
          } catch (e) {
            console.log("ERROR on doPayment: " + e.message);
            this.deployLogRocket(this.logRocketOrganisationSlug);
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = "ThankU couldn't complete your tip - please email this error message to tech@thanku.app: " + e.message + " TU001 " + stageVar;
            this.showPopUpOk = true;
            this.fromTipErrorPopup = true;
          }
        },
        flashPreTitle(){
          try {
            
            this.$refs.preTitle.style.animationTimingFunction = 'ease-in-out';
            this.$refs.preTitle.style.transitionDuration = '0.4s';
            this.$refs.preTitle.style.transform = 'scale(1.22)';
    
            window.setTimeout(this.flashBackPreTitle, 800);   
    
    
          } catch (e) {
            console.log("ERROR " + e.message);
          }
          
        },
        flashBackPreTitle(){
    
              this.$refs.preTitle.style.animationTimingFunction = 'ease-in-out';
              this.$refs.preTitle.style.transitionDuration = '0.3s';
              this.$refs.preTitle.style.transform = 'scale(1.0)';
    
        },
        revealMainMessage(){
          this.setMainPopupMsgVisibility = "visible";
        },
        async actuallyDoPayment(buttonNum, event, amountChosen){

          console.log("about to assign this.actuallyDoPaymentRepeatObject");
    
          this.actuallyDoPaymentRepeatObject = {
            buttonNum: buttonNum,
            event: event, 
            amountChosen: amountChosen,
          }

          console.log("did assign this.actuallyDoPaymentRepeatObject");
    
          // if this is coming through on multiTip it is already this.lastAmountChosen
          // console.log("actuallyDoPayment: " + amountChosen);
          
          let codeStage = 0;
          let debugValue = "debug";
    
          try {
    
            console.log("this.tipper.displayname is " + this.tipper.displayname);
    
            // if (this.testStripeUndefinedCounter < 10) { // just for testing
            //   console.log("testStripeUndefinedCounter: " + this.testStripeUndefinedCounter);
            //   this.stripe = undefined;
            //   this.testStripeUndefinedCounter += 1;
            // }
    
            // at this point the user has finished adding tips and wants to pay - if we don't indicate Stripe isn't loaded then nothing happens with the button tap and they then tap it again and hey presto the user gets phantom double tips becuase they have pressed the tip button twice, so we MUST stop the user doing anything at this point
    
            if (!this.tipper.displayname && (this.executeRebuildUserScreenAndAppState === false && this.showDisplayNameForReceiptInput === false && this.doShowDisplayNameForReceiptInputShowingPrePayment === false)) { // executeRebuildUserScreenAndAppState 
    
              codeStage = 1;
    
              console.log("setting this.doShowDisplayNameForReceiptInputShowingPrePayment");
              this.doShowDisplayNameForReceiptInputShowingPrePayment = true;
              this.doShowDisplayNameForReceiptInput(); // we want this here. If Stripe is not defined, maybe it can sort itself out by the time user has input name
    
              codeStage = 2;
            }
    
            if (this.useRyft === false) {
              if (this.stripe === undefined){
                this.doStripeIsUndefinedOperationsAndCallBackActuallyDoPaymentAfterwards(codeStage, debugValue, buttonNum, event, amountChosen);
                return;
              } else {
                this.doStripeRecoveredOperations();
              }
            } else {
              // what are we going to do if no Ryft?
            }
    
    
            console.log("this.tipper.paymentMethod::: " + this.tipper.paymentMethod); // this will ONLY have come through from parsedbquery IF (userData.get("stripePaymentSourceId") && userData.get("stripeCustomerId")){ are both true, because otherwise there is no point asserting that it is STCC when the user hasn't yet been given the option
    
            let result;
    
            // this.tipper.paymentMethod = "something else"; // for dev
    
            // MARK: start STRIPEFIX DEV
            this.doSaveThisFullDataSetToLocalStorage("actuallyDoPayment");
            // alert("HERE");
            // enmd STRIPEFIX DEV
    
            if (this.useRyft === true) {
    
              codeStage = 2.7;
              console.log("STARTING Ryft");
              // this.loggingError = true;
              shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " STARTING Ryft " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              let justMakingSureThisCalcsDontNeedTheStripeCall = this.makePaymentRequestJSON(amountChosen, "justMakingSureThisCalcsDontNeedTheStripeCall NOT STCC"); 
              // the issue here is that the only reason the NOT doing STCC below was working was because of this 'dummyish' this.makePaymentRequestJSON to tee up the token payment - that is where this.multipleTipsTotalNumber was getting calc'd - I thought about splitting it out separately for clarify but actually the fact that we don't use the return of the paymentRequest is
              amountChosen = this.makeSureAmountChosenIsAssignedToMultipleTipsTotalNumberAtTheRightMoment();
              codeStage = 2.8;
              console.log("about to this.doCardPayment ON RYFT amountChosen is: " + amountChosen);
              this.doCardPayment(buttonNum, amountChosen, undefined, event, "actuallyDoPayment STARTING Ryft");
              // likeiwise, if canMakePaymentRequest failed, we just won't show the AP GP options as we would have done on STCC
              console.log("finished Ryft's doCardPayment");
              
              codeStage = 2.9;
    
            } else if (this.tipper.paymentMethod === "STCC"){ // will only be if tipper has saved source   ...|| this.tipper.hasSavedPaymentSource)  {
    
              codeStage = 3;
              console.log("doing STCC on actuallyDoPayment");
              let paymentRequestWeWontUseUnlessUserChangesToAPorGPJSON = this.makePaymentRequestJSON(amountChosen, "justMakingSureThisCalcsDontNeedTheStripeCall with STCC"); 
              // the issue here is that the only reason the NOT doing STCC below was working was because of this 'dummyish' this.makePaymentRequestJSON to tee up the token payment - that is where this.multipleTipsTotalNumber was getting calc'd - I thought about splitting it out separately for clarify but actually the fact that we don't use the return of the paymentRequest is
              amountChosen = this.makeSureAmountChosenIsAssignedToMultipleTipsTotalNumberAtTheRightMoment(); // makePaymentRequestJSON will first assign this.MultipleTipsTotalNumber so this test and allocation of amountChosen has to come after
    
              // this.setCanDoAppleOrGooglePay(justMakingSureThisCalcsDontNeedTheStripeCall);
    
              
              this.doCardPayment(buttonNum, amountChosen, undefined, undefined, "actuallyDoPayment STCC");
    
              // we can do the following AFTER this.doCardPayment because all it is going to do is set the 'canPay' variables
              let paymentRequest = this.stripe.paymentRequest(paymentRequestWeWontUseUnlessUserChangesToAPorGPJSON);
              shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " about to perform canMakePayment on actuallyDoPayment, doing STCC " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              result = await paymentRequest.canMakePayment();
              this.setStripeCanPayVariables(result);
    
              // if it hangs, no problem...
            } else {    
              codeStage = 4;
              console.log("NOT doing STCC");
              debugValue = amountChosen;
              // at this point, amountChosen is coming in as you would expect
              codeStage = 4.05;
              let paymentRequestJSON = this.makePaymentRequestJSON(amountChosen, "NOT doing STCC");
              codeStage = 4.07;
              debugValue = "stripe object: " + this.stripe + "  paymentRequestJSON: " + JSON.stringify(paymentRequestJSON, null, 2);;
    
              if (this.stripe === undefined){
                console.log("stripe object IS STILL undefined WHAT THE HELL!");
                debugValue = "stripe object IS STILL undefined WHAT THE HELL!";
    
                // stripe = loadStripe.
                // the problem here is that this will be an async call and we will have to bubble asyncs right up the chain with unknown side effects so in the first instance attempting to ensure stripe is assigned earlier on
              } else {
                console.log("stripe WAS still defined");
              }
              let paymentRequest = this.stripe.paymentRequest(paymentRequestJSON);
              codeStage = 4.1;
              debugValue = JSON.stringify(paymentRequest, null, 2);
              amountChosen = this.makeSureAmountChosenIsAssignedToMultipleTipsTotalNumberAtTheRightMoment(); // makePaymentRequestJSON will first assign this.MultipleTipsTotalNumber so this test and allocation of amountChosen has to come after
              codeStage = 4.2;
              debugValue = amountChosen;
              // BUT the reason this has been working is that the payment request is effectively ignoring amountChosen as it converts is  
              console.log(new Date().toISOString() + " :: about to paymentRequest amountChosen is: " + amountChosen);
              // console.log("paymentRequest::: " + JSON.stringify(paymentRequest, null, 2));
              codeStage = 5;
    
              // MARK NEW NEW APPROACH
    
              try {
                
                this.attemptingPaymentSystem = true; 
    
                if (this.hasTriggeredStatusCheck === false){ // this is just re the payment indicator
                  this.hasTriggeredStatusCheck = true;
                  window.setTimeout(this.startProgressIndicator, 1000);
                }
                window.setTimeout(this.startCanMakePaymentResponseCheck, 1000); 
    
                shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " about to perform canMakePayment on actuallyDoPayment, NOT doing STCC " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
                
                // alert("HERE!"); // STRIPEFIX DEV
                // return; // STRIPEFIX DEV
    
                result = await paymentRequest.canMakePayment(); // in this instance we have to deal with if this hangs
    
                this.attemptingPaymentSystem = false; this.delayOnAttemptingPaymentSystemSoFar = 0; this.hasTriggeredStatusCheck = false; this.testStripeUndefinedCounter = 0;
    
                if (this.killedTransaction === true) {
                  // don't set it back to false that happens automatically in 8 seconds from when it is truned true
                  console.log(new Date().toISOString() + " :: killed transaction after payment system timeout on await paymentRequest.canMakePayment :::");
                  this.loggingError = true;
                  shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " :: killed transaction after payment system timeout on await paymentRequest.canMakePayment " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
                  return; // don't proceed as the transaction has already been cancelled
                }
                
                console.log(new Date().toISOString() + " :: RESULT on await paymentRequest.canMakePayment :::" + JSON.stringify(result, null, 2));
    
                shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " completed perform canMakePayment on actuallyDoPayment, NOT doing STCC, result is: " + JSON.stringify(result, null, 2) + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
                
    
                console.log("paymentRequest MAIN AWAIT result::: " + JSON.stringify(result, null, 2));
    
                this.setStripeCanPayVariables(result);
                
    
                console.log("Assigned variables ON MAIN AWAIT result");
    
                if (this.canPayByApplePay === true || this.canPayByGooglePay === true){ // this test could have been performed ahead of canMakePayment but I am not expert and I just want to check for edge cases
    
                  // this.recipient.displayname = JSON.stringify(result, null, 2);
                  if (result) { // at this point the result should never be null because otherwise neither this.payingByApplePay or this.payingByGooglePay would be true from the first pass
                    codeStage = 7;
                  
                    console.log("STARTING TOKEN ");
                    shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " STARTING TOKEN " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
                    if (this.canPayByApplePay===true) {this.payingByApplePay = true;} // at this point we know the direction of travel, unless the user later changes to a saved source, it will only be once they save that we should switch these off
                    if (this.canPayByGooglePay===true) {this.payingByGooglePay = true;} 
                    this.doCardPayment(buttonNum, amountChosen, paymentRequest, event, "actuallyDoPayment TOKEN"); // so we can confirm everything first
                    codeStage = 11;
                    // this.doTokenPayment(buttonNum, amountChosen, paymentRequest, event);
    
                  } else {
                    console.log("THIS SHOULD NEVER HAPPEN");
                    this.loggingError = true;
                    shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " THIS SHOULD NEVER HAPPEN this.canPayByApplePay === true || this.canPayByGooglePay === true are true but RESULT was null " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
                    codeStage = 11.1;
                    console.log("STARTING card never...");
                    let justMakingSureThisCalcsDontNeedTheStripeCall = this.makePaymentRequestJSON(amountChosen, "justMakingSureThisCalcsDontNeedTheStripeCall NOT STCC"); 
                    // the issue here is that the only reason the NOT doing STCC below was working was because of this 'dummyish' this.makePaymentRequestJSON to tee up the token payment - that is where this.multipleTipsTotalNumber was getting calc'd - I thought about splitting it out separately for clarify but actually the fact that we don't use the return of the paymentRequest is
                    codeStage = 11.2;
                    console.log("about to this.doCardPayment amountChosen is: " + amountChosen);
                    this.doCardPayment(buttonNum, amountChosen, undefined, event, "actuallyDoPayment THIS SHOULD NEVER HAPPEN");
    
                    // if canMakePaymentRequest failed, we just won't show the AP GP options as we would have done on STCC
                    this.suppressStripeApplePayAndGooglePayOptions();
                    codeStage = 11.3;
                    console.log("got to end of NEVER HAPPEN ok");
                  }
                } else {
                  codeStage = 12;
                  console.log("STARTING card, tipper had no savedSource AND neither AP nor GP were available");
                  this.loggingError = true;
                  shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " STARTING card, tipper had no savedSource AND neither AP nor GP were available " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
                  let justMakingSureThisCalcsDontNeedTheStripeCall = this.makePaymentRequestJSON(amountChosen, "justMakingSureThisCalcsDontNeedTheStripeCall NOT STCC"); 
                  // the issue here is that the only reason the NOT doing STCC below was working was because of this 'dummyish' this.makePaymentRequestJSON to tee up the token payment - that is where this.multipleTipsTotalNumber was getting calc'd - I thought about splitting it out separately for clarify but actually the fact that we don't use the return of the paymentRequest is
                  codeStage = 13;
                  console.log("about to this.doCardPayment amountChosen is: " + amountChosen);
                  this.doCardPayment(buttonNum, amountChosen, undefined, event, "actuallyDoPayment STARTING card, tipper had no savedSource AND neither AP nor GP were available");
                  // likeiwise, if canMakePaymentRequest failed, we just won't show the AP GP options as we would have done on STCC
                  this.suppressStripeApplePayAndGooglePayOptions();
                  codeStage = 14;
                }
    
    
              } catch (resultErr) {
                console.log("Error in actuallyDoPayment at await paymentRequest.canMakePayment: " + resultErr.message);
        
                this.loggingError = true;
    
                shared.saveToUserPath(this.devEnv, "paymentRequest.canMakePayment FAILED! Tipper: " + this.tipper.objectId + " ERROR on actuallyDoPayment at paymentRequest.canMakePayment: error message is: " + resultErr + " codeStage is " + codeStage +  " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
                
    
                const paramsFailedCanMakePayment = {
                toEmail: "appalert@thanku.app",
                subject: "paymentRequest.canMakePayment FAILED!",
                body: "Error is: " + resultErr + "    Tipper id is: " + this.tipper.objectId + "  " + new Date().toISOString(),
                };
    
                Parse.Cloud.run("sendEmail", paramsFailedCanMakePayment);  
                throw resultErr; // rethrow up to the next try catch
              }
    
              // MARK END NEW APPROACH
    
            }
          } catch (e) {
    
            console.log("ERROR on actuallyDoPayment: " + e.message);
            this.deployLogRocket(this.logRocketOrganisationSlug);
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = "ThankU couldn't complete your tip - please email this error message to tech@thanku.app: " + e.message + " TU002";
            this.showPopUpOk = true;
    
            this.showPaymentScreen = true; // because it hadn't even been shown yet
            this.doShowCancelled(); // this needs to be AFTER logging cancel because it blanks values which need to be logged
            
            this.loggingError = true;
    
            shared.saveToUserPath(this.devEnv, "PAYMENT ERROR Tipper: " + this.tipper.objectId + " ERROR on actuallyDoPayment: error message is: " + e.message + " codeStage is " + codeStage +  " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "TU002 ALERT HAS OCCURED! ",
              body: "Tipper id is: " + this.tipper.objectId + "   Error is: " + e.message + "   codeStage is: " + codeStage + " debug value is: " + debugValue,
            };
    
            Parse.Cloud.run("sendEmail", params);  
    
          }
    
        },
    
        doSaveThisFullDataSetToLocalStorage(errorCodeIfAny){
          let fullThisDataSet;
          try {
            fullThisDataSet = this.serializeTheThisDataSet(errorCodeIfAny);
            window.localStorage.setItem("fullThisDataSet", fullThisDataSet);
            console.log("doSaveThisFullDataSetToLocalStorage: " + errorCodeIfAny);
            // let displayLabel = this.amountToPayDisplayLabel !== undefined ? this.amountToPayDisplayLabel : "NO LABEL";
            // console.log("JUST SAVED fullThisDataSet: amountToPayDisplayLabel was " + this.amountToPayDisplayLabel + " and fullThisDataSet was " + fullThisDataSet);
            // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "JUST SAVED fullThisDataSet", body: "errorCodeIfAny: " + errorCodeIfAny + "<br/>" + fullThisDataSet, });  
          } catch (e) {
            Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "doSaveThisFullDataSetToLocalStorage FAILED: " + e.message, body: "errorCodeIfAny: " + errorCodeIfAny + "<br/>" + fullThisDataSet, });  
          }
        },
        doStripeIsUndefinedOperationsAndCallBackActuallyDoPaymentAfterwards(codeStage, debugValue, buttonNum, event, amountChosen){
    
            console.log("this.hasTriggeredStatusCheck starting doStripeIsUndefinedOperationsAndCallBackActuallyDoPaymentAfterwards: " + this.hasTriggeredStatusCheck);
            this.stripeLoadRetries += 1;
            if (this.hasTriggeredStatusCheck === false){
              this.hasTriggeredStatusCheck = true;
              this.attemptingPaymentSystem = true; 
              window.setTimeout(this.startProgressIndicator, 1000); // this is only going to trigger once and then startProgressIndicator self calls until this.attemptingPaymentSystem is set to false
              // window.setTimeout(this.startCanMakePaymentResponseCheck, 1000); 
            }
    
    
            this.deployLogRocket(this.logRocketOrganisationSlug);
            this.stripeWasUndefined = true;
    
            console.log("stripe object was undefined");
    
            // the idea is that is the stripe object mysteriously is undefined, we try again to get the object set before we do anything with actuallyDoPayment and its parameters, danger is this could be an endless loop?
            debugValue = "stripe object was undefined let's have another go...";
            this.loggingError = true;
            shared.saveToUserPath(this.devEnv, "STRIPE NOT INITIALISED WTIH Tipper: " + this.tipper.objectId + " codeStage is " + codeStage +  " setting time out to tryStripeAgain() :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            if (this.stripeLoadRetries === 7){ // actually unlikely ever to get here because it goes into this.tryStripeAgain loop
              const params = {
                toEmail: "appalert@thanku.app",
                subject: "STRIPE OBJECT UNDEFINED! ",
                body: "Tipper id is: " + this.tipper.objectId + "   codeStage is: " + codeStage + " debug value is: " + debugValue + " setting time out to tryStripeAgain(): " + new Date().toISOString() + "<br/>this.stripeLoadRetries so far: " + this.stripeLoadRetries,
              };
      
              Parse.Cloud.run("sendEmail", params);  
            }

    
            window.setTimeout( async () =>  {
              if (this.dontTryStripeAgainInThisSession === false){ // this is because loadStripeObject has not succeeded in loading Stripe at all after 10 seconds and has told the user to rescan QR code
                await this.tryStripeAgain('actuallyDoPayment');
                this.actuallyDoPayment(buttonNum, event, amountChosen); // it's not an endless loop because this will fire only AFTEr the return statement below
              }
            } , 500);
        },
        doStripeRecoveredOperations(){
          
          if (this.stripeWasUndefined === true){
            console.log("BACK IN THE GAME!");
            console.log("OK we recovered to HIDE ME!");
            this.showPopUpOk = false;
            window.setTimeout(this.doRevealOkButton, 500); 
            // this.hideOKButton = false;
            
            this.attemptingPaymentSystem = false; this.delayOnAttemptingPaymentSystemSoFar = 0; this.hasTriggeredStatusCheck = false; this.testStripeUndefinedCounter = 0;// if we got here, at least Stripe is loaded...
            console.log("this.hasTriggeredStatusCheck having recovered: " + this.hasTriggeredStatusCheck);
            this.stripeWasUndefined = false;
          
            // const params = {
            // toEmail: "appalert@thanku.app",
            // subject: "Stripe Object RECOVERED! this.stripeLoadRetries: " + this.stripeLoadRetries,
            // body: "Tipper id is: " + this.tipper.objectId + "  "  + new Date().toISOString(),
            // };
    
            // Parse.Cloud.run("sendEmail", params);  
          }
          
          console.log("Stripe object WAS properly defined");
        },
        doRevealOkButton(){
          // just to ensure the popup has disappeared
          this.hideOKButton = false;
          this.showProgress = false;
          this.progressIndicator = ".";
        },
        suppressStripeApplePayAndGooglePayOptions(){
          this.payingByApplePay = false; 
          this.canPayByApplePay = false; 
          this.payingByGooglePay = false; 
          this.canPayByGooglePay = false;
        },
    
        setStripeCanPayVariables(result){
    
          console.log("setStripeCanPayVariables RESULT::::: " + JSON.stringify(result, null, 2));
    
          if (result) {
            
            if (result.applePay) {
              // this.payingByApplePay = true; 
              
              this.canPayByApplePay = true; 
              console.log("SETTING this.canPayByApplePay: " + this.canPayByApplePay + "");
              this.showBlankInstead = false;
              // this.payingByGooglePay = false; 
              this.canPayByGooglePay = false;
            } else if (result.googlePay){
              // this.payingByApplePay = false; 
              this.canPayByApplePay = false; 
              // this.payingByGooglePay = true; 
              this.canPayByGooglePay = true;
              this.showBlankInstead = false;
            } else {
              // if there was another wallet, we could have used it - LINK might be avaiable but we chose not to implement
              // this.payingByApplePay = false; 
              this.canPayByApplePay = false; 
              // this.payingByGooglePay = false; 
              this.canPayByGooglePay = false;
            }
          } else {
            // this.payingByApplePay = false; 
            this.canPayByApplePay = false; 
            // this.payingByGooglePay = false; 
            this.canPayByGooglePay = false;
          } 
        },
        
        startProgressIndicator(){
          if (this.showDisplayNameForReceiptInput === true ) {
            window.setTimeout(this.startProgressIndicator, 500); 
          } else if (this.attemptingPaymentSystem === true) {
            if (this.progressIndicator === "...") {
              this.progressIndicator = ".";
            } else {
              this.progressIndicator += ".";
            }
            if (this.showProgress === false) {
              // no point setting more than once
              console.log("this.showProgress: " + this.showProgress);
              this.showProgress = true;
              this.hideOKButton = true;
              this.popUpMsgTitle = "";
              this.popUpMsgBody = "preparing payment";
              this.showPopUpOk = true;
            }
            window.setTimeout(this.startProgressIndicator, 500); 
          } else {
            // this.attemptingPaymentSystem is no longer true
          }   
        },
        async startCanMakePaymentResponseCheck(){
          // console.log("this.attemptingPaymentSystem is: " + this.attemptingPaymentSystem);
          if (this.showDisplayNameForReceiptInput === true ) {
            // no point showing preparing payment yet, apart form anything else it comes on top of the displayname popup
              this.delayOnAttemptingPaymentSystemSoFar = 0; // no point in even starting the clock, some users take a while
              window.setTimeout(this.startCanMakePaymentResponseCheck, 500); 
          } else if (this.attemptingPaymentSystem === true) { 
            
            console.log("this.attemptingPaymentSystem TRIGGERED TRUE");
           
            if(this.delayOnAttemptingPaymentSystemSoFar < 3000){ 
              if (this.delayOnAttemptingPaymentSystemSoFar > 1500) { // want to make sure it is loaded in time but not necessarily every time
                this.deployLogRocket(this.logRocketOrganisationSlug);
                console.log("Triggered logrocket in startCanMakePaymentResponseCheck maybe multiple times but doesn't matter as will return if loaded");
              }
              this.delayOnAttemptingPaymentSystemSoFar += 500;
              window.setTimeout(this.startCanMakePaymentResponseCheck, 500);  // this is just to see if this.attemptingPaymentSystem has been set to false in the meantime before we try actuallyDoPaymentAgain
            // MARK start STRIPEFIX DEV - this section to come out
            // } else if(this.delayOnAttemptingPaymentSystemSoFar < 15000){ 
            //   // try again
            //   console.log("triggering actuallyDoPaymentRepeatObject after < 15000");
            //   this.delayOnAttemptingPaymentSystemSoFar += 1000; // we know that when we do actuallyDoPayment again it won't hit this function for 1,000 so that much will have elapsed
              
            //   this.actuallyDoPayment(this.actuallyDoPaymentRepeatObject.buttonNum, this.actuallyDoPaymentRepeatObject.event, this.actuallyDoPaymentRepeatObject.amountChosen);
            // end STRIPE FIX DEV - this section above to come out
            } else {
              // effectively throw the error
              console.log("ERROR on actuallyDoPayment then startCanMakePaymentResponseCheck: error message should already have been logged, otherwise canMakePayment never returned a result");
            
              this.attemptingPaymentSystem = false; // this should stop the progress indicator
    
              // MARK start STRIPEFIX DEV - this section to come in
    
            
              this.delayOnAttemptingPaymentSystemSoFar = 0;
    
              this.deployLogRocket(this.logRocketOrganisationSlug);
              
              this.loggingError = true;
    
              this.doSaveThisFullDataSetToLocalStorage("TU003");
    
              let fullThisDataSet = "";
    
              if (window.localStorage.getItem("fullThisDataSet") !== null) {
                 fullThisDataSet = window.localStorage.getItem("fullThisDataSet");
              }
    
              shared.saveToUserPath(this.devEnv, "PAYMENT ERROR TU003 Tipper FIXED?: " + this.tipper.objectId + " RESTARTING WITH ALL The SAME DETAILS - did the tipper succeed this time? <br/><br/>" + fullThisDataSet + "<br/><br/>OLD ERROR MESSAGE: ERROR on actuallyDoPayment then startCanMakePaymentResponseCheck: error message should already have been logged, otherwise canMakePayment never returned a result " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              await Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "PAYMENT ERROR TU003 Tipper FIXED?: " + this.tipper.objectId, body: "RESTARTING WITH ALL The SAME DETAILS - did the tipper succeed this time? <br/><br/>" + fullThisDataSet + "<br/><br/> OLD ERROR MESSAGE: ERROR on actuallyDoPayment then startCanMakePaymentResponseCheck: error message should already have been logged, otherwise canMakePayment never returned a result", });  
    
    
              window.location.reload(true);
    
              return;
    
              // end STRIPEFIX DEV - this section above to come in
    
              this.killedTransaction = true;
              window.setTimeout(this.setKilledTransactionFalse, 4000); // random 4 seconds it sets itself back - see email of 22/10/24 around 20:00; it shoudl be reset anyway if the user rescans a QR code, but just in case they navigate to home and tip without scanning and doesn't reset or something like that, then this is why...
    
              this.delayOnAttemptingPaymentSystemSoFar = 0;
    
              this.deployLogRocket(this.logRocketOrganisationSlug);
              this.showProgress = false;
              this.progressIndicator = ".";
              this.hideOKButton = false;
              this.popUpMsgTitle = "Sorry";
              this.popUpMsgBody = "ThankU couldn't complete your tip - there was a problem starting the payment system, please try again [TU003]";
              this.showPopUpOk = true;
    
              this.showPaymentScreen = true; // because it hadn't even been shown yet
              this.doShowCancelled(); // this needs to be AFTER logging cancel because it blanks values which need to be logged
              
              this.loggingError = true;
    
              shared.saveToUserPath(this.devEnv, "PAYMENT ERROR Tipper: " + this.tipper.objectId + " ERROR on actuallyDoPayment then startCanMakePaymentResponseCheck: error message should already have been logged, otherwise canMakePayment never returned a result " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              const params = {
                toEmail: "appalert@thanku.app",
                subject: "ERROR TU003 on actuallyDoPayment then startCanMakePaymentResponseCheck",
                body: "Tipper id is: " + this.tipper.objectId + "   ERROR on actuallyDoPayment then startCanMakePaymentResponseCheck: error message should already have been logged, otherwise canMakePayment never returned a result",
              };
    
              Parse.Cloud.run("sendEmail", params);  
            }
          } else {
            // hide message
            console.log("OK HIDE ME");
            this.showPopUpOk = false;
            window.setTimeout(this.doRevealOkButton, 500);
    
    
          }
        },
        serializeTheThisDataSet(errorCodeIfAny, onlyChecking) {
          const serializedData = {};
          let currentKey = "n/a";
          try {
          
            // let allKeysStr = "";
      
            // Serialize data properties
            Object.entries(this.$data).forEach(([key, value]) => {
              // allKeysStr += "<br/>" + key ;
              currentKey = key;
              if (key === "stripe") {
                return; // Skip serialization of 'stripe'
              }
              if (key === "recipientImageDataURL" && onlyChecking === undefined) {
                // miss it out and refill later because we have it in tipsArray which is the master
              } else if (key === "linkedUsersArrayObject" || key === "linkedUsersArrayObjectFiltered") {
                serializedData[key] = value.map(user => {
                  const { imageData, ...otherProps } = user;
                  // allKeysStr += "&nbsp;&nbsp;&nbsp;otherProps: " + JSON.stringify(otherProps) ;
                  return this.isSerializable(otherProps) ? otherProps : {};
                });
              } else if (key === "buttonNumEventObject" && onlyChecking === undefined) {
                // console.log("buttonNumEventObject: " + JSON.stringify(value, null, 2));
                // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "buttonNumEventObject" , body: "Value: " + value  + " currentKey: " + currentKey, }); 
                
                // let subKeys;
                // if (this.buttonNumEventObject && typeof this.buttonNumEventObject === 'object') {
                //     subKeys = Object.keys(this.buttonNumEventObject).join(', ');
                    
                // }

                // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "buttonNumEventObject" , body: "Subkeys: " + subKeys  + " currentKey: " + currentKey, }); 
                // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "buttonNumEventObject STRINGED" , body: "Subkeys: " + subKeys  + " currentKey: " + currentKey, }); 

                if (value && typeof value === 'object' && !Array.isArray(value)) {

                  // Only include buttonNum and event
                  const { tipsArray, ...otherProps } = value;
                  serializedData[key] = this.isSerializable(otherProps) ? otherProps : {};
                  console.log("serializedData for buttonNumEventObject: " + JSON.stringify(serializedData[key], null, 2));
                } else {
                  console.log("sorry fuckwit, not that...");
                }
              } else if (this.isSerializable(value)) {
                // allKeysStr += "&nbsp;&nbsp;&nbsp;" + key ;
                serializedData[key] = value;
              }
            });
      
            // Serialize prop properties
            Object.entries(this.$props).forEach(([key, value]) => {
              if (this.isSerializable(value)) {
                serializedData[key] = value;
              }
            });
      
            serializedData.executeRebuildUserScreenAndAppState = true;
            serializedData.rebuildStateValidityMilliseconds = new Date().getTime();
      
            if (errorCodeIfAny !== undefined) {
              serializedData.errorCodeIfAny = errorCodeIfAny;
            }
            
            // Stringify the clean data object
      
      
            if (onlyChecking !== undefined && onlyChecking === true){
              // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ALL KEYS on ONLYCHECKING" , body: JSON.stringify(serializedData)});
            }
            
            
            let returnObject = "";
      
            try {
              returnObject = JSON.stringify(serializedData);
            } catch (e2) {
              console.log("ERROR in serializeTheThisDataSet:" + e2.message);
              Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ERROR in serializeTheThisDataSet: " + e2.message, body: "", });  
            }
      
            return returnObject;
              
          } catch (e) {
            console.log("serializeTheThisDataSet FAILED with error: " + e.message + " currentKey: " + currentKey);
            Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ERROR in serializeTheThisDataSet: " + e.message, body: "RETURN serializeTheThisDataSet FAILED with error: " + e.message  + " currentKey: " + currentKey, });  
            return "RETURN serializeTheThisDataSet FAILED with error: " + e.message + " currentKey: " + currentKey;
          }
        },
        isSerializable(value) {
          // Serialize all primitives directly
          if (value === null || typeof value !== 'object') {
            return true; // Includes boolean, string, number, and null
          }
          // Serialize Arrays and plain Objects, avoid special objects or those causing circular references
          if (typeof value === 'object') {
            try {
              JSON.stringify(value);
              return true; // Object can be serialized
            } catch (error) {
              return false; // Serialization error occurred, likely due to circular references
            }
          }
          // Exclude functions and symbols explicitly
          return typeof value !== 'function' && typeof value !== 'symbol';
        },
        setKilledTransactionFalse(){
          this.killedTransaction = false;
        },
        doRefreshDisplayStrings(){
            if (this.tipsArray.length > 1){
              this.tipsPluralStr = "s";
            } else {
              this.tipsPluralStr = "";
            }
            this.allrecipientDisplaynamesAndAmounts = ""; // this is now actually only being used in the Apple / Google Pay details
            this.allrecipientDisplaynames = "";
    
    
            for (let i = 0; i < this.tipsArray.length; i++){
              console.log("[i]  " + i + "  this.tipsArray[i].recipient.displayname:: " + this.tipsArray[i].recipient.displayname + " this.tipsArray.length: " + this.tipsArray.length);
              if ((i === this.tipsArray.length - 1) && (this.tipsArray.length > 1)){
                this.allrecipientDisplaynamesAndAmounts += "& "  + this.tipsArray[i].recipient.displayname + " (" + this.tipsArray[i].recipient.currencySymbol + this.tipsArray[i].amountChosen + ")" ;
                this.allrecipientDisplaynames += "& "  + this.tipsArray[i].recipient.displayname;
              } else if (i === this.tipsArray.length - 2){
                this.allrecipientDisplaynamesAndAmounts += this.tipsArray[i].recipient.displayname + " (" + this.tipsArray[i].recipient.currencySymbol + this.tipsArray[i].amountChosen + ") ";
                this.allrecipientDisplaynames += this.tipsArray[i].recipient.displayname + " ";
              } else if (this.tipsArray.length > 1){
                this.allrecipientDisplaynamesAndAmounts += this.tipsArray[i].recipient.displayname + " (" + this.tipsArray[i].recipient.currencySymbol + this.tipsArray[i].amountChosen + ") "  + ", ";
                this.allrecipientDisplaynames += this.tipsArray[i].recipient.displayname + ", ";
              } else {
                this.allrecipientDisplaynamesAndAmounts += this.tipsArray[i].recipient.displayname;
                this.allrecipientDisplaynames += this.tipsArray[i].recipient.displayname;
              }
            }
    
        },
    
        makeSureAmountChosenIsAssignedToMultipleTipsTotalNumberAtTheRightMoment() {
        
          if (this.multipleTipsTotalNumber!==undefined) {
            this.lastAmountChosen=this.multipleTipsTotalNumber;
            console.log("this.multipleTipsTotalNumber in actuallyDoPayment:" + this.multipleTipsTotalNumber);
          } else {
            // do nothing
            console.log("this.multipleTipsTotalNumber was undefined in actuallyDoPayment");
          }
    
          return this.lastAmountChosen; // this is all a bit of a bodge - amountChosen and this.lastAmountChosen was designed for a single tip process so these days it's a misnomer
              
        },
    
        setToTokenTrans(){
    
          this.wasDoingTokenInsteadMethod = true;
          this.processTokenPayment = true; // so this is only going to stay true unless the user cancels out of completing the token, so they can go back to savedSource as necessary
          console.log("just set this.processTokenPayment to true;")
        },
    
        determineCompletionMethod(evt){
    
          console.log("initiating determineCompletionMethod");
          
          window.localStorage.removeItem("fullThisDataSet"); // at this stage if it fails they must start again
          console.log("REMOVED fullThisDataSet at determineCompletionMethod");
    
          // DON'T RESINSTATE ANYTHING LIKe THE CONSOLE.LOG BELOW - ON 11TH NOV RANDOMLY STARTED TRIGGERING THE FOLLOWING EEEOR EVIDENTLY BECAUSE WE WERE STRINGIFYING THE EVT.CURRENT TARGET WHICH HAD ALWAYS WORKED BEFORE: Failed to read a named property 'toJSON' from 'Window': Blocked a frame with origin "https://www.thanku.app" from accessing a cross-origin frame
          // console.log("and HERE is our event on determineCompletionMethodDelayed: " + evt + " with current target " + JSON.stringify(evt.currentTarget, null, 2));
          const confirmButton = this.$refs.savedSourceOrAPorGPConfirmPayment;
          confirmButton.event = evt;      
          // DON'T RESINSTATE ANYTHING LIKe THE CONSOLE.LOG BELOW, see abOVE
          // console.log("HERE is our BUTTON event on JUST BEFORE doTokenPayment: " + confirmButton.event + " with current target " + JSON.stringify(confirmButton.event.currentTarget, null, 2));
    
          if (this.processTokenPayment === true){
            console.log("doing AP / GP");
    
            this.doTokenPayment(evt);
    
          } else {
            console.log("doing STCC at determineCompletionMethod");
            this.savedSourceConfirmPayment();
          }
        },
    
        blankButton(){
          const confirmButton = this.$refs.savedSourceOrAPorGPConfirmPayment;
          confirmButton.buttonNum = undefined;
          confirmButton.amountChosen = undefined;
          confirmButton.paymentRequest = undefined;
          confirmButton.event = undefined;
    
        },
    
    
        doTokenPayment(evt){
    
          this.consoleLogConfirmButton("doTokenPayment PRE ");
    
          console.log("evt is: " + evt + " AND evt.currentTarget::: " + JSON.stringify(evt.currentTarget, null, 2)); // the event will nowadays have disappeared probably, but it doesn't seem to matter
    
          const confirmButton = this.$refs.savedSourceOrAPorGPConfirmPayment;
          const buttonNum = confirmButton.buttonNum;
          const amountChosen =  confirmButton.amountChosen ;
          const paymentRequest = confirmButton.paymentRequest;
    
          this.consoleLogConfirmButton("doTokenPayment POST ");
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " being shown AP or GP completion screen " + amountChosen + " " + new Date().toISOString(), this.globalPushForwardInterval
          );
    
    
          paymentRequest.show();
    
          paymentRequest.on('token', async ev =>  {
              // /console.log(JSON.stringify(ev, null, 2));
    
              this.buttonPressNum += 1;
              
              if (ev) {
                // Must call this to finish the payment request
                ev.complete('success');
                
                const params = this.getPaymentRequestParams(ev, undefined, buttonNum, +(amountChosen * 100).toFixed(0), undefined, undefined, "doTokenPayment");    
    
                console.log("params before doing token payment are::: " + JSON.stringify(params, null, 2));
                await this.runCloudInitiatePayment(params, "ok");
              } else {
                const params = this.getPaymentRequestParams(undefined, undefined, undefined, +(amountChosen * 100).toFixed(0), "log-cancelled", "cancel button payment post token provider screen", "doTokenPayment - Failed");  
                // throw "sorry, your payment could not be set up"
                await this.runCloudInitiatePayment(params, "Your payment could not be set up - please email us at tech@thanku.app" );
                
              }
          });
    
          paymentRequest.on('cancel', async ev =>  {
            // /console.log("paymentRequest " + buttonNum + " cancelled");
            // /console.log(JSON.stringify(ev, null, 2));
            const params = this.getPaymentRequestParams(undefined, undefined, undefined, +(amountChosen * 100).toFixed(0), "token-log-cancelled", "Tipper cancelled during AP or GP", "doTokenPayment-cancelled");  
            await this.runCloudInitiatePayment(params, "Tipper cancelled during AP or GP");
    
            // TODO need to run through this, at moment it is a) creating multiple cancelled payment objects, with a new reason each time and b) it snips the user journey log
    
          });
    
        },
        doCardPayment(buttonNum, amountChosen, paymentRequest, event, origin){
    
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " started doCardPayment with amount: " + amountChosen + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          console.log("Tipper " + this.tipper.objectId + " started doCardPayment with amount: " + amountChosen + " from origin " + origin);
    
          // /console.log("buttonnum:::" + buttonNum);
    
          var venueStr = ""; if (this.venue.name) { venueStr = " at " + this.venue.name; };
    
          this.cardPaymentRecipientDisplayLabel = this.recipient.displayname + venueStr;
    
          if (this.multipleTipsTotalNumber !== undefined){
            // console.log("this.multipleTipsTotalNumber in doCardPayment: " + this.multipleTipsTotalNumber);
            amountChosen = this.multipleTipsTotalNumber;
          } else {
            // console.log("this.multipleTipsTotalNumber was UNDEFINED in doCardPayment");
          }
    
          const amountIncludingFees = +(amountChosen * (1 + this.thankUFeeToAdd/100)); // in the new system thankuFeeToAdd will always be zero so this kind of becomes redundant
    
          this.amountToPayDisplayLabel = amountIncludingFees.toFixed(2);
    
          // console.log(" this.amountToPayDisplayLabel 2: " +  this.amountToPayDisplayLabel);
    
          // the following needs to happen after amountToPayDisplayLabel above - amountIncludingFees needs to be set for all situation to deal with tipperFX below
          if (this.willDefaultToFeesTicked === true) {
              // console.log("did willDefaultToFeesTicked");
              this.addFeesCheckboxValue = true;
              this.doUpdateAddFeesOrNot();
          }
    
          this.feeToPayDisplayLabel = +(amountChosen * (this.thankUFeeToAdd/100)).toFixed(2);
    
          this.addFeesQuestionLabel = this.allFeesToPotentiallyAdd.toFixed(2);
    
          if (this.tipper.tipperFX !== 1) {
            this.amountChosenCurrEqDisplayLabel = "≈ " + this.tipper.tipperCurrencySymbol + (amountIncludingFees / this.tipper.tipperFX).toFixed(2);
            
          }
    
          const confirmButton = this.$refs.savedSourceOrAPorGPConfirmPayment; // we may use this if user switches from savedSource to AP/GP so let's populate as much as we can here with just paymentRequest to go
          confirmButton.buttonNum = buttonNum;
          confirmButton.amountChosen = amountChosen;
          confirmButton.event = event;
    
          // this.tipper.hasSavedPaymentSource = false; //TODO get rid this is just for testing

          // console.log("paymentRequest at this point is: " + paymentRequest);
     
          if (paymentRequest) {
    
            console.log("doing token");
            this.doUpdateAddFeesOrNot(); // in case the add fee box was ticked from prior transaction
            this.showConfirmation = true;
            this.processTokenPayment = true;
    
    
            confirmButton.paymentRequest = paymentRequest;
            // altConfirmButton.paymentRequest = paymentRequest;
    
            console.log("just made my button... amountChosen is: " + confirmButton.amountChosen);
    
            //BE AWARE THE ABOVE ALL HAPPENS BEFORE YOU START ADDING DELETING TIPS SO BUTTON THEN HAS TO BE UPDATED
    
          } else {
    
            console.log("NOT doing token");
            // console.log("this.tipper.hasSavedPaymentSource:: " + this.tipper.hasSavedPaymentSource);
            
            if (this.useRyft === true) {
    
              // this is a KEY Ryft moment - we should have established already on a test Ryft call through our backend whether AP or GP is available. Whilst in my most instances you could check now and wait 300ms or whatever, we know there is a chance no response will be returned so to be safe we need to have checked beforehand - remember Ryft jettisons unconfirmed payment sessions after 24 hours or so
    
              this.doRyftPaymentPrepOperations();

            } else if (this.tipper.hasSavedPaymentSource) {
    
                // console.log("this.tipper: " + JSON.stringify(this.tipper, null, 2));
                this.payingByApplePay = false; // I think these are being set to make sure there is blank space whilst asynrchonously canMakePAyment is call and returns a result showing whether either are available
                this.payingByGooglePay = false;
                console.log("but user has saved source so about to show payment confirmation screen");
                this.doUpdateAddFeesOrNot(); // in case the add fee box was ticked from prior transaction
                this.showConfirmation = true;
                this.componentKey = !this.componentKey; //refresh the component list, don't think this will work - the issue is that for some reason we are reaching this point and this.showConfirmation doesn't then display, but I don't think componentKey will change that, but just trying...
                console.log("confirmation screen should now be showing");
    
            } else {
              this.detectShowIfUKPlatformCardCountryUnknown(true);
              this.showCardInputForPayment(buttonNum);
            }
          }
    
          this.doSaveThisFullDataSetToLocalStorage("End of doCardPayment"); // we need to do this here to capture the state of the showConfirmation screen if that's where we got to
    
        },
        getNodeVisibleChildrenTotalHeight(existingDiv){
          let totalHeight = 0;
          // Iterate over each child element of existingDiv
          Array.from(existingDiv.children).forEach(child => {
              // Check if the child is visible
              if (window.getComputedStyle(child).display !== 'none' && window.getComputedStyle(child).visibility !== 'hidden') {
                  totalHeight += child.offsetHeight; // Add child's height to totalHeight
                  console.log("extrasAndNotesOnConfirm totalHeight is now " + totalHeight);
              }
          });
          return totalHeight;
        },
        getExtraHeightToAddReExistingExtrasAndNotesFromStripeOnConfirm(){
              let existingDiv = this.$refs.extrasAndNotesOnConfirm;
              let divToSet = this.$refs.dialogMessageScreen;

              if (existingDiv && divToSet) {
                  
                  let totalHeight = this.getNodeVisibleChildrenTotalHeight(existingDiv);

                  // Subtract 42px from the total height
                  let heightToAdd = totalHeight - 42;
                  console.log("Total height of visible children: " + totalHeight + "px");
                  console.log("Adjusted height (totalHeight - 42px): " + heightToAdd + "px");

                  // Set the new height to another div
                  divToSet.style.height = heightToAdd + 'px';

                  this.$nextTick(() => {
                    // Log the new height
                    let newHeight = divToSet.offsetHeight;
                    console.log("New extrasAndNotesOnConfirm height after setting to 140px: " + newHeight + "px");
                  });

                  return heightToAdd;

                  // console.log("New extrasAndNotesOnConfirmheight set to divToSet: " + divToSet.style.height);
              } else {
                  console.log('Elements not found! Please check your $refs or component rendering.');
              }

        },

        getDialogMessageScreenDivHeight () {
          
          // this is all quite a palaver - I could probably have just added 140px to this element height and would have been good enough, but there are lots of variations of what is showing so better to calculate
          let dialogMessageScreen = this.$refs.dialogMessageScreen;

          if (dialogMessageScreen) {
            // Log the current height
            let originalHeight = dialogMessageScreen.offsetHeight;
            console.log("Original dialogMessageScreen height: " + originalHeight + "px");
            return originalHeight;
          } else {
            console.log('dialogMessageScreen Element not found! Make sure you have correctly set the ref attribute.');
            return 0;
          }
        },
        setBlankSpacerHeight(blankspacer, referenceNodeForHeight, description, extraHeight){
          console.log("starting setBlankSpacerHeight");
          try {
            // Check if the parentNode contains the childNode
            if (blankspacer && referenceNodeForHeight) {
              blankspacer.style.height = (this.getNodeVisibleChildrenTotalHeight(referenceNodeForHeight) + extraHeight) + 'px';
              // We need to ensure the DOM updates after setting the height, hence another $nextTick
              this.$nextTick(() => {
                // Log the new height
                console.log("set blankspacer height to: " + (blankspacer.offsetHeight + extraHeight) + "px");
                
              });
              
            } else {
              console.log("FAILED : " + description + " : blankspacer is " + blankspacer + " and referenceNodeForHeight is " + referenceNodeForHeight);
            } 
            console.log("finished " + description);
          } catch (e) {
            console.log("ERROR in setBlankSpacerHeight on: "+ description + ":  " + e.message);
            throw e;
          }
        },
        setNewDialogMessageScreenDivHeight (oldHeight) {
          
          let requiredIncreaseInHeight = this.getExtraHeightToAddReExistingExtrasAndNotesFromStripeOnConfirm();
          let dialogMessageScreen = this.$refs.dialogMessageScreen;

          if (dialogMessageScreen) {
            
            dialogMessageScreen.style.height = (oldHeight + requiredIncreaseInHeight) + 'px';

            // We need to ensure the DOM updates after setting the height, hence another $nextTick
            this.$nextTick(() => {
              // Log the new height
              let newHeight = dialogMessageScreen.offsetHeight;
              console.log("New height after setting to 140px: " + newHeight + "px");
              
            });
          } else {
            console.log('dialogMessageScreen Element not found! Make sure you have correctly set the ref attribute.');
          }
        },

        async doCreateRealRyftPaymentSession(){
            try {
              let params = this.getPaymentRequestParams(undefined, undefined, this.lastButtonTapped, +(this.lastAmountChosen * 100).toFixed(0), undefined, undefined, "Ryft"); 
              console.log("about to do REAL ryftCreatePaymentSession");    
              let response = await this.runCloudInitiatePayment(params, "ryftCreatePaymentSession");
              console.log("Response from runCloudInitiatePayment ryftCreatePaymentSession (remember iframe load won;t be complete yet): " + response);
              return response;
            } catch (e) {
              console.log("ERROR in doCreateRealRyftPaymentSession : " + e.message);
              throw e;
            }
        },
        setStyleOnExtrasAndNotesOnConfirm(){

          let div = this.$refs.extrasAndNotesOnConfirm;

          console.log("setStyleOnExtrasAndNotesOnConfirm div is: " + div);
          div.style.position = 'absolute';
          div.style.top = '42px';
          div.style.left = '0px';
          div.style.background = "white";
          div.style.outline = '0.0em dashed blue';

        },
        setStyleOnExtrasAndNotesOnCardInput(){
          let div = this.$refs.extrasAndNotesOnCardInput;
          div.style.position = 'absolute';
          div.style.top = '42px';
          div.style.left = '0px';
          div.style.background = "white";
          div.style.outline = '0.0em dashed blue';
        },
        doDevDummyApplePayOpsPRE(){
          let devDummyApplePayButtonDiv = document.getElementById('thisiswhereiwas');
          devDummyApplePayButtonDiv.style.display = "block";
          let devDummyApplePayButton = document.getElementById('dev-dummy-apple-pay-button');
          
          console.log("DUMMY devDummyApplePayButton END arrangeNonRyftPaymentFormNodesForRyftOnConfirm IS: " + devDummyApplePayButton.id);
        },
        arrangeNonRyftPaymentFormNodesForRyftOnConfirm(){
          try {

            // let devDummyApplePayButton = document.getElementById('dev-dummy-apple-pay-button');
            // console.log("devDummyApplePayButton START arrangeNonRyftPaymentFormNodesForRyftOnConfirm IS: " + devDummyApplePayButton.id);

            console.log("arrangeNonRyftPaymentFormNodesForRyftOnConfirm this.canPayByApplePay: " + this.canPayByApplePay + " this.canPayByGooglePay: " + this.canPayByGooglePay + "  this.tipper.hasSavedPaymentSource: " + this.tipper.hasSavedPaymentSource);


            // we are going using Confirm screen
            // CRITICAL NOTE!! we have to move this now and not more than 2,500ms after the Ryft form loads otherwise the GP (and probably AP) button disappears, inexplicably
            this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDivOnWalletScreen, this.$refs.completeRyftAndTuPaymentSectionContainerDiv, "movePaymentFormToWalletConfirmNode");
            this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDiv, this.$refs.RyftPayForm, "movePaymentFormItselfBackIntoWalletConfirmNodeIfNecessary", true);
            this.resetRyftAndModalWidths();

            if (this.ryftTuPayButtonAndRelatedElementsContainerDivDisplayStyle !== "" && this.ryftTuPayButtonAndRelatedElementsContainerDivDisplayStyle !== "none") {
              console.log("setting ryftTuPayButtonAndRelatedElementsContainerDiv display style to: " + this.ryftTuPayButtonAndRelatedElementsContainerDivDisplayStyle);
              this.$refs.ryftTuPayButtonAndRelatedElementsContainerDiv.style.display = this.ryftTuPayButtonAndRelatedElementsContainerDivDisplayStyle ;
            }
            

            // NB remember at this point the Form hasn't loaded, we have no Ryft element, in order to shunt the non-Ryft components around, we need to anticipate what WILL be happening when the Ryft form arrives, so for now we MUST use this.tipper.hasSavedPaymentSource ie what's comign from the DB
            if (this.canPayByApplePay && this.tipper.hasSavedPaymentSource){
              console.log("this.canPayByApplePay && this.tipper.hasSavedPaymentSource");
              this.setStyleOnExtrasAndNotesOnConfirm();
              this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDiv, this.$refs.APorGPInsteadOptionButtonOnConfirm, "moveGPInsteadOptionToRyftFormContainer");
              this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, this.$refs.ryftTuPayButtonAndRelatedElementsContainerDiv, "moveTUConfirmButtonToOwnLocation"); // starts life nested / overlaid in Ryft Pay Form - we are moving it off to overlay saved payment card Ryft Pay Form which will be detached and put here when it arrives
              // this.doDevDummyApplePayOpsPRE();
              this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, this.$refs.extrasAndNotesOnConfirm, "moveExtrasAndNotesOnConfirmTODetachedSavedLocationFromStripeToRyftPosition");
              this.applyStylesToAPInsteadOptionPreFormLoad();           
            } else if (this.canPayByGooglePay && this.tipper.hasSavedPaymentSource) {
              console.log("this.canPayByGooglePay && this.tipper.hasSavedPaymentSource");
              this.setStyleOnExtrasAndNotesOnConfirm();
              // this.$refs.completeRyftAndTuPaymentSectionContainerDivOnWalletScreen.style.height = "82px";
              this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDiv, this.$refs.APorGPInsteadOptionButtonOnConfirm, "moveGPInsteadOptionToRyftFormContainer");
              this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, this.$refs.ryftTuPayButtonAndRelatedElementsContainerDiv, "moveTUConfirmButtonToOwnLocation"); // starts life nested / overlaid in Ryft Pay Form - we are moving it off to overlay saved payment card Ryft Pay Form which will be detached and put here when it arrives
              this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, this.$refs.extrasAndNotesOnConfirm, "moveExtrasAndNotesOnConfirmTODetachedSavedLocationFromStripeToRyftPosition");
              this.applyStylesToGPInsteadOptionPreFormLoad();
              this.$refs.completeRyftAndTuPaymentSectionContainerDivOnWalletScreen.style.height = "62px";
            } else if (this.tipper.hasSavedPaymentSource) {
              console.log("ONLY this.tipper.hasSavedPaymentSource");
              this.setStyleOnExtrasAndNotesOnConfirm();
              // this.$refs.completeRyftAndTuPaymentSectionContainerDivOnWalletScreen.style.height = "82px";
              this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDiv, this.$refs.APorGPInsteadOptionButtonOnConfirm, "moveGPInsteadOptionToRyftFormContainer");
              this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, this.$refs.ryftTuPayButtonAndRelatedElementsContainerDiv, "moveTUConfirmButtonToOwnLocation"); // starts life nested / overlaid in Ryft Pay Form - we are moving it off to overlay saved payment card Ryft Pay Form which will be detached and put here when it arrives
              
              this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, this.$refs.extrasAndNotesOnConfirm, "moveExtrasAndNotesOnConfirmTODetachedSavedLocationFromStripeToRyftPosition");
              this.applyStylesToGPInsteadOptionPreFormLoad();

              this.$refs.completeRyftAndTuPaymentSectionContainerDivOnWalletScreen.style.height = "62px";

            } else if (this.canPayByApplePay) {
              console.log("ONLY this.canPayByApplePay");
              this.payingByApplePay = true;
              this.setStyleOnExtrasAndNotesOnConfirm();
              this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDiv, this.$refs.extrasAndNotesOnConfirm, "moveExtrasAndNotesOnConfirmTOcompleteRyftAndTuPaymentSectionContainerDivFromStripeToRyftPosition");
              this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDiv, this.$refs.blankspacer, "moveBlankSpacerTOcompleteRyftAndTuPaymentSectionContainerDivFromStripeToRyftPosition");
              this.$refs.APorGPInsteadOptionButtonOnConfirm.style.display = "none";
              let div = this.$refs.RyftPayForm;          
              div.style.overflow = "hidden"; // we need this because when it is loaded, the Apple Pay button in particular will temporarily resume full width before coming back down to size, and will therefore be visible on the right hand side of the screen
              this.$refs.extrasAndNotesOnConfirm.style.height = "200px"; // overflows the bottom of the screen hust to make the point that it's height is not calculated beyond here

            } else if (this.canPayByGooglePay) {
              console.log("ONLY this.canPayByGooglePay ");
              this.payingByGooglePay = true;
              this.setStyleOnExtrasAndNotesOnConfirm();
              this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDiv, this.$refs.extrasAndNotesOnConfirm, "moveExtrasAndNotesOnConfirmTOcompleteRyftAndTuPaymentSectionContainerDivFromStripeToRyftPosition");
              this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDiv, this.$refs.blankspacer, "moveBlankSpacerTOcompleteRyftAndTuPaymentSectionContainerDivFromStripeToRyftPosition");
              this.$refs.APorGPInsteadOptionButtonOnConfirm.style.display = "none";
              this.$refs.extrasAndNotesOnConfirm.style.height = "200px"; // overflows the bottom of the screen hust to make the point that it's height is not calculated beyond here
              this.setTUConfirmButtonRadius(); // becuase it is the only live payment button hence the Confirm button will sit over it and we cannot access the Google Pay button itself directly
      
            } else {
              console.log("WE HAD NOTHING!");
              return false;
            }

            // all true cases
            
            return true; // all other cases
            
          } catch (e) {
            console.log("ERROR in arrangeNonRyftPaymentFormNodesForRyftOnConfirm: " + e.message);
            throw e;
          }
        },
        async doRyftPaymentPrepOperations(){
          


          // this.canPayByApplePay = false; // for dev
          // this.canPayByGooglePay = false; // for dev
          // this.tipper.hasSavedPaymentSource = false; // for dev
          // this.hasSavedPaymentSource = false; // for dev



          if ((this.canPayByApplePay || this.canPayByGooglePay || this.tipper.hasSavedPaymentSource) && this.fromChangePaymentMethodButton === false){

            let result = await this.doCreateRealRyftPaymentSession(); // do it immediately for both screens
            if (result === "ok"){
              this.initRyft(this.paymentSessionClientSecret, this.ryftPaymentMethodsJSON, true, true); // no point doing await as it loads the form, and some key actions which follow that must be triggered from onload - we could have set up polling here, but it felt hacky  
              // at this point, we already triggered the form once, so we ALREADY know which Ryft Pay Form elements are on their way, just not loaded yet
            }

            console.log("AP / GP / stored card status... " + this.canPayByApplePay + "     " + this.canPayByGooglePay + "     " + this.tipper.hasSavedPaymentSource);
            this.doUpdateAddFeesOrNot(); // in case the add fee box was ticked from prior transaction
            
            let arrangeResult = this.arrangeNonRyftPaymentFormNodesForRyftOnConfirm(); // even though form isn't loaded yet we know where all the non Ryft PAyment Form nodes are going to end up once it is, and we know which elements of the form we are going to show as visible and we know that on this screen (because there is no card input) all of AP, GP and Saved CArd elements will be 42px
            if (arrangeResult === false) { // WE COuLDN'T ARRANGE the nodes indicating some unknown problem managing our interface, this is where we SHOULD SHOW OUR FALLBACK PAYMENT SCREEN with everything on it, but for now...
              this.showRyftCardInputForPayment();
            }
            this.showConfirmation = true;
            this.componentKey = !this.componentKey; 
            this.$nextTick(() => {
              // now we have all the non Ryft Payment Form nodes in the right place we can calculate what heights need to be adjusted to display everything properly, the Ryft Pay Form components will follow but always be behind existing non form elements
              this.$refs.savedSourceOrAPorGPConfirmPayment.style.display = "none";

              this.setBlankSpacerHeight(this.$refs.blankspacer, this.$refs.extrasAndNotesOnConfirm, "setBlankSpacerHeight", 42); // we have all the non Ryft components required to calculate the spacer height - in some instances we don't need this spacer but in those instances the display has been set to none
                           
            });
            
            console.log("Ryft confirmation screen should now be showing");
          } else {

            // this.detectShowIfUKPlatformCardCountryUnknown(true); // just sets this.showIfUKPlatformCardCountryUnknown which is irrelevant on Ryft as we won't change what the tipper is being charged
            this.showIfUKPlatformCardCountryUnknown = false; // to make sure we don't show the above messaging

            let result = await this.doCreateRealRyftPaymentSession(); // do it immediately for both screens
            if (result === "ok"){
              this.initRyft(this.paymentSessionClientSecret, this.ryftPaymentMethodsJSON, false, false); // no point doing await as it loads the form, and some key actions which follow that must be triggered from onload - we could have set up polling here, but it felt hacky  
              // at this point, we already triggered the form once, so we ALREADY know which Ryft Pay Form elements are on their way, just not loaded yet
            }

            console.log("doing showRyftCardInputForPayment");
            this.configureRyftPayFormCardInput();
            this.showRyftCardInputForPayment();
          }
        },

        movePaymentFormToWalletConfirmNode(){
          try {
            // Access nodes via refs, using names that match the id attributes
            let newParentNode = this.$refs.completeRyftAndTuPaymentSectionContainerDivOnWalletScreen;
            let childNode = this.$refs.completeRyftAndTuPaymentSectionContainerDiv;

            // Check if the parentNode contains the childNode
            if (newParentNode && childNode) {
              let isChild = newParentNode.contains(childNode);
              if (!isChild) {
                // Move the childNode to the new parent element
                newParentNode.appendChild(childNode);
                console.log("Ryft should be on wallet parent");
              }
            } else {
              console.log("FAILED : movePaymentFormToWalletConfirmNode : newParentNode is " + newParentNode + " and childNode is " + childNode);
            } 

            // Assuming ref is set on a relevant element in the template
            this.$refs.savedSourceOrAPorGPConfirmPayment.style.display = 'none';
          } catch (e) {
            console.log("ERROR in movePaymentFormToWalletConfirmNode: " + e.message);
            throw e;
          }
        }, 
        
        moveNode(newParentNode, childNode, moveDescription, toFirstPosition){
          console.log("starting moveNode: + " + moveDescription + "...");
          try {
            // Check if the parentNode contains the childNode
            if (newParentNode && childNode) {
              let isChild = newParentNode.contains(childNode);
              if (!isChild) {
                if (toFirstPosition) {
                  // Prepend the childNode to the new parent element as the first child
                  newParentNode.insertBefore(childNode, newParentNode.firstChild);
                  console.log(childNode.id + " should be In FIRST POSITION on new parentNode " + newParentNode.id);
                } else {
                  // Move the childNode to the new parent element
                  newParentNode.appendChild(childNode);
                  console.log(childNode.id + " should be on new parentNode " + newParentNode.id);
                }
              } else {
                console.log("FAILED : " + moveDescription + " : THERE IS NO childNode: " + childNode);
              }
            } else {
              console.log("FAILED : " + moveDescription + " : newParentNode is " + newParentNode + " and childNode is " + childNode);
            } 
            console.log("finished " + moveDescription);
          } catch (e) {
            console.log("ERROR in moveNode on: "+ moveDescription + ":  " + e.message);
            throw e;
          }
        },
        
        async savedSourceConfirmPayment(){
    
          this.buttonPressNum += 1;
    
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " confirmed savedSource payment " + new Date().toISOString() + " button click num: " + this.buttonPressNum, this.globalPushForwardInterval, this.loggingError);
    
          const params = this.getPaymentRequestParams(undefined, undefined, undefined, +(this.lastAmountChosen * 100).toFixed(0), undefined, undefined, "savedSourceConfirmPayment");  
          // we are not bothering to fetch the source details on the client side as they will by default be retrieved on the backend
          await this.runCloudInitiatePayment(params, "ok");
        },
    
        consoleLogConfirmButton(stage){
          let confirmButton = this.$refs.savedSourceOrAPorGPConfirmPayment; // we may use this if user switches from savedSource to AP/GP so let's populate as much as we can here with just paymentRequest to go
          console.log("ConfirmButton at stage " + stage + "   buttonNum: " + confirmButton.buttonNum + "   amountChosen: " + confirmButton.amountChosen + "   paymentRequest: " + confirmButton.paymentRequest + "   event: " + confirmButton.event);
        },
        
        async savedSourceCancelPayment(){
          // console.log("savedSourceCancelPayment");
    
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " CANCELLED savedSource payment: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
          
          
          const params = this.getPaymentRequestParams(undefined, undefined, undefined, +(this.lastAmountChosen * 100).toFixed(0), "log-cancelled", "cancel button payment confirm existing source", "savedSourceCancelPayment");   
          // console.log("params JUST BEFORE run cloud initiate: " + JSON.stringify(params, null, 2));
          await this.runCloudInitiatePayment(params, "ok");
          this.doShowCancelled(); // this needs to be AFTER logging cancel because it blanks values which need to be logged
        },
        doShowCancelled(){
          console.log("DOING SHOWCANCELLED");
          if (this.useRyft === true ){
            this.showMainFinishScreen("ryft-cancelled");
          }
          const backgroundcolour = '#EDF2F7';// the pale red we tried was'#e54a1c';
          document.body.style.backgroundColor = backgroundcolour;
          this.goAgainMessage = "Start again";
          this.setTipAgainVisible= true;
          this.showRecipient = false;
          this.showRecipientsList = false;
          this.paymentCancelled = true;
          this.paymentSubmitted = false;
          this.showConfirmation = false;
          this.showDisplayNameForReceiptInput = false;
    
          this.lastButtonTapped = undefined; this.lastAmountChosen = undefined; 
          console.log("did doShowCancelled");
          this.loggingError = true;
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " being shown cancelled tip: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
          window.localStorage.removeItem("fullThisDataSet");
          console.log("REMOVED fullThisDataSet at doShowCancelled");
        },
        blankTipArrayTypeValues(){
          this.tipsArray = [];
          this.transactionOutcomeResultObject = undefined;
          console.log("just blanked tipsArray");
          if (this.isReallyMultiTips === false){
            this.usingMultiTips = false; // this is because to confirm non multi tip venues to the multi tip array structure, after usingMultiTips is used to assess whether to ask the add a tip question then if usingMultiTips was false, we turn it to true to make the transaction progress as if it then were, and at the end we need to switch it back in case the user does in fact do a second tip and then gets asked the add a tip question
            console.log("this.usingMultiTips at 02 is NOW " + this.usingMultiTips);
          }
          this.showCancelAddTip = false;
          this.showGothankUHome = true; // we don't generally show it but at this point the user may be confused
          this.doNotShowGetTipsHereButton = true;
          this.showTwoOptionsOnMainPopup = false;
          this.nextAction = "";
          this.buttonNumEventObject = {};
          this.multipleTipsTotalNumber = undefined;
          this.allrecipientDisplaynamesAndAmounts = undefined;
          this.allrecipientDisplaynames = undefined;
          this.atLeaseOneRecipientHasPhoto = false;
          this.recipientImageDataURLIsNotPhoto = true;
    
        },
        async showRyftCardInputForPayment(){
    
          try {
            // this.moveNode(this.$refs.ryftTuPayButtonAndRelatedElementsContainerDiv, this.$refs.paymentFormExtrasAndNotesOnCardInput, "movePaymentFormExtrasAndNotesFromStripeToRyftOnCardInput"); // the main Ryft form is on or has been moved back to the Card Input screen, and the Extras and Notes already on that Card Input screen are now moved up to the Ryft Form
            shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " being shown Ryft card input dialog " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            
            this.ryftTuPayButtonAndRelatedElementsContainerDivDisplayStyle = window.getComputedStyle(this.$refs.ryftTuPayButtonAndRelatedElementsContainerDiv).display;
            this.$refs.ryftTuPayButtonAndRelatedElementsContainerDiv.style.display = "none";
            this.$refs.RyftPayForm.style.height = "auto";
            this.$refs.RyftPayForm.style.width = "100%";
            
            
            this.showRecipient = false;
            this.showConfirmation = false;
            this.showPaymentScreen = false;
            this.showCardInput = true;

            
            // this.canPay = true; // DEV ONLY
    
          } catch (e) {
            console.log("ERROR on ryft create: " + e.message);
            throw e;
          }
        },
        showCardInputForPayment(buttonNum){
    
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " being shown card input dialog " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
          
          this.showRecipient = false;
          this.showConfirmation = false;
          this.showPaymentScreen = false;
          this.showCardInput = true;
    
          // /console.log("doing card payment!!!::: " + buttonNum);
    
          var elements = this.stripe.elements();
          var style = {    
            base: {
              iconColor: '#c4f0ff',
              color: '#000012',
              fontWeight: '500',
              fontFamily: '"LT", Helvetica, Arial',
              fontSize: '14px',
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': {
              color: '#000012',
              },
              '::placeholder': {
                color: '#87BBFD',
              },
            },
            invalid: {
              iconColor: '#FFC7EE',
              color: '#FFC7EE',
            },
          };
    
          var card = elements.create("card", { style: style, hidePostalCode: true, disableLink: true}); // 
          card.mount("#card-element");
    
          this.card = card;
    
          card.on('change', async ev =>  {
    
            // console.log("HERE IS THE ERROR " + JSON.stringify(ev, null, 2));
            // var displayError = document.getElementById('card-errors');
            if (ev.error) {
              // displayError.textContent = event.error.message;
              // this.popUpMsgBody = JSON.stringify(event.error, null, 2);
              // this.showPopUpOk = true;
              // console.log("card problem");
              // console.log(JSON.stringify(ev.error, null, 2));
              shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " error on user card input: " +  ev.error.message + "" + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            } else {
              
            }
          });
    
        },
    
        async cardInputConfirmPlatformSelect(){
    
          window.localStorage.removeItem("fullThisDataSet"); // we don't want it refreshing beyond this
          console.log("REMOVED fullThisDataSet at cardInputConfirmPlatformSelect");
    
          if (this.useRyft === true) {
    
            // this.testFromMashing();
            this.doSubmitRyftPayment();
            
          } else {
            this.cardInputConfirm();
          }
    
        },
    
        detectWhichWalletElementsAvailable(){

          this.hasSavedPaymentSource = this.detectSavedCardFormElement();
          this.canPayByApplePay = this.detectApplePayButtonElement();
          this.canPayByGooglePay = this.detectGooglePayButtonElement();
          
        },
    
        isolateApplePayButton(hasSavedPaymentSource){
          // let applePayButton = document.getElementById('ryft-pay-apple-pay-button'); don't actually need to do anything with it, but this is its reference - I guess if we wanted to we could change its border radius? 
    
          if (hasSavedPaymentSource === false){
            this.hideSavedCardFormElementOnly(true);
          } else {
            this.hideSavedCardFormElementOnly(false);
            this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, document.getElementById('ryft-pay-saved-cards-grid'), "moveRyftStoredCardButtonToOwnLocation");
          }
          this.hideRyftFormDividerTextSection(true);
          console.log("doing hideRyftiFrame from isolateApplePayButton");

          // let div = this.$refs.RyftPayForm;
          
          // div.style.overflow = "hidden"; // we need this because when it is loaded, the Apple Pay button in particular will temporarily resume full width before coming back down to size, and will therefore be visible on the right hand side of the screen
          // console.log("styles applied to " + div.id);

          this.hideRyftiFrame(true);
    
        },
        isolateGooglePayButton(hasSavedPaymentSource){
          
          // the Google Pay button is part of the Ryft iFrame so the only way to isolate it is to detect if it is present and then restrict the form height to what we anticipate the Google Pay button height will be, bieng 42px the same as Apple Pay, which is known
    
          // Apple Pay button will not appear on a non Apple phone so don't think I need to do anything? 
    
          if (hasSavedPaymentSource === false){
            this.hideSavedCardFormElementOnly(true); // hide saved cards
          } else {
            this.hideSavedCardFormElementOnly(false);
            // this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, document.getElementById('ryft-pay-saved-cards-grid'), "moveRyftStoredCardButtonToOwnLocation");
            // this.moveTUPayButtonToOwnLocation();
          }
          
    
          this.hideRyftFormDividerTextSection(true); // hide the header text above the iFrame to isolate the button
    
          this.showRyftiFrameJustButtonHeight();
         
    
    
        },
        detectSavedCardFormElement(){
          let savedCardsGrid = document.getElementById('ryft-pay-saved-cards-grid');
          if (savedCardsGrid) {
            console.log("this user has a saved Ryft payment source");
            return true;
          } else {
            console.log("this user doe NOT have a saved Ryft payment source");
            return false;
          }
        },
        detectApplePayButtonElement(){
          
          if (this.canPayByApplePay === true){
            return true; // if we had the capability on any detection, we have it, if we later go to card input we don't want this set to false in case we return
          }
          
          let applePayButton = document.getElementById('ryft-pay-apple-pay-button');
          
          if (applePayButton !== null) {
            console.log("SETTING this.canPayByApplePay to TRUE");
            // this.showBlankInstead = false;
            applePayButton.style.borderRadius = "15px"; // to match our nice button
            this.canPayByGooglePay = false;
            // this.canPayByApplePay = true; it's the return
            return true;
          } else {
            console.log("applePayButton was NULL");
            // this.canPayByApplePay = false; it's the return
            return false;
          }
        },
        detectGooglePayButtonElement(){
          
          if (this.canPayByGooglePay === true){
            return true;  // if we had the capability on any detection, we have it, if we later go to card input we don't want this set to false in case we return
          }

          let newCardsGridDividerBlock = document.getElementById('ryft-pay-new-card-divider-block');
          //may be null
          if (newCardsGridDividerBlock !== null){
            if (newCardsGridDividerBlock.innerHTML.indexOf("Google Pay") > -1){
              // this.showBlankInstead = false;
              this.canPayByApplePay = false;
              console.log("Can pay by Google Pay");
              // this.canPayByGooglePay = true; it's the return
              return true;
            } else {
              console.log("CANNOT pay by Google Pay: newCardsGridDividerBlock WASN'T NULL but Google Pay wasn't detected");
              // this.canPayByGooglePay = false; it's the return
              return false;
            }
          } else {
            console.log("newCardsGridDividerBlock was NULL");
            return false;
          }

          // I reconfirmed that we absolutely CANNOT access the contents of the iFrame
          // therefore although the button below IS present, AND woudl be a clearner method, we can't see it
    
          // let googlePayButton = document.getElementById('gpay-button-online-api-id');
          // console.log("googlePayButton: " + googlePayButton);
          // if (googlePayButton !== null) {
          //   this.canPayByGooglePay = true;
          //   console.log("Can pay by Google Pay");
          //   // alert("Can pay by Google Pay");
          // } else {
          //   this.canPayByGooglePay = false;
          //   console.log("CANNOT pay by Google Pay");
          //   // alert("CANNOT pay by Google Pay");
          // }
        },
        hideSavedCardFormElementOnly(setHidden){
    
          let showHeadingElement = false; // this is the group of components which actually says "Select a saved card" rather than the form element itself
          // we are using this as a simple override switch right now because we don't have a use case when they should be shown
          
          // savedCardsGridDivider is the middle point grey line which 'Select a saved card' sits in, though it is evidently in a div which takes the same amoutn of vertifcal space as the actual saved card form element (showing card details)
          let savedCardsGridDivider = document.getElementById('ryft-pay-saved-cards-divider');
          // savedCardsGridDivider.style.outline = "0.1em dashed brown"

          if (savedCardsGridDivider) {
            if (setHidden === true || showHeadingElement === false) {
              this.savedCardsGridDividerDisplayStyle = window.getComputedStyle(savedCardsGridDivider).display;
              console.log("this.savedCardsGridDividerDisplayStyle: " + this.savedCardsGridDividerDisplayStyle);
              savedCardsGridDivider.style.display = 'none';
            } else {
              savedCardsGridDivider.style.display = this.savedCardsGridDividerDisplayStyle;
            }
          }
          
    
          // savedCardsGridDividerBlock is the little text block sitting in the middle of savedCardsGridDivider which actually displays the text 'Select a saved card'
          let savedCardsGridDividerBlock = document.getElementById('ryft-pay-saved-cards-divider-block');
          // savedCardsGridDividerBlock.style.outline = "0.1em solid green"
          
          if (savedCardsGridDividerBlock){
            if (setHidden === true || showHeadingElement === false) {
              this.savedCardsGridDividerBlockDisplayStyle = window.getComputedStyle(savedCardsGridDividerBlock).display;
              console.log("this.savedCardsGridDividerBlockDisplayStyle: " + this.savedCardsGridDividerBlockDisplayStyle);
              savedCardsGridDividerBlock.style.display = 'none';
            } else {
              savedCardsGridDividerBlock.style.display = this.savedCardsGridDividerBlockDisplayStyle;
            }
          }
    
          // savedCardsGrid is the selectable form element itself displaying the saved card details which needs to be selected before payment can be made
          let savedCardsGrid = document.getElementById('ryft-pay-saved-cards-grid');
          // savedCardsGrid.style.outline = "0.1em solid yellow"
          if (savedCardsGrid){
            if (setHidden === true) {
              this.savedCardsGridDisplayStyle = window.getComputedStyle(savedCardsGrid).display;
              console.log("this.savedCardsGridDisplayStyle: " + this.savedCardsGridDisplayStyle);
              savedCardsGrid.style.display = 'none';
            } else {
              savedCardsGrid.style.display = this.savedCardsGridDisplayStyle;
            }
          }
    
    
        },
        hideRyftFormDividerTextSection(setHidden){
          // newCardsGridDivider is the middle point grey line which either 'Pay with Google Pay' or 'Enter a new card' sits in, though it is evidently in a div which takes the same amount of vertical space as the actual new Card or Google Pay button element, remember the 2 form elements are in fact indivisible inside the iFrame
          let newCardsGridDivider = document.getElementById('ryft-pay-new-card-divider');
          
          if(newCardsGridDivider){
            if (setHidden === true) {
              this.newCardsGridDividerDisplayStyle = window.getComputedStyle(newCardsGridDivider).display;
              console.log("this.newCardsGridDividerDisplayStyle: " + this.newCardsGridDividerDisplayStyle);
              newCardsGridDivider.style.display = 'none';
            } else {
              newCardsGridDivider.style.display = this.newCardsGridDividerDisplayStyle;
            }
          }
    
    
          // newCardsGridDividerBlock is the little text block sitting in the middle of newCardsGridDivider which actually displays the text 'Pay with Google Pay' or 'Enter a new card'
          let newCardsGridDividerBlock = document.getElementById('ryft-pay-new-card-divider-block');
          
          if (newCardsGridDividerBlock){
            if (setHidden === true) {
              this.newCardsGridDividerBlockDisplayStyle = window.getComputedStyle(newCardsGridDividerBlock).display;
              console.log("this.newCardsGridDividerBlockDisplayStyle: " + this.newCardsGridDividerBlockDisplayStyle);
              newCardsGridDividerBlock.style.display = 'none';
            } else {
              newCardsGridDividerBlock.style.display = this.newCardsGridDividerBlockDisplayStyle;
            }
          }

        },
    
        hideRyftiFrame(setHidden){
    
          console.log("STARTING hideRyftiFrame");
          let ryftiFrame = document.getElementById('ryft-pay-iframe');
          console.log("ryftiFrame: " + ryftiFrame);
    
          if (setHidden === true){
            ryftiFrame.style.visibility = 'hidden';
            let currentHeight = ryftiFrame.style.height;
            // Log current height
            console.log('Current Height: ' + currentHeight);
            this.ryftFrameStyleHeight = currentHeight;
            ryftiFrame.style.height = 0;
            // ryftiFrame.style.outline = "0.1em dashed blue";
            let currentHeightPost = ryftiFrame.style.height;
            console.log('Current Height POST: ' + currentHeightPost);
            console.log("FINISHED hideRyftiFrame");
          } else {
            ryftiFrame.style.height = this.ryftFrameStyleHeight;
            ryftiFrame.style.visibility = 'visible';
          }  
        },
        showRyftiFrameJustButtonHeight(){
    
          console.log("Doing showRyftiFrameJustButtonHeight");
          let ryftiFrame = document.getElementById('ryft-pay-iframe');
          console.log("ryftiFrame: " + ryftiFrame);

          if (ryftiFrame){
            let currentHeight = ryftiFrame.style.height;
            // Log current height
            console.log('Current Height showRyftiFrameJustButtonHeight: ' + currentHeight);
            console.log("about to set frame height on showRyftiFrameJustButtonHeight");
            this.ryftFrameStyleHeight = currentHeight;
            ryftiFrame.style.height = "62px";
            ryftiFrame.style.overflow = 'hidden !important';
            // ryftiFrame.style.outline = "0.1em dashed blue";
            let currentHeightPost = ryftiFrame.style.height;
            console.log('Current Height showRyftiFrameJustButtonHeight POST: ' + currentHeightPost);
          }
        },
        makeRyftFormZeroHeight (){
          // it turns out the form had residual height for an empty form which we CAN set to zero if we need to
    
          // However remember that it also contains the Apple Pay button if present, so can't think wehn we will need to do this
          var ryftForm = document.getElementById('ryft-pay-form');
          let currentFormHeight = ryftForm.clientHeight;
          ryftForm.style.height = '0px';
          ryftForm.style.overflow = 'hidden';
        },
    
        async deleteRyftPaySavedCardsGridIfOutsideRyftPayForm(){
    
          let movedParentNode = this.$refs.movedRyftStoredPaymentButtonContainer;
          let nodeToDelete = document.getElementById('ryft-pay-saved-cards-grid');

          // Check if nodeToDelete is a child of movedParentNode
          if (movedParentNode && nodeToDelete && movedParentNode.contains(nodeToDelete)) {
            // Remove nodeToDelete from the DOM if it is a child
            nodeToDelete.remove();
          }

          nodeToDelete = document.getElementById('ryft-pay-saved-cards-divider'); 
          // Check if nodeToDelete is a child of movedParentNode
          if (movedParentNode && nodeToDelete && movedParentNode.contains(nodeToDelete)) {
            // Remove nodeToDelete from the DOM if it is a child
            nodeToDelete.remove();
          }

          // Do nothing if nodeToDelete is not a child

          this.$nextTick(() => {
            return;
          });

        },
        async ryftiFrameIsPresentWhetherOrNotLoaded(){
    
          let ryftiFrame = document.getElementById('ryft-pay-iframe');
          console.log("ryftiFrame: " + ryftiFrame);
          if (ryftiFrame === null) {
            console.log("ryft iFrame not yet present");
            // alert("ryft iFrame not yet present")
            return false;
          } else {
            console.log("ryft iFrame IS present");
            // alert("ryft iFrame IS present");
            return true;
          }
        },
        setRyftiFrameLoadingListener(){
          let ryftiFrame = document.getElementById('ryft-pay-iframe');
          if (ryftiFrame) {
              ryftiFrame.addEventListener('load', this.onRyftIframeLoaded);
          } else {
              console.error('ryftiFrame not found!');
          }
    
        },
        onRyftIframeLoaded(){
          try {
            let ryftiFrame = document.getElementById('ryft-pay-iframe');
            if (ryftiFrame) {

              

              ryftiFrame.removeEventListener('load', this.onRyftIframeLoaded);

    
              this.detectWhichWalletElementsAvailable(); // now that the form has loaded
              console.log("DID this.detectWhichWalletElementsAvailable() this.ryftSessionType is: " + this.ryftSessionType);

              if (this.ryftSessionType !== "testAPandGPStatus" && this.useRyft === true) {
                console.log("ABOUT TO this.moveRyftPayFormElements()");

                // window.setTimeout(this.moveRyftPayFormElements, 2000); // This was used for dev debug - BIG NOTE! BE AWARE:: after an impossible amount of searching out a problem, it turns out that if MOVE the form or one of its containers more than about 2500 ms after the form has loaded, then the Google Pay (possible Apple Pay too) button will disappear on the move. No idea why. On that basis we were previously doing this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDivOnWalletScreen, this.$refs.completeRyftAndTuPaymentSectionContainerDiv, "movePaymentFormToWalletConfirmNode"); here and this was cause the button to disappear, have now moved it to arrangeNonRyftPaymentFormNodesForRyftOnConfirm
                this.moveRyftPayFormElements();  
              } else if (this.ryftSessionType === "testAPandGPStatus")  {
                this.ryftSessionType = ""; // not sure yet if we needed it, but good to have
                //move the Ryft Pay Form elements, other elements have already been moved
                console.log("ABOUT TO send RYFT SUCCESSFULLY LOADED");

                let retriesMessage = "";

                if (this.ryftLoadTries > 0){
                  retriesMessage = "<br/>this.ryftLoadTries: " + this.ryftLoadTries;
                }
                Parse.Cloud.run("sendEmail", { toEmail: "appnotif@thanku.app", subject: "RYFT SUCCESSFULLY LOADED: " + this.tipper.objectId, body: "UseRyft: " + this.useRyft + retriesMessage, });  
              } else {
                console.log("some other situation");
              }
    
                          
            } else {
                console.error('ryftiFrame not found on loaded!');
            }
          } catch (e) {
            console.log("ERROR in onRyftIframeLoaded: " + e.message);
              Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ERROR in onRyftIframeLoaded for: " + this.tipper.objectId, body: "ERROR is: " + e.message + "<br/><br/>UseRyft: " + this.useRyft, });  
          }
        },
        moveRyftPayFormElements(){
          try {

            

            // this.canPayByApplePay = false; // for dev
            // this.canPayByGooglePay = false; // for dev
            // this.hasSavedPaymentSource = false; // for dev

            // NB CRITICAL!! DO NOT move the Ryft iFrame more than 2,500ms after it as loaded otherwise the Google Pay button (maybe also Apple Pay) will disappear!!!! 
          

            console.log("moveRyftPayFormElements this.canPayByApplePay: " + this.canPayByApplePay + "   this.canPayByGooglePay: " + this.canPayByGooglePay + "  this.hasSavedPaymentSource: " + this.hasSavedPaymentSource);

            if ((this.canPayByApplePay || this.canPayByGooglePay || this.hasSavedPaymentSource) && this.fromChangePaymentMethodButton === false){
              if (this.canPayByApplePay && this.hasSavedPaymentSource){
                console.log("this.canPayByApplePay && this.hasSavedPaymentSource");
                this.showBlankInstead = false;
                let devDummyApplePayButton = document.getElementById('dev-dummy-apple-pay-button');
                console.log("devDummyApplePayButton PRE IS: " + devDummyApplePayButton.id);
                this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, document.getElementById('ryft-pay-saved-cards-grid'), "moveRyftStoredCardButtonToOwnLocation", true);
                this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, document.getElementById('ryft-pay-saved-cards-divider'), "moveRyftStoredCardButtonDIVIDERToOwnLocation", true);
                this.hideSavedCardFormElementOnly(false);
                this.setBlankSpacerHeight(this.$refs.blankspacer, this.$refs.extrasAndNotesOnConfirm, "setBlankSpacerHeight", 0); // we have all the non Ryft components required to calculate the spacer height
                this.hideRyftFormDividerTextSection(true);
                this.hideRyftiFrame(true);
                this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDiv, this.$refs.APorGPInsteadOptionButtonOnConfirm, "moveAPorGPInsteadOptionToRyftFormContainer");
                this.applyStyleToSavedCardGridPostFormLoad();
                this.moveNode(document.getElementById('completeRyftAndTuPaymentSectionContainerDiv'), document.getElementById('ryft-pay-apple-pay-button'), "MoveAPInsteadOptionToRyftFormContainerRyftPayGrid", true); 
                this.hideRyftFormDividerTextSection(true);
                console.log("doing hideRyftiFrame from isolateApplePayButton");
                // this.$refs.RyftPayForm.style.height = "42px";
                this.hideRyftiFrame(true);

                // devDummyApplePayButton = document.getElementById('dev-dummy-apple-pay-button');
                // console.log("devDummyApplePayButton POST IS: " + devDummyApplePayButton);
                // this.moveNode(document.getElementById('completeRyftAndTuPaymentSectionContainerDiv'), document.getElementById('dev-dummy-apple-pay-button'), "MOVEFAKEAPInsteadOptionToRyftFormContainerRyftPayGrid", true); // FAKE BUTTON FOR DEV ONLY! 
              } else if (this.canPayByGooglePay && this.hasSavedPaymentSource) {
                console.log("this.canPayByGooglePay && this.hasSavedPaymentSource");
                this.showBlankInstead = false;
                this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, document.getElementById('ryft-pay-saved-cards-grid'), "moveRyftStoredCardButtonToOwnLocation", true);
                this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, document.getElementById('ryft-pay-saved-cards-divider'), "moveRyftStoredCardButtonDIVIDERToOwnLocation", true);
                this.hideSavedCardFormElementOnly(false);
                this.applyStylesToGPInsteadOptionPOSTFormLoad();
                this.setBlankSpacerHeight(this.$refs.blankspacer, this.$refs.extrasAndNotesOnConfirm, "setBlankSpacerHeight", 0); // we have all the non Ryft components required to calculate the spacer height
                this.hideRyftFormDividerTextSection(true);
                // this.$refs.RyftPayForm.style.height = "42px";
                
                
              } else if (this.hasSavedPaymentSource) {
                console.log("ONLY this.hasSavedPaymentSource");
                this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, document.getElementById('ryft-pay-saved-cards-grid'), "moveRyftStoredCardButtonToOwnLocation", true);
                this.moveNode(this.$refs.movedRyftStoredPaymentButtonContainer, document.getElementById('ryft-pay-saved-cards-divider'), "moveRyftStoredCardButtonDIVIDERToOwnLocation", true);
                this.hideSavedCardFormElementOnly(false);
                this.applyStyleToSavedCardGridPostFormLoad();
                this.setBlankSpacerHeight(this.$refs.blankspacer, this.$refs.extrasAndNotesOnConfirm, "setBlankSpacerHeight", 0); // we have all the non Ryft components required to calculate the spacer height
                this.hideRyftFormDividerTextSection(true);
                this.hideRyftiFrame(true);
              } else if (this.canPayByApplePay) {
                console.log("ONLY this.canPayByApplePay");
                // this.hideSavedCardFormElementOnly(true);
                this.isolateApplePayButton(false);
              } else if (this.canPayByGooglePay) {
                console.log("ONLY this.canPayByGooglePay");
                // this.hideSavedCardFormElementOnly(true);
                this.isolateGooglePayButton(false);
              } else {
                console.log("NOT EXPECTING THIS, fall back...");
                this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerTdOnCardInputScreen, this.$refs.completeRyftAndTuPaymentSectionContainerDiv, "movePaymentFormToCardInputNode")// there's an unexpected problem, fall back to card input for now until we have a proper fallback screen with all payment elements
                return false;
              }

              return true; // all other cases

            } else {


              // we will have done it at initial arrange time

            }
      
 
          } catch (e) {
            console.log("ERROR in moveRyftPayFormElements: " + e.message);
          }
        },
        configureRyftPayFormCardInput(){

              // we are using card input screen
              // this.hideSavedCardFormElementOnly(true);
              // this.hideRyftFormDividerTextSection(true);

              // NOTE: the issue is with the card input form inside the iFrame, which in my original configuration doesn't fully show on the screen andis truncated to the right, though the form can scroll laterally (not desirable). NO attempt to control the width of the card input form outside or on the iFrame worked. The only solution was to give it the width it wants which is no less than 95% width of the screen. That is therefore what we have done. For some reason when I actually implemented it seemed to need 97% so that is what we have given it.

              // let ryftiFrame = document.getElementById('ryft-pay-iframe');
              // console.log("ryftiFrame: " + ryftiFrame);
      
              // if (ryftiFrame){
              //   ryftiFrame.style.width = '100%';
              //   ryftiFrame.style.overflow = "hidden";
                
              // } else {
              //   alert ("couldn't fine ryftiFrame");
              // }

              // this.moveNode(this.$refs.ryftpayformgoeshere, this.$refs.RyftPayForm, "moveRyftPayFormOnCardInputNodeForWidth");
              // this.$refs.RyftPayForm.style.zIndex = 999;

              
              this.moveNode(this.$refs.completeRyftAndTuPaymentSectionContainerDivOnCardInputScreen, this.$refs.RyftPayForm, "movePaymentFormToCardInputNode");

              this.$refs.ryftPayFormEnclosingModal.style.width = "97%";
              this.$refs.ryftPayFormEnclosingTable.style.width = "100%";
              this.$refs.RyftPayForm.style.width = "100%";
              console.log("we are using card input screen");
        },
        resetRyftAndModalWidths(){

          this.$refs.ryftPayFormEnclosingModal.style.width = "91%";
          this.$refs.ryftPayFormEnclosingTable.style.width = "95%";
          this.$refs.RyftPayForm.style.width = "95%";
          this.$refs.RyftPayForm.style.height = "42px";
          

        },
        applyStylesToAPInsteadOptionPreFormLoad(){

          let div = this.$refs.APorGPInsteadOptionButtonOnConfirm;
          div.style.position = 'absolute';
          div.style.top = '0';
          div.style.margin = 'auto';
          div.style.width = '100%';
          div.style.background = "white";
          div.style.pointerEvents = "none";   
          div.style.display = "none";       
          div.style.height = "200px"; // setting it high in order to make the point as it won't increase the div at this point.. we need the extra white background stretching down to cover the Ryft form at the point it loads, because any manipulation of the Ryft form at that point, to make it small, move the saved card out etc, will be quick but will still show on screen for a microsecond which is undesirable

          console.log("styles applied to " + div.id);

          div = this.$refs.RyftPayForm;
          div.style.paddingTop = '5px';
          div.style.width = "60%";
          div.style.overflow = "hidden"; // we need this because when it is loaded, the Apple Pay button in particular will temporarily resume full width before coming back down to size, and will therefore be visible on the right hand side of the screen
          console.log("styles applied to " + div.id);

          this.$refs.APorGPInsteadOptionBlanking.style.display = "block";

          this.$refs.blankPayButton.height = "62px"; // because for GP it will have the scroll bar at the bottom of the form
        },
        applyStylesToGPInsteadOptionPreFormLoad(){

          let div = this.$refs.APorGPInsteadOptionButtonOnConfirm;
          div.style.position = 'absolute';
          div.style.top = '0';
          div.style.margin = 'auto';
          div.style.width = '100%';
          div.style.background = "white";
          div.style.pointerEvents = "none";
          div.style.display = "none";  
          div.style.height = "200px"; // setting it high in order to make the point as it won't increase the div at this point.. we need the extra white background stretching down to cover the Ryft form at the point it loads, because any manipulation of the Ryft form at that point, to make it small, move the saved card out etc, will be quick but will still show on screen for a microsecond which is undesirable

          console.log("styles applied to " + div.id);

          div = this.$refs.RyftPayForm;
          div.style.paddingTop = '5px';
          div.style.width = "60%";
          div.style.overflow = "hidden"; // see above
          console.log("styles applied to " + div.id);

          this.$refs.APorGPInsteadOptionBlanking.style.display = "block";

          this.$refs.blankPayButton.height = "62px"; // because for GP it will have the scroll bar at the bottom of the form
        },
        applyStyleToSavedCardGridPostFormLoad(){
          let div = document.getElementById('ryft-pay-saved-cards-grid');
          div.style.width = '95%';
          div.style.borderRadius = "15px";

          let firstChild = div.firstChild;

          firstChild.style.borderRadius = '15px';
          firstChild.style.border = '0px';

          
        },
        applyStylesToGPInsteadOptionPOSTFormLoad(){
          let div = document.getElementById('ryft-pay-saved-cards-grid');
          div.style.width = '95%';
          div.style.borderRadius = "15px";

          let firstChild = div.firstChild;

          firstChild.style.borderRadius = '15px';
          firstChild.style.border = '0px';

          
        },
        setTUConfirmButtonRadius(){
          if (this.canPayByGooglePay === true) {
            console.log("setting tupaybutton to border radius 4px")
            this.$refs.tupaybutton.style.borderRadius = "4px"; // to match Ryft Google Pay button over whcih we have no control
          } else {
            console.log("setting tupaybutton to border radius 15px")
            this.$refs.tupaybutton.style.borderRadius = "15px"; // our normal style
          }
        },
        testFromMashing(){
    
          this.isolateApplePayButton();
         
          
          
          // savedCardsGrid is the selectable form element itself displaying the saved card details which needs to be selected before payment can be made
          let savedCardsGrid = document.getElementById('ryft-pay-saved-cards-grid');
          savedCardsGrid.style.outline = "0.1em solid yellow"
          savedCardsGrid.style.display = 'none';
    
          var applePayButton = document.getElementById('ryft-pay-apple-pay-button');
    
            // Check if the button exists
    
         
    
    
          
          // if (applePayButton === null) {
            // alert("SOZ!!! No Apple Pay");
                   // HTML to insert before the button
            // let htmlBefore = '<div class="tupaybutton-container">';
            // HTML to insert after the button
            // let htmlAfter = '<mark class="tupaybutton-span">£1</mark>';
            // savedCardsGridDivider.insertAdjacentHTML('afterend', htmlAfter); // hoping that doing after first will prevent HTML break
            // savedCardsGrid.style.position = 'relative';
            // savedCardsGrid.appendChild(mark);
            // savedCardsGridDivider.insertAdjacentHTML('beforebegin', htmlBefore);
          // } else {
          //   alert("YAAY!!!! WE have Apple Pay");
            // let htmlBefore = '<div class="tupaybutton-container">';
            // let htmlAfter = '<mark class="tupaybutton-span">£1</mark>';
            
            // applePayButton.insertAdjacentHTML('afterend', htmlAfter); // hoping that doing after first will prevent HTML break
            // applePayButton.insertAdjacentHTML('beforebegin', htmlBefore);
            // applePayButton.classList.add('tupaybutton-button');
            
            
    
    
            // applePayButton.style.display = 'none';
          // }
    
          var ryftForm = document.getElementById('ryft-pay-form');
    
          // this.updateObfuscatedCardInfo();
    
          // var ryftGrid = document.getElementById('ryft-pay-grid');
    
          // ryftGrid.style.position = 'relative';
          // ryftGrid.style.display = 'inline-block';
    
    
    
    
    
          //  const currentHeight = ryftForm.offsetHeight;
          //   const newHeight = currentHeight * 0.5;  // Reducing height by 50%
          //   ryftForm.style.height = `${newHeight}px`;
    
          // let emailParams = {
          //         toEmail: "appalert@thanku.app",
          //         subject: "ALL SOURCE CODE",
          //         body: document.documentElement.outerHTML,
          //         // body: ryftForm.innerHTML,
          //         };
    
          // Parse.Cloud.run("sendEmail", emailParams);
    
    
    
          let newCardsGridDividerBlock = document.getElementById('ryft-pay-new-card-divider-block');
          console.log("check Google Pay: " + newCardsGridDividerBlock.innerHTML);
    
    
          // NB WE NEED TO REVISIT to check nothing fails for nulls if one or other isn't available
          // if (newCardsGridDividerBlock.innerHTML.indexOf("Pay with Google Pay") > -1){
          //   alert("Google Pay is available");
          // }
    
          // if (newCardsGridDividerBlock.innerHTML.indexOf("Pay with Apple Pay") > -1){ // for example this will NEVER be hit because Apple Pay is not in this section
          //   alert("Apple Pay is available");
          // }
          // newCardsGridDividerBlock.style.display = 'none';
          // var nextIframe = newCardsGridDivider.nextElementSibling;
    
          // nextIframe.style.visibility = 'hidden';
    
          // if (this.isIframeAccessible(nextIframe)) {
          //     console.log("Iframe is accessible");
          // } else {
          //     console.log("Iframe is not accessible, possibly due to cross-origin policies");
          // }
    
        },
        updateObfuscatedCardInfo() {
      // Select the specific div by class name
      const obfuscatedDiv = document.querySelector('.ryftPayCardObfuscated');
    
      // Check if the div exists and contains the expected text
      if (obfuscatedDiv && obfuscatedDiv.textContent.trim() === '**** **** ****') {
        // Update the content of the div
        obfuscatedDiv.textContent = '*';
      }
    },
        isIframeAccessible(iframe) {
            try {
                // Try accessing the contentWindow.document of the iframe
                var doc = iframe.contentWindow.document;
        
                // If accessing the document does not throw an error, return true
                return true;
            } catch (e) {
                // If an error is thrown, it is likely due to cross-origin restrictions
                return false;
            }
        },
        async cardInputConfirm(){
    
          console.log("cardInputConfirm");
    
          this.buttonPressNum += 1;
    
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " confirmed on card input dialog " + new Date().toISOString() + " button click num: " + this.buttonPressNum, this.globalPushForwardInterval, this.loggingError);
    
          const sourceUsage = this.saveSecurelyCheckboxValue ? 'reusable' : 'single_use';
    
          // /console.log("source usage::: " + sourceUsage);
    
          // /console.log("CARD::: " + JSON.stringify(this.card, null, 2));
    
    
          try {
         
            console.log("ABOUT to create source");
    
            let source = await this.stripe.createSource(this.card, {
                type: 'card',
                usage: sourceUsage,
            });
    
            console.log("have created source");
    
            if (source.error) {
    
              console.log("SOURCE ERROR:::: " + JSON.stringify(source.error));
    
              this.popUpMsgTitle = "Sorry";
              this.popUpMsgBody = source.error.message;
              this.showPopUpOk = true;
    
              shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " error on user card input: creation of source: " +  source.error.message + "" + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              
              // const params = {
              //   toEmail: "appalert@thanku.app",
              //   subject: "ALERT! ERROR creating SOURCE on FRONT END ",
              //   body: "" + JSON.stringify(source.error),
              // };
    
              // Parse.Cloud.run("sendEmail", params);
              
              // don't log this back to the cloud as it will mess with the client side error messaging
    
              return;
             
            } else {
              console.log("SOURCE IS FINE!!");
            }
    
            // console.log("SOURCE::: " + JSON.stringify(source, null, 2));
    
            
            this.loggingError = true;
    
    
            if (source) {
    
              if (sourceUsage === 'reusable'){
                // OK BIG NB!!!!!!! the PROBLEM with this is IF the source/customer CANNOT be created on the backend becuase of some payment failure, a decline which might be CVC or postcode, then the front end THINKS hasSavedPaymentSource is TRUE when it isn't, and therefore the follow on call to the backend doesnt have any source information AND the user record on the backend hasn't any stored source/customer ids either
    
                this.justAttemptedToCreateReusablePaymentSource = true;
                this.tipper.hasSavedPaymentSource = true; // set this as a session flag even though it will be saved for next time in the backend
                this.tipper.last4Digits = source.source.card.last4;// set this as a session value even though it will be saved for next time in the backend
                this.tipper.paymentMethod = "STCC"; // for the session, in case they do another, before it's picked up next time they come to thankU
                this.payingByApplePay = false;
                this.payingByGooglePay = false;
    
                shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " source is reusable, last 4 digits are: " +  this.tipper.last4Digits + "" + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              }
    
              shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " IF this produced an error, this.lastAmountChosen is: " + this.lastAmountChosen + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              console.log("at cardInputConfirm source this.lastAmountChosen is: " + this.lastAmountChosen);
              const params = this.getPaymentRequestParams(undefined, source, this.lastButtonTapped, +(this.lastAmountChosen * 100).toFixed(0), undefined, undefined, "cardInputConfirm source"); 
              
              shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " KEY PARAMS if this produced an error, params is: " + JSON.stringify(params, null, 2) + "   " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              
              await this.runCloudInitiatePayment(params, "ok");
              
            }
    
            // at some stage before too long we need to transition to the paymentMethods API https://stripe.com/docs/payments/payment-methods/transitioning#compatibility let paymentMethod = stripe.createPaymentMethod({ type: 'card', card: card, billing_details: {  name: 'Jenny Rosen',  }, })
            
          } catch (e) {
            this.deployLogRocket(this.logRocketOrganisationSlug);
            // console.log("cardInputConfirm error: " + JSON.stringify(e, null, 2));
    
            let thisStripePointer = this.stripe === undefined ? ".0" : ".1";
            let thisCardPointer = this.card === undefined ? ".0" : ".1";
    
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = e.message + " TU008" + thisStripePointer + thisCardPointer;
            this.showPopUpOk = true;
    
            let errormsg = e.message + " TU008" + thisStripePointer + thisCardPointer;
    
            try {
              this.card.update({}); // updating no options, just to see if it is still mounted
              console.log("this.card was still mounted during the cardInputConfirm error");
            } catch (e2) {
              if (e2.message.indexOf("is still mounted") > -1){
                console.log("ALERT! this.card was not mounted during the cardInputConfirm error");
                const params = {
                  toEmail: "appalert@thanku.app",
                  subject: "ALERT! this.card was not mounted during the cardInputConfirm error",
                  body: "Tipper " + this.tipper.objectId + " experienced the problem where this.card (the card element) was not mounted after user deleted a tip, error was " + errormsg + " TU008" + thisStripePointer + thisCardPointer,
                };
    
                Parse.Cloud.run("sendEmail", params);    
              }
            }
    
            return;
          }
                      
          
    
            
          // }
        },
        async cardInputCancel(){
    
          shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " CANCELLED on card input dialog " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
          
          console.log("AM I cancelling?");
    
          this.showCardInput = false;
    
          if (this.fromChangePaymentMethodButton === true){
            if (this.useRyft===true){
              console.log("just going back to Saved Card / Apple / Google Pay Confirm with Ryft");
              this.fromChangePaymentMethodButton = false; // reset flag
              this.doRyftPaymentPrepOperations();
            } else {
              this.fromChangePaymentMethodButton = false; // simple flag toggle
              this.processTokenPayment = true;
              this.showConfirmation = true;
              // we are just cancelling card input and going back to Apple Pay etc
              console.log("just going back to Apple / Google Pay with Stripe");
            }

          } else {
            
            // below was incomplete, best to rely on above for standard cancelled ops which includes hiding the recipient list
            // this.paymentCancelled = true;
            // this.goAgainMessage = "Start again";
            // this.setTipAgainVisible = true;
            const params = this.getPaymentRequestParams(undefined, undefined, this.lastButtonTapped, +(this.lastAmountChosen * 100).toFixed(0), "log-cancelled", "cancel button payment pay by new source", "cardInputCancel");    
            this.doShowCancelled(); // this needs to be AFTER getPaymentRequestParams because it blanks values which need to be logged which are collated in getPaymentRequestParams
            console.log("params at cancelling with CardInputCancel: " + JSON.stringify(params, null, 2));
            await this.runCloudInitiatePayment(params, "ok"); 
            console.log("cancelled with cardInputCancel!");
          }
          
    
        },
        changePaymentMethod(){
            shared.saveToUserPath(this.devEnv, "Tipper " + this.tipper.objectId + " tap change payment method button " + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            this.processTokenPayment = false; 

            if (this.useRyft === true){
              this.showConfirmation=false; 
              this.fromChangePaymentMethodButton = true; // otherwise cancelling out of card input will cancel the tip
              this.doRyftPaymentPrepOperations();
            } else {
              // this.blankButton(); // if you revert back you need the same details
              this.showConfirmation=false; 
              this.detectShowIfUKPlatformCardCountryUnknown(); 
              this.fromChangePaymentMethodButton = true; // otherwise cancelling out of card input will cancel the tip
              this.showCardInputForPayment(this.lastAmountChosen);
            }
        },
        async runCloudInitiatePayment(params, status){
    
          if (this.paymentInProgress === true){
            console.log("this.paymentInProgress is true");
            shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + "runCloudInitiatePayment status: " +  status  + " .. PAYMENT WAS ALREADY IN PROGRESS .. :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            return; //STOP
    
          } else {
            console.log("this.paymentInProgress was false has now been set to TRUE");
            this.paymentInProgress = true;
          }
    
          if (params.status === "token-log-cancelled"){ 
            this.popUpMsgTitle = "Ok";
            this.popUpMsgBody = "You can change payment method if you like, tap 'Change' at the bottom of the payment screen";
            this.showPopUpOk = true;
            if (this.wasDoingTokenInsteadMethod === true) {
              this.processTokenPayment = false;
              this.wasDoingTokenInsteadMethod = false; // this is to use as a flag only when the tipper tapped use [Apple/Google Pay] instead, so that if they decide to proceed with a saved card they can
            }
            shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " runCloudInitiatePayment status: " +  status  + " .. not proceeding .. :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
          } else if (params.status === "log-cancelled"){
            // do nothing, user has simply cancelled whichever popup they were on
            console.log("params.status was log-cancelled");
            shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " runCloudInitiatePayment status: " +  status  + " .. not proceeding .. :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
          } else if (status === "ryftCreatePaymentSession"){
            console.log("ryft paymentSession about to be created");  
          } else if (status !== "ok"){
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = status;
            this.showPopUpOk = true;
            shared.saveToUserPath(this.devEnv, "Tipper " + this.tipper.objectId + " runCloudInitiatePayment status: " +  status  + " .. not proceeding .. :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
          
          } else {
            console.log("condition was default else");
            this.showPaymentSubmitted();
            //whichever way it turns out we need to do this
            this.processTokenPayment = false;
            this.blankButton();
            // this.deployLogRocket(this.logRocketOrganisationSlug); // only deploy if there's no problem with the tip and it is proceeding
            // UPDATE: now trying to filter down so that LogRocket is only deploying if the same user is doing a second tip
          }
    
    
          var paymentIntentIdStr = "no pi_id";
    
          // /console.log("" + this.clientRequestId);
    
          // return;
    
          try {
    
            shared.saveToUserPath(this.devEnv, "Tipper " + this.tipper.objectId + " running initiateStripePaymentIntent: " +  JSON.stringify(params, null, 2)  + " :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            window.setTimeout(this.pollDBForResult, 4000); // see OneNote #pollDBForResult for rationale 

            if (this.useRyft === true && this.transactionOutcomeResultObject !== undefined){ // only on Ryft and only if not first time through when there won't yet be an object
              params = this.addPaymentObjectIdsToDeleteToParams(params, this.transactionOutcomeResultObject);
            }
    
            console.log("params just before initiate: " + JSON.stringify(params, null, 2));
    
            this.previousTipParams = params;
    
            const result = await Parse.Cloud.run("initiateStripePaymentIntent", params);
            // this.blankButton(); don't do this yet, it will get blanked when screen changes in any case
            console.log("initiateStripePaymentIntent did NOT return an error");
            
           
            
            // this.paymentInProgress = false; need to do this further down so that we can use it to catch phantom double calls resulting in errors like £0.30
    
            if (result.paymentIntentId !== undefined) {
              paymentIntentIdStr = result.paymentIntentId;
            }
    
            if (result.transactionStatus === "duplicate payment caught, fail silently") {
    
              // see OneNote #runCloudInitiatePayment duplicate payment caught, fail silently
    
              console.log("duplicate payment caught, fail silently");
    
              shared.saveToUserPath(this.devEnv, "Tipper " + this.tipper.objectId + " runCloudInitiatePayment .. duplicate payment caught, fail silently: " + " result " + JSON.stringify(result, null, 2) + " :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              this.paymentInProgress = false; console.log("this.paymentInProgress SET FALSE 1");
              window.localStorage.removeItem("fullThisDataSet");
              console.log("REMOVED fullThisDataSet at runCloudInitiatePayment duplicate payment");
    
              console.log("this.paymentInProgressWasSetToFalse");
    
              //fail silently 
    
            } else if (result.transactionStatus === "mustAuthorise"){ 
              console.log("must authorise");
              this.deployLogRocket(this.logRocketOrganisationSlug);
              shared.saveToUserPath(this.devEnv, "Tipper " + this.tipper.objectId + " runCloudInitiatePayment RESPONSE status: " +  result.transactionStatus  + " .. showing authorise screen for payment object(s): " + JSON.stringify(result.transactionOutcomeArray, null, 2) + " with (single) paymentIntent.id " + paymentIntentIdStr + " :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              window.localStorage.removeItem("fullThisDataSet");
              console.log("REMOVED fullThisDataSet at runCloudInitiatePayment mustAuthorise");
              this.doAuthorisePaymentIntent(result);
    
    
              // this.paymentInProgress = false;  this is after all not yet true
    
            } else if (result.transactionStatus === "ryftPaymentSessionCreated") {
    
              console.log("ryftPaymentSessionCreated");
              // console.log("ryftPaymentSessionCreated RESULT::: " + JSON.stringify(result, null, 2));
    
              this.transactionOutcomeResultObject = result;
              console.log("this.transactionOutcomeResultObject: " + JSON.stringify(this.transactionOutcomeResultObject, null, 2));
    
              // WE NEED TO PICK up all the transaction outcomes here and persist the object for use the handleclientside call
    
              this.paymentInProgress = false; console.log("this.paymentInProgress SET FALSE Ryft 1");
              window.localStorage.removeItem("fullThisDataSet");
              console.log("REMOVED fullThisDataSet at runCloudInitiatePayment ryftPaymentSessionCreated");
            
              shared.saveToUserPath(this.devEnv, "Tipper " + this.tipper.objectId + " runCloudInitiatePayment RESPONSE status: " +  result.transactionStatus  + " .. looking to do attemptPayment for payment object(s): " + JSON.stringify(result.transactionOutcomeArray, null, 2) + " with (single) paymentSession.id " + paymentIntentIdStr + " :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              this.paymentSessionClientSecret = result.paymentSessionClientSecret;
              this.ryftPaymentMethodsJSON = result.ryftPaymentMethodsJSON;
              // stopping here: we want to create the full paymentSession but this is before the user has actually tapped the pay button which will only operate on card payments, stored or otherwise, what we actually now need to do is Ryft.init to set up the form to be actionable with AP or GP
                           
              return "ok";
    
            } else {
              // this.deployLogRocket(this.logRocketOrganisationSlug); save monthly...
    
              // transaction succeeded;
    
              this.paymentInProgress = false; console.log("this.paymentInProgress SET FALSE 3");
              window.localStorage.removeItem("fullThisDataSet");
              console.log("REMOVED fullThisDataSet at runCloudInitiatePayment transaction succeeded");
    
              console.log("Tipper: " + this.tipper.objectId + " runCloudInitiatePayment RESPONSE is : " +  result.transactionStatus  + " .. showing result screen for transaction Id: " + result.paymentObjectId + " with paymentIntent.id " + paymentIntentIdStr  + " :: " + this.clientRequestId);
    
              shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " runCloudInitiatePayment RESPONSE is : " +  result.transactionStatus  + " .. showing result screen for transaction Id: " + result.paymentObjectId + " with paymentIntent.id " + paymentIntentIdStr  + " :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              this.showResult(result);
            }
            
          } catch (e) {
    
            console.log("WHOOAH! we have an error here,..,.");
            this.deployLogRocket(this.logRocketOrganisationSlug);
            
            this.blankButton();
    
    
            if (this.justAttemptedToCreateReusablePaymentSource === true){
              this.justAttemptedToCreateReusablePaymentSource = false;
              this.tipper.hasSavedPaymentSource = undefined; // clear session flag as there may well NOT be a saved source on the back end
              this.tipper.last4Digits = ""; // ditto
            }
    
            this.popUpMsgTitle = "Sorry";
    
          
            var transactionStatus = "no transactionStatus returned";
            var paymentObjectId = "no paymentObjectId returned";
            var errorMessage = "no error message";
            var errorCode = "no error code";
    
            if (e.message.message !== undefined){
    
              errorMessage = e.message.message;
              errorCode = e.message.code;
    
              if (e.message.transactionStatus !== undefined) {
                transactionStatus = e.message.transactionStatus;
              }
              if (e.message.paymentObjectId !== undefined) {
                paymentObjectId = e.message.paymentObjectId;
              }
              if (e.message.paymentIntentId !== undefined) {
                paymentIntentIdStr = e.message.paymentIntentId;
              }
            } else {
              // it is not a 'caught' message so we just have the basic error object
              errorMessage = "UNCAUGHT: " + e.message;
              errorCode = e.code;
    
              if (errorMessage.indexOf("Transfers using this transaction as a source must not exceed the source amount") > -1){
                const params = {
                toEmail: "appalert@thanku.app",
                subject: "ALERT! NEED TO REFUND: " + errorMessage,
                body: "ALERT! NEED TO REFUND: " + errorMessage,
                };
    
                Parse.Cloud.run("sendEmail", params);
              }
            }
    
            if (errorCode === "testmode_charges_only") {
              this.popUpMsgBody = "Your payment could not be set up because the person you are tipping needs to supply verification information on their account - you have not been charged";
            } else {
              
              this.popUpMsgBody = "Your payment could not be set up - please contact payments@thanku.app with the following message: " + errorMessage + " TU005. You have not been charged."; // e.message is an object
              console.log("SET UP FAILED TU005:: " + errorMessage);
            }
    
            // shared.saveToUserPath(this.devEnv, "PAYMENT ERROR Tipper: " + this.tipper.objectId + " runCloudInitiatePayment ERROR RESPONSE is : " + transactionStatus  + " error message is: " + errorMessage + " error code is: " + errorCode + " .. showing result screen for transaction Id: " + paymentObjectId + " with paymentIntent.id " + paymentIntentIdStr + " :: " + this.clientRequestId + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
                    // 'Amount must be at least £0.30 gbp' - here is the flag variable that if the payment has already succeeded then this is ignored except to send a system alert
    
            if (this.paymentInProgress === false) {
              // this transaction already completed successfully this must be a phantom second call back e.g. the £0.30
                let tipperId = "no tipper id";
                if (this.tipper.objectId !== undefined) {
                tipperId = this.tipper.objectId;
                }
                const params = {
                toEmail: "appalert@thanku.app",
                subject: "PHANTOM ALERT! : " + errorMessage,
                body: "Likely to be a phantom double call return of runCloudInitiatePayment, tipper id is: " + tipperId + "  error is: " + errorMessage + "  code: " + errorCode + " .. no message was shown to user, here are the variables, transactionStatus: " + transactionStatus + "  paymentObjectId: " + paymentObjectId + "   ",
                };
                Parse.Cloud.run("sendEmail", params);
            } else {
              this.paymentInProgress = false; console.log("this.paymentInProgress SET FALSE 8");
              window.localStorage.removeItem("fullThisDataSet");
              console.log("REMOVED fullThisDataSet at runCloudInitiatePayment error");
              this.showPopUpOk = true;
              this.doShowCancelled();
            }
    
            return;
            
          }
        },
        addPaymentObjectIdsToDeleteToParams(params, transactionOutcomeResultObject){
          try {
            // Ensure the params object has the new property, initializing as an empty array
            if (!params.paymentObjectIdsToDelete) {
              params.paymentObjectIdsToDelete = [];
            }

            // Loop through each item in the transactionOutcomeArray
            transactionOutcomeResultObject.transactionOutcomeArray.forEach(item => {
              // Push the paymentObjectId to the paymentObjectIdsToDelete array in params
              params.paymentObjectIdsToDelete.push(item.paymentObjectId);
            });
          } catch (error) {
            // Handle errors, for example, log them or throw them further
            console.error('Failed to process payment object IDs:', error);
            throw error; // Optional: rethrow if you want calling code to handle it as well
          }

          // Return the updated params object
          console.log("PARAMS IS NOW: " + JSON.stringify(params, null, 2));
          return params;
        },
        doEpsilon(){
          if (this.paymentConfirmed === true) {
            this.epsilon = "";
          } else {
            if (this.epsilon === "...") {
              this.epsilon = "";
            } else {
              this.epsilon += "."
            }
            setTimeout(this.doEpsilon, 700);
          }
        },
        async doAuthorisePaymentIntent(result){
    
          console.log("MUST AUTHORISE!");
          // either which way, this payment attempt, successful or not has completed, so we need to set this.paymentInProgress back to FALSE
          this.paymentInProgress = false; console.log("this.paymentInProgress on doAuthorisePaymentIntent was set to false!");
          window.localStorage.removeItem("fullThisDataSet");
          console.log("REMOVED fullThisDataSet at runCloudInitiatePayment doAuthorisePaymentIntent");
    
          let wentMultiTip = false;
          
          try {
            // alert("stopping here");
    
            // if (this.usingMultiTips === true){
            //   alert("Success on MULTITIP! But stopping just here thank you!");
            //   return;
            // } else {
            //   alert("Success on ORIGINAL! But stopping just here thank you!");
            //   return;
            // }
    
            this.paymentAuthenticating = true;
            this.doEpsilon();
            this.paymentConfirmed = false;  
    
            const clientSecret = result.paymentIntentClientSecret;
            // const paymentIntentId = result.paymentIntentId;
    
            const intentConfirm = await this.stripe.confirmCardPayment(clientSecret);
    
            let params;
    
            if (intentConfirm.error !== undefined) {
    
              this.deployLogRocket(this.logRocketOrganisationSlug);
    
              this.loggingError = true;
    
              shared.saveToUserPath(this.devEnv, "AUTHORISATION FAILED Tipper: " + this.tipper.objectId + " result from backend which required authorisation: " + JSON.stringify(result, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              if (this.justAttemptedToCreateReusablePaymentSource !== undefined && this.justAttemptedToCreateReusablePaymentSource === true){
                this.justAttemptedToCreateReusablePaymentSource = false;
                this.tipper.hasSavedPaymentSource = undefined; // clear session flag as there may well NOT be a saved source on the back end
                this.tipper.last4Digits = ""; // ditto
              }
    
              this.popUpMsgTitle = "Sorry";
    
              if (intentConfirm.error.code === "payment_intent_authentication_failure") {
                this.popUpMsgBody = intentConfirm.error.message;
              } else {
                this.popUpMsgBody = "Your payment could not be set up - please contact payments@thanku.app with the following message: " + intentConfirm.error.message + " TU006. You have not been charged.";
                console.log("intentConfirm failed TU006: " + intentConfirm.error.message);
              }
              this.showPopUpOk = true;
              this.doShowCancelled();
              // this.showCardInputForPayment(); // not absolutely sure what this is doing
              // console.log("intentConfirm FAILED::: " + JSON.stringify(intentConfirm, null, 2));
    
    
              if (this.usingMultiTips === true && result.isMultipleTipsStructure === true){
    
                wentMultiTip = true;
    
                params = {};
                let paymentsDataArray = [];
    
                for (let i = 0; i < result.transactionOutcomeArray.length; i++){
                  let paramsItem = {
                    paymentObjectId: result.transactionOutcomeArray[i].paymentObjectId,
                    emailParams: result.transactionOutcomeArray[i].emailParams,
                  }
                  paymentsDataArray.push(paramsItem);
                }
    
                params.paymentsDataArray = paymentsDataArray;
                params.status = "failed";
                params.paymentIntentId = result.paymentIntentId;
                params.paymentIntentClientSecret = result.paymentIntentClientSecret;
                params.clientRequestId = this.clientRequestId;
                params.isMultipleTipsStructure = true;
    
    
                // console.log("params FAILEDINTENTSTATUS with ARRAY: " + JSON.stringify(params, null, 2));
    
              } else {
                params = {
                  status: "failed",
                  paymentObjectId: result.paymentObjectId,
                  paymentIntentId: result.paymentIntentId,
                  paymentIntentClientSecret: result.paymentIntentClientSecret,
                  emailParams: result.emailParams,
                  clientRequestId: this.clientRequestId,
                }
              }
    
    
              shared.saveToUserPath(this.devEnv, "AUTHORISATION FAILED Tipper: " + this.tipper.objectId + " doAuthorisePaymentIntent: " + JSON.stringify(params, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              // so I THINK the problem is: for some reason when mustauthorise result is returned its not going through the multitip path above, therefore it is not reading a paymentObjectId which is otherwise in reality nested in an array of ids, and so when we go to handleClientSideConfirmStripePaymentIntent below, the backend can't find the paymendId provided because it is undefined. ACTUALLY no I've just worked it out, handleClientSideConfirmStripePaymentIntent was failing because params above for failure was not passing back params.isMultipleTipsStructure = true; meaning handleClientSideConfirmStripePaymentIntent didn't recognise to look for a nested paymentObjectId - and none of this would have been obvious except for the fact that it looks like I typed this.showCardInputForPayment() instead of this.doShowCancelled(); when I originally put this function together meaning it didn't show cancelled the tipper had the opportunity to resubmit on the same failed transaction and hence we get the must be at least £0.30p message
    
    
              // let emailParams = {
              //   toEmail: "appalert@thanku.app",
              //   subject: "ALERT! AUTHORISE FAIL CONDITION: go check userPath email for tipper id: " + this.tipper.objectId + " wentMultiTip IS " + wentMultiTip + " ...LIKELY missing paymendId",
              //   body: "ALERT! AUTHORISE FAIL CONDITION: go check userPath email for tipper id: " + this.tipper.objectId + " wentMultiTip IS " + wentMultiTip + " ...LIKELY missing paymendId, params are: " + JSON.stringify(params, null, 2) + " LOOK OUT FOR MISSING result.isMultipleTipsStructure in result: " + JSON.stringify(result, null, 2),
              //   };
    
              // Parse.Cloud.run("sendEmail", emailParams);
    
              const confirmLogResult = await Parse.Cloud.run("handleClientSideConfirmStripePaymentIntent", params);
    
              shared.saveToUserPath(this.devEnv, "AUTHORISATION FAILED Tipper: " + this.tipper.objectId + " confirmLogResult: " + JSON.stringify(confirmLogResult, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              if (JSON.stringify(confirmLogResult, null, 2).indexOf("ERROR in handleClientSideConfirmStripePaymentIntent: ") > -1){
                emailParams = {
                  toEmail: "appalert@thanku.app",
                  subject: "ALERT! AUTHORISE FAIL CONDITION: the result for tipper ID  " + this.tipper.objectId + " handleClientSideConfirmStripePaymentIntent is as follows...",
                  body: JSON.stringify(confirmLogResult, null, 2)
                  };
    
                Parse.Cloud.run("sendEmail", emailParams);
                // console.log("confirmLogResult::: " + JSON.stringify(confirmLogResult, null, 2));
              }
              this.clientRequestId = "";
              return;
            } else {
              // authorisation succeeded
              // console.log("intentConfirm SUCCEEDED::: " + JSON.stringify(intentConfirm, null, 2));
    
              // console.log("this.usingMultiTips " + this.usingMultiTips + "  result.isMultipleTipsStructure " + result.isMultipleTipsStructure);
    
              if (this.usingMultiTips === true && result.isMultipleTipsStructure === true){
    
                // console.log("this.usingMultiTips === true && result.isMultipleTipsStructure === true");
    
                params = {};
                let paymentsDataArray = [];
    
                for (let i = 0; i < result.transactionOutcomeArray.length; i++){
                  let paramsItem = {
                    paymentObjectId: result.transactionOutcomeArray[i].paymentObjectId,
                    emailParams: result.transactionOutcomeArray[i].emailParams,
                  }
                  paymentsDataArray.push(paramsItem);
                }
    
                params.paymentsDataArray = paymentsDataArray;
                params.status = "succeeded";
                params.paymentIntentId = result.paymentIntentId;
                params.paymentIntentClientSecret = result.paymentIntentClientSecret;
                params.clientRequestId = this.clientRequestId;
                params.isMultipleTipsStructure = true;
    
                // console.log("params SUCCEEDEDINTENTSTATUS with ARRAY: " + JSON.stringify(params, null, 2));
    
              } else {
                // console.log("just the oner");
                params = {
                  status: "succeeded",
                  paymentObjectId: result.paymentObjectId,
                  paymentIntentId: result.paymentIntentId,
                  paymentIntentClientSecret: result.paymentIntentClientSecret,
                  emailParams: result.emailParams,
                  clientRequestId: this.clientRequestId,
                }
              }
    
              shared.saveToUserPath(this.devEnv, "AUTHORISATION SUCCEEDED Tipper: " + this.tipper.objectId + " doAuthorisePaymentIntent: " + JSON.stringify(params, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              // console.log("about to do confirmLogResult");
    
              const confirmLogResult = await Parse.Cloud.run("handleClientSideConfirmStripePaymentIntent", params);
    
              this.loggingError = true; // no error but we want to keep an eye on this problematic area of code
    
              shared.saveToUserPath(this.devEnv, "AUTHORISATION Succeeded Tipper: " + this.tipper.objectId + " confirmLogResult: " + JSON.stringify(confirmLogResult, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              // console.log("DID confirmLogResult");
    
              // console.log("confirmLogResult::: " + JSON.stringify(confirmLogResult, null, 2));
    
              result.transactionStatus = "succeeded";
              this.showResult(result);
              this.clientRequestId = "";
              return;
            }
    
    
    
            
            // this.mustAuthorise = true;
            // this.mustAuthoriseURL = result.mustAuthoriseURL;
          } catch (e) {
            console.log("ERROR in doAuthorisePaymentIntent: " + e.message);
            this.deployLogRocket(this.logRocketOrganisationSlug);
            this.blankButton();
    
            this.paymentInProgress = false; console.log("this.paymentInProgress SET FALSE 4");
            window.localStorage.removeItem("fullThisDataSet");
            console.log("REMOVED fullThisDataSet at doAuthorisePaymentIntent error");
    
            if (this.justAttemptedToCreateReusablePaymentSource === true){
              this.justAttemptedToCreateReusablePaymentSource = false;
              this.tipper.hasSavedPaymentSource = undefined; // clear session flag as there may well NOT be a saved source on the back end
              this.tipper.last4Digits = ""; // ditto
            }
    
            this.popUpMsgTitle = "Sorry";
    
          
            var transactionStatus = "no transactionStatus returned";
            var paymentObjectId = "no paymentObjectId returned";
            var errorMessage = "";
            var errorCode = "";
    
            if (e.message.message !== undefined){
    
              errorMessage = e.message.message;
              errorCode = e.message.code;
    
              if (e.message.transactionStatus !== undefined) {
                transactionStatus = e.message.transactionStatus;
              }
              if (e.message.paymentObjectId !== undefined) {
                paymentObjectId = e.message.paymentObjectId;
              }
              if (e.message.paymentIntentId !== undefined) {
                paymentIntentIdStr = e.message.paymentIntentId;
              }
            } else {
              // it is not a 'caught' message so we just have the basic error object
              errorMessage = "UNCAUGHT: " + e.message;
              errorCode = e.code;
    
              if (errorMessage.indexOf("Transfers using this transaction as a source must not exceed the source amount") > -1){
                const params = {
                toEmail: "appalert@thanku.app",
                subject: "ALERT! NEED TO REFUND: " + errorMessage,
                body: "ALERT! NEED TO REFUND: " + errorMessage,
                };
    
                Parse.Cloud.run("sendEmail", params);
              }
            }
    
            if (errorCode === "testmode_charges_only") {
              this.popUpMsgBody = "Your payment could not be set up because the person you are tipping needs to supply verification information on their account - you have not been charged";
            } else {
              // console.log("SET UP FAILED:: " + JSON.stringify(e, null, 2));
              this.popUpMsgBody = "Your payment could not be set up - please contact payments@thanku.app with the following message: " + errorMessage + " TU007. You have not been charged."; // e.message is an object
              console.log("doAuthorisePaymentIntent failed TU007: " + errorMessage);
            }
    
          
            this.showPopUpOk = true;
            this.doShowCancelled();
    
            this.loggingError = true;
            
            shared.saveToUserPath(this.devEnv, "PAYMENT ERROR Tipper: " + this.tipper.objectId + " runCloudInitiatePayment ERROR RESPONSE is : " + transactionStatus  + " error message is: " + errorMessage + " error code is: " + errorCode + " .. showing result screen for transaction Id: " + paymentObjectId + " with paymentIntent.id " + paymentIntentIdStr + " :: " + this.clientRequestId + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            
            return;
          }
    
        },
        async doRyftAttemptPayment(){
    
          console.log("Ryft attempting payment...");
    
          let emailParams;
    
          // either which way, this payment attempt, successful or not has completed, so we need to set this.paymentInProgress back to FALSE
          this.paymentInProgress = false; console.log("this.paymentInProgress on doRyftAttemptPayment was set to false!");
          window.localStorage.removeItem("fullThisDataSet");
          console.log("REMOVED fullThisDataSet at doRyftAttemptPayment");
    
          let wentMultiTip = false;
          
          try {
            // alert("stopping here");
    
            // if (this.key === true){
            //   alert("Success on MULTITIP! But stopping just here thank you!");
            //   return;
            // } else {
            //   alert("Success on ORIGINAL! But stopping just here thank you!");
            //   return;
            // }
    
            this.paymentAuthenticating = true;
            this.doEpsilon();
            this.paymentConfirmed = false;  
    
            const paymentSessionClientSecret = this.transactionOutcomeResultObject.paymentSessionClientSecret;
            console.log("clientSecret:::: " + paymentSessionClientSecret);
            const attemptPaymentRequest = {
              clientSecret: paymentSessionClientSecret,
            //   paymentMethod: {
            //   "id": "pmt_01JEKYXV86RNSGTHCC06G1K56C",
            //   "type": "Card",
            //   "card": {
            //     "scheme": "Mastercard",
            //     "last4": "2932",
            //     "expiryMonth": "03",
            //     "expiryYear": "2027"
            //   },
            //   "customerId": "cus_01JEKVJAM1ZJQX8TEN6HAJH462",
            //   "createdTimestamp": 1733689273
            // },
            };
            console.log("attemptPaymentRequest is: " + JSON.stringify(attemptPaymentRequest, null, 2));
    
            let ryftError;
            let paymentSession;
            
            try {
              // Assuming Ryft.attemptPayment is available in this context
              paymentSession = await Ryft.attemptPayment(attemptPaymentRequest);
              console.log("attemptPayment returned");
    
              emailParams = {
                  toEmail: "appalert@thanku.app",
                  subject: "Completed! Ryft.attemptPayment paymentSession",
                  body: JSON.stringify(paymentSession, null, 2)
                  };
    
                Parse.Cloud.run("sendEmail", emailParams);
    
              if (paymentSession.status === "Approved" || paymentSession.status === "Captured") {
                // Payment successful - possibly redirect or update the UI
                console.log("paymentSession.status: " + paymentSession.status);
                // return; // don't know why this was here, we need to go on...
                console.log("paymentSession ALL: " + JSON.stringify(paymentSession, null, 2));
    
                if (paymentSession.paymentMethod !== undefined && paymentSession.paymentMethod.tokenizedDetails !== undefined){
                  this.transactionOutcomeResultObject.ryftCustomerPaymentMethodId = paymentSession.paymentMethod.tokenizedDetails.id;
                }
    
              }
    
              if (paymentSession.lastError) {
                const userFacingError = Ryft.getUserFacingErrorMessage(paymentSession.lastError);
                // Show userFacingError to customer
                console.log("paymentSession.lastError: " + userFacingError);
              }
            } catch (error) {
              // Show error to customer)
              console.log("ERROR in Ryft.attemptPayment: " + error);
              ryftError = error;
            }
    
            if (ryftError !== undefined) {
    
              this.doProcessRyftError(ryftError, paymentSession);
    
            } else {
              
              this.doProcessRyftSucceeded(paymentSession);
    
            }
    
          } catch (e) {
            console.log("ERROR in doRyftAttemptPayment: " + e.message);
            this.deployLogRocket(this.logRocketOrganisationSlug);
            this.blankButton();
    
            this.paymentInProgress = false; console.log("this.paymentInProgress SET FALSE 4");
            window.localStorage.removeItem("fullThisDataSet");
            console.log("REMOVED fullThisDataSet at doRyftAttemptPayment error");
    
            if (this.justAttemptedToCreateReusablePaymentSource === true){
              this.justAttemptedToCreateReusablePaymentSource = false;
              this.tipper.hasSavedPaymentSource = undefined; // clear session flag as there may well NOT be a saved source on the back end
              this.tipper.last4Digits = ""; // ditto
            }
    
            this.popUpMsgTitle = "Sorry";
    
          
            var transactionStatus = "no transactionStatus returned";
            var paymentObjectId = "no paymentObjectId returned";
            var errorMessage = "";
            var errorCode = "";
    
            if (e.message.message !== undefined){
    
              errorMessage = e.message.message;
              errorCode = e.message.code;
    
              if (e.message.transactionStatus !== undefined) {
                transactionStatus = e.message.transactionStatus;
              }
              if (e.message.paymentObjectId !== undefined) {
                paymentObjectId = e.message.paymentObjectId;
              }
              if (e.message.paymentIntentId !== undefined) {
                paymentIntentIdStr = e.message.paymentIntentId;
              }
            } else {
              // it is not a 'caught' message so we just have the basic error object
              errorMessage = "UNCAUGHT: " + e.message;
              errorCode = e.code;
    
              if (errorMessage.indexOf("Transfers using this transaction as a source must not exceed the source amount") > -1){
                const params = {
                toEmail: "appalert@thanku.app",
                subject: "ALERT! NEED TO REFUND: " + errorMessage,
                body: "ALERT! NEED TO REFUND: " + errorMessage,
                };
    
                Parse.Cloud.run("sendEmail", params);
              }
            }
    
            if (errorCode === "testmode_charges_only") {
              this.popUpMsgBody = "Your payment could not be set up because the person you are tipping needs to supply verification information on their account - you have not been charged";
            } else {
              // console.log("SET UP FAILED:: " + JSON.stringify(e, null, 2));
              this.popUpMsgBody = "Your payment could not be set up - please contact payments@thanku.app with the following message: " + errorMessage + " TU010. You have not been charged."; // e.message is an object
              console.log("doAuthorisePaymentIntent failed TU010: " + errorMessage);
            }
    
          
            this.showPopUpOk = true;
            this.doShowCancelled();
    
            this.loggingError = true;
            
            shared.saveToUserPath(this.devEnv, "PAYMENT ERROR Tipper: " + this.tipper.objectId + " runCloudInitiatePayment ERROR RESPONSE is : " + transactionStatus  + " error message is: " + errorMessage + " error code is: " + errorCode + " .. showing result screen for transaction Id: " + paymentObjectId + " with paymentIntent.id " + paymentIntentIdStr + " :: " + this.clientRequestId + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            
            return;
          }
    
        },
        async doProcessRyftError(ryftError, paymentSession){
    
          let params; 
    
          try {
              
              this.deployLogRocket(this.logRocketOrganisationSlug);
    
              this.loggingError = true;
    
              shared.saveToUserPath(this.devEnv, "Ryft.attemptPayment FAILED Tipper: " + this.tipper.objectId + " result from backend which required authorisation: " + JSON.stringify(paymentSession, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              if (this.justAttemptedToCreateReusablePaymentSource !== undefined && this.justAttemptedToCreateReusablePaymentSource === true){
                this.justAttemptedToCreateReusablePaymentSource = false;
                this.tipper.hasSavedPaymentSource = undefined; // clear session flag as there may well NOT be a saved source on the back end
                this.tipper.last4Digits = ""; // ditto
              }
    
              this.popUpMsgTitle = "Sorry";
    
              // if (intentConfirm.error.code === "payment_intent_authentication_failure") {
              //   this.popUpMsgBody = intentConfirm.error.message;
              // } else {
                this.popUpMsgBody = "Your payment could not be set up - please contact payments@thanku.app with the following message: " + ryftError + " TU009. You have not been charged.";
                console.log("Ryft.attemptPayment failed TU009: " + ryftError);
              // }
              this.showPopUpOk = true;
              this.doShowCancelled();
              // this.showCardInputForPayment(); // not absolutely sure what this is doing
              // console.log("intentConfirm FAILED::: " + JSON.stringify(intentConfirm, null, 2));
    
              let result = this.transactionOutcomeResultObject; // pick this up from previously
    
              if (this.usingMultiTips === true && result.isMultipleTipsStructure === true){
    
                wentMultiTip = true;
    
                params = {};
                let paymentsDataArray = [];
    
                for (let i = 0; i < result.transactionOutcomeArray.length; i++){
                  let paramsItem = {
                    paymentObjectId: result.transactionOutcomeArray[i].paymentObjectId,
                    emailParams: result.transactionOutcomeArray[i].emailParams,
                  }
                  paymentsDataArray.push(paramsItem);
                }
    
                params.paymentsDataArray = paymentsDataArray;
                params.status = "failed";
                params.paymentIntentId = result.paymentIntentId;
                params.paymentIntentClientSecret = result.paymentIntentClientSecret;
                params.clientRequestId = this.clientRequestId;
                params.isMultipleTipsStructure = true;
                params.useRyft = this.useRyft;

                params = this.addDetailsFromPaymentSessionAndUpdateLocalTipper(params, paymentSession);
    
    
                // console.log("params FAILEDINTENTSTATUS with ARRAY: " + JSON.stringify(params, null, 2));
    
              } else {
                params = {
                  status: "failed",
                  paymentObjectId: result.paymentObjectId,
                  paymentIntentId: result.paymentIntentId,
                  paymentIntentClientSecret: result.paymentIntentClientSecret,
                  emailParams: result.emailParams,
                  clientRequestId: this.clientRequestId,
                  useRyft: this.useRyft,
                }

                params = this.addDetailsFromPaymentSessionAndUpdateLocalTipper(params, paymentSession);

              }
    
    
              shared.saveToUserPath(this.devEnv, "Ryft.attemptPayment FAILED Tipper: " + this.tipper.objectId + " doRyftAttemptPayment: " + JSON.stringify(params, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
    
              const confirmLogResult = await Parse.Cloud.run("handleClientSideConfirmStripePaymentIntent", params);
    
              shared.saveToUserPath(this.devEnv, "doRyftAttemptPayment FAILED Tipper: " + this.tipper.objectId + " confirmLogResult: " + JSON.stringify(confirmLogResult, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            
              this.clientRequestId = "";
              return;
          } catch (e) {
            console.log("ERROR in doProcessRyftError: " + e.message);
            throw e;
          }
        },
        async doProcessRyftSucceeded(paymentSession){
    
          let params;
    
          try {
            this.showPaymentSubmitted(); 
            //whichever way it turns out we need to do this
            this.processTokenPayment = false;
            this.blankButton();

            if (this.useRyft === true){
              this.showMainFinishScreen("succeeded"); // so as not to delay - we could think of putting in some dots instead? 
            }
            let result = this.transactionOutcomeResultObject; // pick this up from previously
  
            if (this.usingMultiTips === true && result.isMultipleTipsStructure === true){
  
              params = {};
              let paymentsDataArray = [];
  
              for (let i = 0; i < result.transactionOutcomeArray.length; i++){
                let paramsItem = {
                  paymentObjectId: result.transactionOutcomeArray[i].paymentObjectId,
                  emailParams: result.transactionOutcomeArray[i].emailParams,
                }
                paymentsDataArray.push(paramsItem);
              }
  
              params.paymentsDataArray = paymentsDataArray;
              params.status = "succeeded";
              params.paymentIntentId = result.paymentIntentId;
              params.paymentIntentClientSecret = result.paymentIntentClientSecret;
              params.clientRequestId = this.clientRequestId;
              params.isMultipleTipsStructure = true;
              params.useRyft = this.useRyft;
              params = this.addDetailsFromPaymentSessionAndUpdateLocalTipper(params, paymentSession);
              
              // console.log("params SUCCEEDEDINTENTSTATUS with ARRAY: " + JSON.stringify(params, null, 2));
  
            } else {
              // console.log("just the oner");
  
              let ryftCustomerPaymentMethodId;
              if (result.ryftCustomerPaymentMethodId !== undefined){
                ryftCustomerPaymentMethodId = result.ryftCustomerPaymentMethodId;
              }
  
              params = {
                status: "succeeded",
                paymentObjectId: result.paymentObjectId,
                paymentIntentId: result.paymentIntentId,
                paymentIntentClientSecret: result.paymentIntentClientSecret,
                emailParams: result.emailParams,
                clientRequestId: this.clientRequestId,
                useRyft: this.useRyft,
                ryftCustomerPaymentMethodId: ryftCustomerPaymentMethodId,
              }

              params = this.addDetailsFromPaymentSessionAndUpdateLocalTipper(params, paymentSession);
            }
  
            shared.saveToUserPath(this.devEnv, "Ryft.attemptPayment SUCCEEDED Tipper: " + this.tipper.objectId + " doRyftAttemptPayment: " + JSON.stringify(params, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
  
            // console.log("about to do confirmLogResult");
  
            const confirmLogResult = await Parse.Cloud.run("handleClientSideConfirmStripePaymentIntent", params);
  
            this.loggingError = true; // no error but we want to keep an eye on this problematic area of code
  
            shared.saveToUserPath(this.devEnv, "Ryft.attemptPayment Succeeded Tipper: " + this.tipper.objectId + " confirmLogResult: " + JSON.stringify(confirmLogResult, null, 2) + " "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
  
            // console.log("DID confirmLogResult");
  
            // console.log("confirmLogResult::: " + JSON.stringify(confirmLogResult, null, 2));

            result.transactionStatus = "succeeded";

            this.showResult(result);

            this.clientRequestId = "";
            return;
  
        } catch (e) {
            console.log("ERROR in doProcessRyftSucceeded: " + e.message);
            throw e;
          }
        },
        addDetailsFromPaymentSessionAndUpdateLocalTipper(params, paymentSession){

          // realised we don't need to add these to params since we get all the paymentSession details from the backend

          // BUT the valuable thing it does is update the local user if a source has been saved, see below
          try {
            if (paymentSession.paymentMethod !== undefined){
              // if (paymentSession.paymentMethod.wallet !== undefined){
              //   if (paymentSession.paymentMethod.wallet.type.indexOf("Apple") > -1){
              //     params.paymentMethodType = "AP";
              //   } else if (paymentSession.paymentMethod.wallet.type.indexOf("Google") > -1){
              //     params.paymentMethodType = "GP";
              //   }
              // } else {
              //   // it's a card; could be existing stored, or new stored, you can't tell (well could becomes one comes from handleWalletPaymentSessionResult but no matter..)
              //   if (paymentSession.paymentMethod.tokenizedDetails !== undefined){
              //     params.paymentMethod = "STCC";
              //     params.usage = paymentSession.paymentMethod.tokenizedDetails.stored === true ? "reusable" : "single_use";
              //   }
              // }
              // if (paymentSession.paymentMethod.card !== undefined){
              //   params.cardBrand = paymentSession.paymentMethod.card.scheme;
              //   params.last4Digits = paymentSession.paymentMethod.card.last4;
              // }

              // if (params.usage === "reusable") { // it might be existing or new, doesn't matter
              if (paymentSession.paymentMethod.tokenizedDetails.stored === true){
                this.tipper.hasSavedPaymentSource = true; // set this as a session flag even though it will be saved for next time in the backend
                this.tipper.last4Digits = params.cardLast4;// set this as a session value even though it will be saved for next time in the backend
                this.tipper.cardBrand = params.cardBrand; // not sure we are actually using this but...
                this.tipper.paymentMethod = "STCC"; // for the session, in case they do another, before it's picked up next time they come to thankU
                this.payingByApplePay = false;
                this.payingByGooglePay = false;
              }

            }
            
            return params;
          } catch (e) {
            console.log("ERROR in addDetailsFromPaymentSessionAndUpdateLocalTipper: " + e.message);
            Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ERROR in addDetailsFromPaymentSessionAndUpdateLocalTipper: " + e.message, body: "Error: " + e.message + "<br/>params: " + JSON.stringify(params, null, 2) + "<br/><br/>paymentSession:" + JSON.stringify(paymentSession, null, 2), });  
            return params; // don't throw?
          }
        },
        async pollDBForResult(){
    
    
          // console.log("polling DB");
    
          if (window.navigator.onLine === false) {
             // no point polling whilst we are offline, but we want to resume as soon as we are back on
             window.setTimeout(this.pollDBForResult, 500); // see OneNote for rationale 
          }
    
          if (this.paymentInProgress === false ) {
            //no need
            return;
          }
    
          var paymentIntentIdStr = "no paymentIntend Id"
    
          let params = {
            ownerObjectId: this.tipper.objectId,
            clientRequestId: this.clientRequestId,
          }
    
          shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " pollDBForResult ABOUT TO POLL this.clientRequestId: " +  this.clientRequestId  + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          const result = await Parse.Cloud.run("pollDBForResult", params);
    
          shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " pollDBForResult SUCCESSFULLY POLLED this.clientRequestId: " +  this.clientRequestId  + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          if (result.transactionStatus === "succeeded"){
            
            this.paymentInProgress = false; console.log("this.paymentInProgress SET FALSE 2");
            window.localStorage.removeItem("fullThisDataSet");
            console.log("REMOVED fullThisDataSet at pollDBForResult");
    
            // need a failsafe to stop new transaction occuring?
    
            if (result.paymentIntentId !== undefined) {
              paymentIntentIdStr = result.paymentIntentId;
            }
    
            const userPath = window.localStorage.getItem("tuuserpath") !== null ? window.localStorage.getItem("tuuserpath") : "no userPath";
    
            // NB no longer sending this but NOTE! IT happens on many transactions throughout the day so this polling function remains critical, probably slow internet speeds
            // const params = {
            //   toEmail: "appalert@thanku.app",
            //   subject: "ALERT poll SUCCEEDED for user " + this.tipper.objectId + " " + new Date().toISOString(),
            //   body: "CHECK Poll Success TRANSACTION! Result was " + JSON.stringify(result, null, 2) + " userPath " + userPath,
            // };
    
            // Parse.Cloud.run("sendEmail", params);
    
            //SEE backend notes as to why
            // if (result.transactionStatus === "mustAuthorise"){
            //   shared.saveToUserPath(this.devEnv, "Tipper " + this.tipper.objectId + " runCloudInitiatePayment RESPONSE status: " +  result.transactionStatus  + " .. showing authorise screen for transaction Id: " + result.paymentObjectId + " with paymentIntent.id " + paymentIntentIdStr + " :: " + this.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            //   this.doAuthorisePaymentIntent(result);
    
            // } else {
              
              
              this.showResult(result);
            // }
           }
    
           shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " runCloudInitiatePayment POLLING   RESPONSE is : " +  result.transactionStatus  + " paymentObject.id: " + result.paymentObjectId + " with paymentIntent.id " + paymentIntentIdStr  + " :: " + result.clientRequestId + " :: " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
           if (this.paymentInProgress === true) {
             this.pollCount += 1;
    
             if (this.pollCount === 10) {
               // fail the transaction
               this.pollCount = 0;
    
               //the transaction almost certainly succeeded but we lost network so no longer do the below
    
              //  this.doShowCancelled();
    
              //  const userPath = window.localStorage.getItem("tuuserpath") !== null ? window.localStorage.getItem("tuuserpath") : "no userPath";
    
              //  const params = {
              //     toEmail: "appalert@thanku.app",
              //     subject: "ALERT poll timed out for user " + this.tipper.objectId + " " + new Date().toISOString(),
              //     body: "CHECK TRANSACTION! Result was " + JSON.stringify(result, null, 2) + " userPath " + userPath,
              //   };
    
              //  Parse.Cloud.run("sendEmail", params);
             } else {
               // poll again
               window.setTimeout(this.pollDBForResult, 3000); // see OneNote for rationale 
             }
    
           } else {
             this.pollCount = 0;
           }
    
        },
        checkForNetworkConnection(){
    
          var timeOut= 1000;
    
          if (window.navigator.onLine){
            this.transactionInterruptedMsgWasShownOnceAlready = false; // reset it
            this.showNetworkPopUp = false;
          } else {
            // console.log("OFFLINE!");
            if (this.paymentInProgress === true){
              if (this.transactionInterruptedMsgWasShownOnceAlready === false) {
                timeOut = 9000; // make sure it is on screen long enough
              } else {
                timeOut = 1000;
              }
              
              this.transactionInterruptedMsg = "Your tip has completed but ThankU can't confirm it yet because your device lost its internet connection. If you have registered an email with ThankU, please check your email for confirmation the tip was successful.";
            } else {
              this.transactionInterruptedMsg = "";
            }
            this.showNetworkPopUp = true;
          }
          window.setTimeout(this.checkForNetworkConnection, timeOut); 
        },
        updateSaveSecurelyCheckboxValue(){
    
          this.saveSecurelyCheckboxValue = !this.saveSecurelyCheckboxValue;
    
          shared.saveToUserPath(this.devEnv, "Tipper " + this.tipper.objectId + " changed saveSecurelyCheckboxValue to: " + this.saveSecurelyCheckboxValue + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          // /console.log("saveSecurelyCheckboxValue::: " + this.saveSecurelyCheckboxValue)
        },
        doToggleUpdateFeesCheckbox(){
          this.addFeesCheckboxValue = !this.addFeesCheckboxValue;
          this.doUpdateAddFeesOrNot();
          if (this.useRyft === true){
            console.log("new Ryft payment");
            this.doRyftPaymentPrepOperations(); // need to update with plus or minus fees payment value for AP/GP
          }
        },
        doUpdateAddFeesOrNot(){
    
          if (this.addFeesCheckboxValue === true) {
            // console.log("adding " );
    
            if (this.willDefaultToFeesTicked === false){
              this.willDefaultToFeesTicked = true; // however it stared life, it is now being used as a lockstep for this.addFeesCheckboxValue. Reason being there is some sequential reason why we should only change this.addFeesCheckboxValue when we currently do, but we need its status available as an alternative test using this.willDefaultToFeesTicked
            }
    
            this.allFeesToAdd = this.allFeesToPotentiallyAdd;
    
          } else {
    
            if (this.willDefaultToFeesTicked === true){
              this.willDefaultToFeesTicked = false; // however it stared life, it is now being used as a lockstep for this.addFeesCheckboxValue. Reason being there is some sequential reason why we should only change this.addFeesCheckboxValue when we currently do, but we need its status available as an alternative test using this.willDefaultToFeesTicked
            }
    
            this.allFeesToAdd = 0.0;
          }
    
          // alert("doing doUpdateAddFeesOrNot");
    
          if (this.multipleTipsTotalNumber !== undefined){
    
            this.lastAmountChosen = this.multipleTipsTotalNumber;
            console.log("this.multipleTipsTotalNumber in doUpdateAddFeesOrNot:" + this.multipleTipsTotalNumber);
          } else {
            console.log("this.multipleTipsTotalNumber was undefined in doUpdateAddFeesOrNot");
          }
    
          this.amountToPayDisplayLabel = (+(this.lastAmountChosen)  + this.allFeesToAdd).toFixed(2);
    
          // console.log("this.amountToPayDisplayLabel: " + this.amountToPayDisplayLabel);
          
          if (this.tipper.tipperFX !== 1) {
            this.amountChosenCurrEqDisplayLabel = "≈ " + this.tipper.tipperCurrencySymbol + ((+(this.lastAmountChosen) + this.allFeesToAdd) / this.tipper.tipperFX).toFixed(2);
            
          }
    
          // // /alert(JSON.stringify(this.makePaymentRequestJSON(this.lastAmountChosen), null, 2));
    
          // const confirmButton = document.getElementById('savedSourceConfirmPayment');
          
          if (this.useRyft === false) {
            let paymentRequest = this.stripe.paymentRequest(this.makePaymentRequestJSON(this.lastAmountChosen, "doUpdateAddFeesOrNot")); // update the payment request which will now have allFeesToAdd
            const confirmButton = this.$refs.savedSourceOrAPorGPConfirmPayment;
            confirmButton.paymentRequest = paymentRequest;
            shared.saveToUserPath(this.devEnv, "Tipper: " + this.tipper.objectId + " about to perform canMakePayment on doUpdateAddFeesOrNot " + " :: "  + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            confirmButton.paymentRequest.canMakePayment().then(result => { // do we actually need this I don't think we do
            });
          }
          // const altConfirmButton = this.$refs.altConfirmButton;
          // altConfirmButton.paymentRequest = paymentRequest;
          // altConfirmButton.paymentRequest.canMakePayment().then(result => { // do we actually need this I don't think we do
          // });
    
          this.consoleLogConfirmButton("doUpdateAddFeesOrNot");
    
          shared.saveToUserPath(this.devEnv, "Tipper " + this.tipper.objectId + " changed doUpdateAddFeesOrNot to: " + this.addFeesCheckboxValue + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
        },
        getAllFeesToPotentiallyAdd(amountChosen){
    
          amountChosen = +(amountChosen); // make it a number
    
          var thankUFeesComponent = +(amountChosen * (this.thankUFeePotentiallyToAdd/100));
    
          // console.log("thankUFeesComponent: " + thankUFeesComponent);
          
          const stripeInternationalFeePercent = this.global.stripeInternationalFeePercent;
    
          // console.log("stripeInternationalFeePercent: " + stripeInternationalFeePercent);
    
          // console.log("this.tipperStripeFeesFromDB: " + this.tipperStripeFeesFromDB);
          // console.log("this.tipper.stripeFees ALL: " + JSON.stringify(this.tipper.stripeFees, null, 2));
    
          // console.log("1: this.tipper.stripeFees.get(PlatformTypeCurrency): " + this.tipper.stripeFees.PlatformTypeCurrency);
    
          // console.log("this.global: " + JSON.stringify(this.global, null, 2));
    
          var platformFees = this.tipperStripeFeesFromDB - 0;
    
          if (this.tipper.stripeFees && this.tipper.stripeFees.PlatformTypeCurrency == "USD") { //this.tipper.stripeFees means we are on Stripe platform
                          
              if (this.global.TUpaysStripeInternationalFee) {
    
                  thankUFeesComponent = +(amountChosen * ((this.thankUFeePotentiallyToAdd - stripeInternationalFeePercent)/100)) ; 
                  
                  // console.log("thankUFeesComponent adjusted for paying International fee: " + thankUFeesComponent);
                  
                  platformFees = this.tipperStripeFeesFromDB + stripeInternationalFeePercent; 
    
                  // console.log("ThankU paying int fees!");
              } else {
                  // console.log("ThankU  NOT  paying int fees!");
                  platformFees = this.tipperStripeFeesFromDB + stripeInternationalFeePercent; // it's extra on top, wow! (might be called multiple time and don't want to add to itself, always start with original DB value)
              }
          }
    
    
          var stripeFeesComponent = 0;
    
          var amountToCalcStripePerCentOn = 0;
    
          if (this.tipper.stripeFees){
    
            amountToCalcStripePerCentOn = (amountChosen + thankUFeesComponent + this.tipper.stripeFees.PlatformTypeOnTopAmount);
    
            console.log("amountToCalcStripePerCentOn: " + amountToCalcStripePerCentOn);
    
            console.log("platformFees: " + platformFees);
    
            stripeFeesComponent = (amountToCalcStripePerCentOn /(1- +(platformFees) / 100) - (amountChosen + thankUFeesComponent));
    
            // console.log("stripeFeesComponent: " + stripeFeesComponent);
    
            // console.log("this.tipper.stripeFees.get(PlatformTypeCurrency): " + this.tipper.stripeFees.PlatformTypeCurrency);
    
            if (this.tipper.stripeFees.PlatformTypeCurrency === "GBP") { // GB platform
    
              const platformFees = 2.9; // the non-European % fee level
    
              const nonEuropeanStripeFeesComponent = (amountToCalcStripePerCentOn /(1- +(platformFees) / 100) - (amountChosen + thankUFeesComponent));
    
              // console.log("nonEuropeanStripeFeesComponent: " + nonEuropeanStripeFeesComponent);
    
              const extraFees = nonEuropeanStripeFeesComponent - stripeFeesComponent;
    
              // console.log("extraFees: " + extraFees);
    
              const totalWithExtraFees = (amountChosen + thankUFeesComponent + stripeFeesComponent + extraFees);
    
              // console.log("totalWithExtraFees: " + totalWithExtraFees);
    
              this.nonEuropeanFeesLabel = this.buttonCurrSymbol + (extraFees).toFixed(2);
    
              this.totalWithNonEuropeanFeesLabel = this.buttonCurrSymbol + (totalWithExtraFees).toFixed(2);
    
              // console.log("this.totalWithNonEuropeanFeesLabel: " + this.totalWithNonEuropeanFeesLabel);
    
            }
    
          }
    
          
    
          return +(thankUFeesComponent + stripeFeesComponent).toFixed(2);
      
        },
        showResult(result){
    
          try {
    
            this.doShowDisplayNameForReceiptInputShowingPrePayment = false;
            // console.log("just set this.doShowDisplayNameForReceiptInputShowingPrePayment to false");
    
            console.log("transactionStatus::: " + result.transactionStatus);
    
            if (result.transactionStatus === "succeeded"){
    
                this.checkUserExistingObjectEmailAddress(); //this is where we make sure the real userOjbect is used in the DB so receipt is sent to the correct email address
    
                console.log("transaction outcome: " + JSON.stringify(result, null, 2));
                this.goAgainMessage = "Tip again";
                console.log("setting paymentConfirmed to true");
                this.paymentAuthenticating = false;
                // this.showCardInput = false;
                this.paymentCancelled = false; // this might have been set to true by a previous error so we need to make sure it is no longer true otherwise we get both messages appearing
                this.paymentConfirmed = true;  
                
                // this.$nextTick(() => {
                //   console.log("JUST DID nexttick on showResult");
                //   this.paymentCancelled = false; 
                //   this.paymentConfirmed = true;  
                // });
    
                this.lastButtonTapped = undefined; this.lastAmountChosen = undefined; 
                
                // /console.log("timestamp paymentConfirmed " + new Date().toISOString());
    
                // console.log("this.tipper.displayname:: " + this.tipper.displayname + " this.tipper.dontShowDisplayNameForReceiptInputAgain " + this.tipper.dontShowDisplayNameForReceiptInputAgain + " this.hasSetEmail "  + this.hasSetEmail + " this.tipper.dontShowEmailForReceiptInputAgain " + this.tipper.dontShowEmailForReceiptInputAgain + " this.hasSetPassword " + this.hasSetPassword + " this.tipper.dontShowPWForUserAccountInputAgain " + this.tipper.dontShowPWForUserAccountInputAgain);
    
                let paymentObjectId = result.paymentObjectId !== undefined ? result.paymentObjectId : "no payment id";
                let paymentIntentId = result.paymentIntentId !== undefined ? result.paymentIntentId : " no paymentIntent id";
                let timestamp = result.timestamp !== undefined ? result.timestamp : "no timestamp";
    
                this.previousTip = "payment Id: " + paymentObjectId + "\ntimestamp: " + timestamp + "\npaymentIntent.id: " + paymentIntentId + "  \npmtParams: " + JSON.stringify(this.previousTipParams, null, 2);
    
                this.previousRecipientObjectId = this.recipient.objectId;
                this.previousRecipientDisplayname = this.recipient.displayname;
                this.previousRecipientPaymentObjectId = paymentObjectId;
    
                window.localStorage.setItem("previousRecipientObjectId", this.previousRecipientObjectId); 
                window.localStorage.setItem("previousRecipientDisplayname", this.previousRecipientDisplayname);
                window.localStorage.setItem("previousRecipientPaymentObjectId", this.previousRecipientPaymentObjectId);
                window.localStorage.setItem("previousTipTimeInMilliseconds", new Date().getTime());
                  
                if (!this.tipper.displayname && !this.tipper.dontShowDisplayNameForReceiptInputAgain){
                  window.setTimeout(this.doShowDisplayNameForReceiptInput, 4000); // will go to email after
                  console.log("this1");
                } else if (this.hasSetEmail === false && !this.tipper.dontShowEmailForReceiptInputAgain){
                  window.setTimeout(this.doShowEmailForReceiptInput, 4000); // this will trigger if the user HAS set a display name on a previous tip but cancelled out of providing email address
                  console.log("this2");
                } else if (this.hasSetEmail === true && this.hasSetPassword === false && !this.tipper.dontShowPWForUserAccountInputAgain){
                  window.setTimeout(this.doShowPWForUserAccountInput, 4000); // this should never happen on the same tip as display name / email
                  console.log("this3");
                } else {
                  window.setTimeout(this.doSetTipAgainVisible, 1000); 
                  console.log("this4");
                }
                  
                window.localStorage.removeItem("tutws");      
            } else if (result.transactionStatus === "log-cancelled"){
                // we have already shown the screen, nothing to add
            }
    
            shared.saveToUserPath(this.devEnv, "Tip result confirmed as: " + result.transactionStatus + " for tipper.id " + this.tipper.objectId + " " + new Date().toISOString(), 2000); // only 2 seconds because we want to make sure we get it
    
            console.log("final result: " + JSON.stringify(result, null, 2));
            // /console.log("this.paymentCancelled::: " + this.paymentCancelled);
          } catch (e) {
            console.log("ERROR in showResult: " + e.message);
            throw e;
          }
        },
        doSetTipAgainVisible(){ //don't want to mix it up with instance variable
          // /console.log("timestamp setTipAgainVisible " + new Date().toISOString());
          this.setTipAgainVisible = true;
          // window.setTimeout(this.doShowEmailForReceiptInput, 2000); 
        },
        doShowEmailForReceiptInput(){
          if (!this.tipper.dontShowEmailForReceiptInputAgain){
            this.showEmailForReceiptInput = true;
            shared.saveToUserPath(this.devEnv, "Tipper presented with showEmailForReceiptInput " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
          }
        },
        async checkUserExistingObjectEmailAddress(){
      
          if (window.localStorage.getItem("emforreceiptsgoingforward") !== null){ // if this flag wasn't set don't waste a call
            this.doSaveEmail(); // it's actually the same thing this is just for clarity, because the fucntion name is a misnomer when triggered in the background
          }
    
        },
        async doSaveEmail(){
    
          let email;
    
          if (window.localStorage.getItem("emforreceiptsgoingforward") !== null){
            //either we triggered this because it was previosuly set on a prior transaction or...
            email = window.localStorage.getItem("emforreceiptsgoingforward");
          } else {
            // .. we are comiong at this from the user's email input for a receipt
            email = this.$refs['new-email'].value;
          } 
    
          if (shared.validEmail(email)){
            var params = {};
            params["userObjectId"] = this.tipper.objectId;
            params["email"] = email.toLowerCase();
            params["username"] = email.toLowerCase();
            params["forReceipt"] = true; // this will change this user's objectId details but not displayname in the payments DB but otherwise leave this 'tu@thanku' user unchanged
    
            try {
              let response = await Parse.Cloud.run("saveUserFromWebApp", params); 
            
              if (response === "succeeded") {
                this.showEmailForReceiptInput = false;
                this.tipper.dontShowEmailForReceiptInputAgain = true;
                this.hasSetEmail = true;
                window.setTimeout(this.doSetTipAgainVisible, 1000);
                shared.saveToUserPath(this.devEnv, "Tipper saved email address " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
              } else if (response === "succeeded and used existing email address from prior userOjbect") {
    
                window.localStorage.setItem("emforreceiptsgoingforward", email.toLowerCase()); // this will only work whilst user is using this device etc but should be good enough
                
                this.showEmailForReceiptInput = false;
                this.tipper.dontShowEmailForReceiptInputAgain = true;
                this.tipper.dontShowPWForUserAccountInputAgain = true; // this is because we have just asked them for their email for in fact it was in use on a prior accoutn whcih already has a password so it's going to create a hall of a mess if they think they have set their password on this object, but the tips will only be avaialble to see on their real email address object
                this.hasSetEmail = true;
                window.setTimeout(this.doSetTipAgainVisible, 1000);
                shared.saveToUserPath(this.devEnv, "Tipper input email address WHICH WAS ACTUALLY on an existing object and payments are being logged to that existing object" + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              }
    
            } catch (err) {
              
              // /console.log("err::: " + JSON.stringify(err, null, 2));
              var responsemsg = err.message.errorText; 
              
              // console.log("we HAVE an error!");
    
              if (err.code === 141) {
                
                // console.log("error Code is 141");
    
                // console.log("errorObj: " + JSON.stringify(err, null, 2));
    
                if (err.message.emailSubmittedUserHasSetEmailButNotPassword === true){
                  const response = await Parse.User.requestPasswordReset(email);
                  this.PopUpTwoOptionsTitle = "Email already registered";
                  responsemsg = "this email address was used before on ThankU and you need to set a password to continue using it - you normally only get this message if you are in incognito mode in your browser. A password reset link has been sent to your email, please use it to set a password, and only after that tap 'login' below. Our apologies for the inconvenience.";
                  this.wasProbablyIncognito = true;
                } else {
                  this.PopUpTwoOptionsTitle = "Email already registered";
                  responsemsg = "this email address is already registered on ThankU - please choose one of the options below...";
                }
    
              } else {
                
                this.PopUpTwoOptionsTitle = "Sorry";
              }
              this.PopUpTwoOptionsMessage = responsemsg;
    
              // /console.log("response::: " + responsemsg);
              this.showPopUpTwoOptions = true;
            }
            
          } else {
            this.popUpMsgTitle = "Oops..";
            this.popUpMsgBody = "'" + email + "' is not a valid email address. Please check and try again";
            this.showPopUpOk = true;
          }
        },
        doShowEmailForReceiptInputDontShowAgain(){
    
            shared.saveToUserPath(this.devEnv, "Tipper selected dontShowEmailForReceiptInputAgain " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            this.showEmailForReceiptInput = false; //disappears the dialog
            this.tipper.dontShowEmailForReceiptInputAgain = true;
            var params = {};
            params["userObjectId"] = this.tipper.objectId;
            params["dontShowEmailForReceiptInputAgain"] = true;
            window.setTimeout(this.doSetTipAgainVisible, 1000);
            Parse.Cloud.run("saveUserFromWebApp", params);
        },
        doCancelShowEmailForReceiptInput(){
          this.showEmailForReceiptInput=false;  
          window.setTimeout(this.doSetTipAgainVisible, 1000);
        },
        doShowDisplayNameForReceiptInput(){
          if (!this.tipper.dontShowDisplayNameForReceiptInputAgain){
            // this.deployLogRocket(this.logRocketOrganisationSlug);
            this.showDisplayNameForReceiptInput = true;
            shared.saveToUserPath(this.devEnv, "Tipper being shown showDisplayNameForReceiptInput " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
          }
        },
        doSaveDisplayName(){
          const displayname = this.$refs['new-displayname'].value;
    
    
          if (displayname !== "" && displayname.length > 1){
            var params = {};
            params["userObjectId"] = this.tipper.objectId;
            params["displayname"] = displayname;
    
            Parse.Cloud.run("saveUserFromWebApp", params);
            this.tipper.dontShowDisplayNameForReceiptInputAgain = true; // tho no need to save this we need to set it here as a flag in this session in case the user taps tip again we don't want it diesplayed again
            this.showDisplayNameForReceiptInput = false;
            // /console.log("setting localStorage tudis::: " + displayname);
            window.localStorage.setItem("tudis", displayname);
            this.tipper.displayname = displayname;
    
            shared.saveToUserPath(this.devEnv, "Tipper saved display name: " + displayname + " " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            if (this.doShowDisplayNameForReceiptInputShowingPrePayment === false) {
    
              shared.saveToUserPath(this.devEnv, "this.doShowDisplayNameForReceiptInputShowingPrePayment === false" + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
              console.log("this.doShowDisplayNameForReceiptInputShowingPrePayment is FALSE");
              let params2 = {
                transferringObjectId: this.tipper.objectId,
                transferringObjectDisplayname: displayname
              }
    
              Parse.Cloud.run("updatePaymentRecordsWithTipperCurrentInfo", params2); 
              
    
              if (this.hasSetEmail === false){
                if (!this.tipper.dontShowEmailForReceiptInputAgain ){
                  window.setTimeout(this.doShowEmailForReceiptInput, 1000); 
                } else {
                  window.setTimeout(this.doSetTipAgainVisible, 1000);
                }
              }
            } else {
              console.log("this.doShowDisplayNameForReceiptInputShowingPrePayment is TRUE");
            }
    
          } else {
            this.popUpMsgTitle = "Oops.."
            this.popUpMsgBody = "Your username must be at least 2 characters long please"
            this.showPopUpOk = true;
          }
        },
        doShowDisplayNameForReceiptInputDontShowAgain(){
    
            shared.saveToUserPath(this.devEnv, "Tipper selected dontShowDisplayNameForReceiptInputAgain " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            this.showDisplayNameForReceiptInput = false; // this disappears the dialog
    
            if (this.doShowDisplayNameForReceiptInputShowingPrePayment === false){
              console.log("doShowDisplayNameForReceiptInputShowingPrePayment WAS false");
              this.tipper.dontShowDisplayNameForReceiptInputAgain = true;
              window.setTimeout(this.doSetTipAgainVisible, 1000);
    
            } else {
              //only do this year as it's at the beginning not the end of the process
              this.doShowDisplayNameForReceiptInputShowingPrePayment = false; // need this for the reload
              this.doSaveThisFullDataSetToLocalStorage("doShowDisplayNameForReceiptInputDontShowAgain"); // need to do this to record that both these are now false;  EXCEPT after the transaction has succeeded or faile
              console.log("doShowDisplayNameForReceiptInputDontShowAgain WAS TRUE");
            }
    
            var params = {};
            params["userObjectId"] = this.tipper.objectId;
            params["dontShowDisplayNameForReceiptInputAgain"] = true;
            window.setTimeout(this.doSetTipAgainVisible, 1000);
            Parse.Cloud.run("saveUserFromWebApp", params);
        },
        doCancelShowDisplayNameForReceiptInput(){
    
          console.log("doCancelShowDisplayNameForReceiptInput");
          this.showDisplayNameForReceiptInput=false;  
          
    
          if (this.doShowDisplayNameForReceiptInputShowingPrePayment === false){
            console.log("doShowDisplayNameForReceiptInputShowingPrePayment WAS false");
            window.setTimeout(this.doSetTipAgainVisible, 1000);
          } else {
            //only do this year as it's at the beginning not the end of the process
            this.doShowDisplayNameForReceiptInputShowingPrePayment = false; // need this for the reload
            this.doSaveThisFullDataSetToLocalStorage("doCancelShowDisplayNameForReceiptInput"); // need to do this to record that both these are now false;  EXCEPT after the transaction has succeeded or faile
            console.log("doShowDisplayNameForReceiptInputShowingPrePayment WAS TRUE");
          }
        },
        doShowPWForUserAccountInput(){
          if (!this.tipper.dontShowPWForUserAccountInputAgain){
            this.showPWForUserAccountInput = true;
            
            shared.saveToUserPath(this.devEnv, "Tipper being shown showPWForUserAccountInput " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          }
        },
        async doSavePW(){
          const pw = this.$refs['new-password'].value;
    
          if (pw !== "" && pw.length > 4){
            var params = {};
            params["userObjectId"] = this.tipper.objectId;
            params["password"] = pw;
            
            try {
              let newSessionToken = await Parse.Cloud.run("saveUserFromWebApp", params); 
              // /console.log("doing new session token");
              // /console.log("Sesh:: " + newSessionToken);
              window.localStorage.setItem("tu", newSessionToken);
              this.showPWForUserAccountInput = false;
              this.hasSetEmail = true; // for UI email message
              this.hasSetPassword = true;
              this.tipper.dontShowPWForUserAccountInputAgain = true; // for the session
    
              window.localStorage.setItem("tuisfulluser", true);
    
              window.setTimeout(this.doSetTipAgainVisible, 1000); 
    
              shared.saveToUserPath(this.devEnv, "Tipper saved password " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
    
            } catch (err) {
              
              // /console.log("err::: " + JSON.stringify(err, null, 2));
              var responsemsg = err.message; // not using for now, safe to go with codes for multilingual later, in the case of username it was "Account already exists for this username."
    
              this.popUpMsgTitle = "Sorry";
              responsemsg = responsemsg.substr(0); // what number?
              this.popUpMsgBody = responsemsg;
              // /console.log("response::: " + responsemsg);
              this.showPopUpOk = true;
            }
          } else {
            this.popUpMsgTitle = "Oops.."
            this.popUpMsgBody = "Your password must be at least 5 characters long please"
            this.showPopUpOk = true;
          }
        },
        doShowPWForUserAccountInputDontShowAgain(){
          
            shared.saveToUserPath(this.devEnv, "Tipper selected dontShowPWForUserAccountInputAgain " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
            this.showPWForUserAccountInput = false; // disappears the dialog
            this.tipper.dontShowPWForUserAccountInputAgain = true; // for the session
            var params = {};
            params["userObjectId"] = this.tipper.objectId;
            params["dontShowPWForUserAccountInputAgain"] = true;
            Parse.Cloud.run("saveUserFromWebApp", params);
            window.setTimeout(this.doSetTipAgainVisible, 1000);
        },
        doCancelShowPWForUserAccountInput(){
          this.showPWForUserAccountInput=false;  
          window.setTimeout(this.doSetTipAgainVisible, 1000);
        },
        makePaymentRequestJSON(buttonAmount, origin){
    
          // so long as we are using the new approach with tipsArray for everything, the incoming buttonAmount is not used, and if it is the old system there can only be one tip anyway
    
          let buttonAmountNumber = 0;
    
          var venueStr = ""; if (this.venue.name) { venueStr = " at " + this.venue.name; };
    
          var displayItems = undefined;
    
          var feesInDisplayItems = 0;
    
          let originStr = origin !== undefined ? origin : "n/a "
    
          console.log(origin + " makePaymentRequestJSON this.usingMultiTips " + this.usingMultiTips);
          
          if (this.usingMultiTips === true && origin !== "dummy") {
            console.log("here I am using this.usingMultiTips ON makePaymentRequestJSON...");
    
            let tipsArrayAllFeesToPotentiallyAdd = 0;
    
            if (this.tipsArray.length > 0){
              for (let tip of this.tipsArray){
                // console.log("tip.amountChosen: " + tip.amountChosen + " tip.allFeesToPotentiallyAdd : " + tip.allFeesToPotentiallyAdd);
                buttonAmountNumber += +tip.amountChosen;
                tipsArrayAllFeesToPotentiallyAdd += +tip.allFeesToPotentiallyAdd;
              }
              // console.log("totalled tip amount: " + buttonAmountNumber + "  tipsArrayAllFeesPotentiallyToAdd: " + tipsArrayAllFeesToPotentiallyAdd.toFixed(2));
    
              this.allFeesToPotentiallyAdd = +(this.getAllFeesToPotentiallyAdd(buttonAmountNumber));
              this.allFeesToAdd = this.allFeesToPotentiallyAdd;
              
              // console.log("totalled tip amount: " + buttonAmountNumber + "  this.allFeesToAdd: " + this.allFeesToAdd);
    
              buttonAmount = buttonAmountNumber;
    
              this.multipleTipsTotalNumber = buttonAmountNumber;
    
              console.log(origin + " this.multipleTipsTotalNumber in makePaymentRequestJSON: " + this.multipleTipsTotalNumber);
              
              // NB re below, we had an edge case where by the time we came to a token payment, the amountChosen value for the button was for the last amount chosen not the aggregate amount, I have no idea why as all the other the tips of the day seemed to work but when this.multipleTipsTotalNumber was set in doUpdateAddFeesOrNot it was correct and the next minute when we saw ConfirmButton at stage doUpdateAddFeesOrNot the amountChosen value was only the last tipped input. The tipper this happened to was FCJAq2J2iU on 10th Oct 2024. Anyhow, we hope that ensuring that the button's amountChosen button is defintely update with the this.multipleTipsTotalNumber number here which we have never had issues with will hopefully fix it. The total cost on the user screen shown was correct but when it came to Google Pay which we couldn't see on LogRocket the amount was evidently just the one tip - the fees added were correct for one tip, so the only conclusion I can come to is that the button just hadn't been updated with the second tip details
              const confirmButton = this.$refs.savedSourceOrAPorGPConfirmPayment;
              confirmButton.amountChosen = this.multipleTipsTotalNumber;
    
              // console.log("button Amount: " + buttonAmount);
              // console.log("atLeaseOneRecipientHasPhoto:: " + this.atLeaseOneRecipientHasPhoto);
    
            }
    
          } else {
            console.log("here I am NOT USING this.usingMultiTips ON makePaymentRequestJSON...");
    
            this.allFeesToPotentiallyAdd = +(this.getAllFeesToPotentiallyAdd(buttonAmount));
            this.allFeesToAdd = this.allFeesToPotentiallyAdd;
          }
    
    
          let totalAmountIncFees = buttonAmount; // needs to be down here now that we have a new buttonAmount based on buttonAmountNumber, potentially, multiple tips
    
          let displayNameStrToUse = this.allrecipientDisplaynamesAndAmounts !== undefined ? this.allrecipientDisplaynamesAndAmounts : this.recipient.displayname;
    
          if (this.recipient.feesAtUserOption === true) {
            // NEW SYSTEM
            // console.log("NEW SYSTEM this.recipient.feesAtUserOption is " + this.recipient.feesAtUserOption);
            if (this.addFeesCheckboxValue === true || this.willDefaultToFeesTicked === true){ // this.willDefaultToFeesTicked: there is some sequential reason why we shouldn't set this.addFeesCheckboxValue to true too early however we are using this.willDefaultToFeesTicked as an alternative flag since it doesn't affect anything else
              if (this.allFeesToAdd > 0) {
                // console.log(originStr + "this.allFeesToAdd is " + this.allFeesToAdd);
                totalAmountIncFees = (+buttonAmount + this.allFeesToAdd);
                feesInDisplayItems = this.allFeesToAdd;
              } else {
                // console.log(originStr + "this.allFeesToAdd was zero ");
              }
            } else {
              // console.log(originStr + "this.addFeesCheckboxValue is " + this.addFeesCheckboxValue);
            }
          } else if (this.recipient.feesAtUserOption === false) {
            // console.log("NEW SYSTEM this.recipient.feesAtUserOption is " + this.recipient.feesAtUserOption);
            totalAmountIncFees = (+buttonAmount); // just the aggregate amount of the tips
            // console.log(originStr + " totalAmountIncFees is " + totalAmountIncFees);
            feesInDisplayItems = (buttonAmount * this.thankUFeeToAdd/100); // this will not kick in because feesInDisplayItems = 0
    
    
          } else {
    
            //OLD SYSTEM
            console.log("OLD SYSTEM because this.recipient.feesAtUserOption is " + this.recipient.feesAtUserOption);
            totalAmountIncFees = (buttonAmount * (1 + this.thankUFeeToAdd/100));
            feesInDisplayItems = (buttonAmount * this.thankUFeeToAdd/100); // this would only kick in literally under the old NFC system otherwise it will just be zero anyway
          }
    
          if (+(feesInDisplayItems * 100).toFixed(0) > 0) {
            displayItems =  [
              {
                label: 'tip' + this.tipsPluralStr +' to ' + displayNameStrToUse,
                amount: +(buttonAmount * 100).toFixed(0),
              },
              {
                label: 'processing fee',
                amount: +(feesInDisplayItems * 100).toFixed(0),
              },
            ]
          }
    
          // console.log("makePaymentRequestJSON this.recipient: " + JSON.stringify(this.recipient, null, 2));
    
          
    
          return {
            country: this.recipient['locale'].toUpperCase(), 
            currency: this.recipient['currencyCode'].toLowerCase(),
            total: {
                label: displayNameStrToUse + venueStr,
                amount: +(totalAmountIncFees * 100).toFixed(0),
            },
            displayItems,
            // shippingAddress: {
            //   recipient: 'Just take me anywhere',
            // }
          }
        },
    
        checkSize(){
          if(window.innerWidth !== undefined && window.innerHeight !== undefined) { 
            var w = window.innerWidth;
            var h = window.innerHeight;
          } else {  
            var w = document.documentElement.clientWidth;
            var h = document.documentElement.clientHeight;
          }
          var txt = "Page size: width=" + w + ", height=" + h;
          this.dimensions = txt;
          // document.getElementById("demo").innerHTML = txt;
        },
        getTipperParams(){
    
          // /console.log("calling get tipper params");
    
          const userPhotoFilename = window.localStorage.getItem("userPhotoFilename") !== null ? window.localStorage.getItem("userPhotoFilename") : undefined;
    
          var params = {};
          params["tuid"] = this.tuid;
          params["userObjectId"] = this.userObjectId;
          
          this.deviceFullObject = shared.getDeviceFullObject();
    
          params["locale"] = this.deviceFullObject.locale;
          params["deviceIsDaylightSavingTime"] = this.deviceFullObject.deviceIsDaylightSavingTime;
          params["deviceDaylightSavingTimeOffset"] = this.deviceFullObject.deviceDaylightSavingTimeOffset;
          params["deviceSecondsFromGMT"] = this.deviceFullObject.deviceSecondsFromGMT;
          params["deviceLocalTimeZoneIdentifier"] = this.deviceFullObject.deviceLocalTimeZoneIdentifier;
          params["deviceType"] = this.deviceFullObject.deviceType;
          params["mobile"] = this.deviceFullObject.mobile;
          params["OS"] = this.deviceFullObject.deviceOS;
          params["userAgent"] = this.deviceFullObject.userAgent;
          params["isRecipient"] = false;
          params["tippedWithoutScanning"] = this.tippedWithoutScanning;
          params["userPhotoFilename"] = userPhotoFilename;
          
    
          // console.log("tipper params:: " + JSON.stringify(params, null, 2));
    
          return params;
        },
        showMainFinishScreen(status){

          this.showPaymentScreen = true;
          let backgroundcolour;
          if (status==="log-cancelled" || status === "token-log-cancelled" || status === "ryft-cancelled" ){
            backgroundcolour = '#EDF2F7';// this was the pale red we tried '#e54a1c';
          } else { // succeeded
            backgroundcolour = '#68D391'; //green
          }
          document.body.style.backgroundColor = backgroundcolour;
          console.log("showMainFinishScreen status: " + status);
    
        },
        getPaymentRequestParams(ev, stripeSource, buttonNum, amountChosen, status, statusReason, origin){
          
          try {
            if (this.useRyft === false){
              this.showMainFinishScreen(status);
            }
            
    
            let originStr = origin !== undefined ? origin : "n/a";
            var token; 
            var sourceId;
            // var secret; don't need it
            var country;
            var cardBrand;
            var last4Digits;
            var methodName = "STCC"; // unless varied
            var type;
            var usage;
    
            if (ev) {
              token = ev.token.id; 
              country = ev.token.card.country;
              cardBrand = ev.token.card.brand;
              last4Digits = ev.token.card.last4;
              methodName = ev.methodName;
              type = ev.token.type.toUpperCase();
    
              // console.log("Card details country on EV: " + country);
            } else if (stripeSource) {
              // /console.log("stripeSource:: "+ JSON.stringify(stripeSource, null, 2));
              sourceId = stripeSource.source.id; 
              country = stripeSource.source.card.country;
              cardBrand = stripeSource.source.card.brand;
              last4Digits = stripeSource.source.card.last4;
              type = stripeSource.source.type.toUpperCase();
              usage = stripeSource.source.usage;
    
              // console.log("Card details country on stripeSource: " + country);
            } else {
              // console.log("country info not coming from here "); // because it's a saved source
            }
    
            this.clientRequestId = +new Date() + " click: " + this.buttonPressNum;
    
            // this.clientRequestId = "1653132406232 click: 1"; dev test
    
            let tipsArrayRequiredFieldsOnly = undefined;
    
            if (this.tipsArray.length > 0) {
              tipsArrayRequiredFieldsOnly = [];
              for (let tip of this.tipsArray){
                  let tipsArrayRequiredFieldsOnlyItem = {
                    recipientObjectId: tip.recipient.objectId,
                    amount: tip.amountChosen,
                  }
                  tipsArrayRequiredFieldsOnly.push(tipsArrayRequiredFieldsOnlyItem);
              }
            }
    
            let params = {
              tuid: this.tuid,
              tipperId: this.tipper.objectId, 
              locale: this.deviceFullObject.locale, 
              deviceSecondsFromGMT: this.deviceFullObject.deviceSecondsFromGMT,
              deviceIsDaylightSavingTime: this.deviceFullObject.deviceIsDaylightSavingTime,   
              deviceDaylightSavingTimeOffset: this.deviceFullObject.deviceDaylightSavingTimeOffset, 
              deviceLocalTimeZoneIdentifier: this.deviceFullObject.deviceLocalTimeZoneIdentifier,
              deviceType: this.deviceFullObject.deviceType,
              deviceMobile: this.deviceFullObject.mobile,
              deviceUserAgent: this.deviceFullObject.userAgent,
              deviceOS: this.deviceFullObject.deviceOS,
              recipientId: this.recipient.objectId, // or this.tipsArray   THIS is only being used where there is no tipsArray. I haven't changed it to confirm to recipientObjectId normally used, in case of disconnect's between cached versions of the app at update time vs new cloud code being out of step
              venuePoolMasterId: this.venue.poolMasterId,
              venueID: this.venue.venueID,
              amount: amountChosen, // we are sending amountChosen, all the rest is done on the backend, it works from amountChosen // or this.tipsArray
              currency: this.recipient['currencyCode'].toLowerCase(), // TODO can't remember if it's possible for different recipients at one salon to ahve different recieving currencies
              token: token,
              sourceId: sourceId,
              country: country,
              cardBrand: cardBrand,
              last4Digits: last4Digits,
              methodName: methodName,
              type: type,
              tipperFX: this.tipper.tipperFX,
              status: status, //only present with cancelled so far
              statusReason: statusReason,
              usage: usage,
              userChoseToAddFees: this.addFeesCheckboxValue,
              platform: "web",
              clientRequestId: this.clientRequestId,
              previousRecipientObjectId: this.previousRecipientObjectId,
              previousRecipientDisplayname: this.previousRecipientDisplayname,
              previousRecipientPaymentObjectId: this.previousRecipientPaymentObjectId,
              tippedWithoutScanning: this.tippedWithoutScanning,
              feesAtUserOption: this.feesAtUserOption,
              additionalField: "",
              tipsArray: tipsArrayRequiredFieldsOnly,
              origin: originStr,
              useRyft: this.useRyft,
            }
    
            if (buttonNum) {
            
            } else {
              buttonNum = "with amount " + amountChosen;
            }
            // console.log("PMT DATA for Button " + buttonNum + " IS:: " + JSON.stringify(params, null, 2));
    
            return params;
          } catch (e) {
            // console.log("ERROR in getPaymentRequestParams: " + e.message);
            this.deployLogRocket(this.logRocketOrganisationSlug);
            throw e;
          }
        },
    
        doTipAgain(){
    
          shared.saveToUserPath(this.devEnv, "Tip again tapped " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
    
          // this.deployLogRocket(this.logRocketOrganisationSlug); can't user video evidence with Stripe
    
          // console.log("tip again tapped");
    
          window.setTimeout(this.doMainTipAgainOps, 350); 
     
        },
        doMainTipAgainOps(dontBlankTipArrayTypeValues){
    
          this.checkForPriorTipValues();
    
          this.showRecipient = false;
          this.paymentCancelled = false;   
          this.paymentConfirmed = false;
          this.showPaymentScreen = false;
          this.paymentSubmitted = false;
          this.showOwnTip=false;
          this.setTipAgainVisible=false;
    
          if (dontBlankTipArrayTypeValues !== undefined && dontBlankTipArrayTypeValues === true){
            // we only want to blank this lot when the tipper has actually tapped the Tip Again Button at the end of a tip rather than whilst we are adding tips midway
          } else {
            console.log("BLANKING TIPS ARRAY");
            this.blankTipArrayTypeValues();
          }
    
          let backgroundcolour = '#EDF2F7';
          
    
          // document.querySelector('body').style.backgroundColor = backgroundcolour;
          document.body.style.backgroundColor = backgroundcolour;
         
          // console.log("previous tip:\n" + this.previousTip);
    
          this.linkedUsersArrayObjectFiltered = this.linkedUsersArrayObject; // make sure that any filtering is removed
    
          this.showRecipientScreen();
    
        },
        showRecipientScreen(){
    
          if (this.linkedUsersArrayObject.length > 0 || (this.recipient.isPoolMaster === true && this.recipient.dontShowPoolmasterInList === true)){
    
            shared.saveToUserPath(this.devEnv, "Showing recipient list " + new Date().toISOString(), this.globalPushForwardInterval, this.loggingError);
            
            this.showRecipient = false;
            this.showRecipientsList = true;
    
            // console.log("about to nexttick");
            if (shared.getDeviceFullObject().deviceType === "Desktop"){
            // on a phone it's i) pointless ii) counterproductive because it initiates pushing half the screen up to be invisible
              this.$nextTick(() => {
                // console.log("JUST DID nexttick");
                this.$refs['searchbox'].focus(); // ref virtual DOM see https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Client-side_JavaScript_frameworks/Vue_refs_focus_management
                // need to find a way to make this leave the correct part of the page visible on iPhone
                window.scrollTo({ top: 0, behavior: 'smooth' });
              });
            }
    
          } else {
            this.userSelected(this.recipient.objectId, this.recipient.displayname, this.recipient.connectedAccountStatus); // make sure we have run through everything including dummy canMakePayment
            // this.showRecipient = true;
            // this.showRecipientsList = false;
          }
        },
        doCheckRecipientConnectedAccountStatus(){
    
          // /console.log("calling check account status");
          // console.log("this.recipient.connectedAccountStatus::: " + this.recipient.displayname + "  " + JSON.stringify(this.recipient.connectedAccountStatus, null, 2));
    
          if (this.recipient.connectedAccountStatus !== true && (this.recipient.isPoolMaster === true && this.recipient.dontShowPoolmasterInList === false)){
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = this.recipient.displayname + " needs to add their bank details before they can receive tips with thankU";
            this.showPopUpOk = true;
            return false;
          } else {
            return true;
          }
        },
        doMakeName(){
    
          this.venue.name = this.$refs.searchbox.value;
          let params = {
            newVenueName: this.$refs.searchbox.value,
            venueObjectId: this.venue.objectId,
          }
          Parse.Cloud.run("setNameForVenue", params); // don't wait
          
        },
        deployLogRocket(logRocketOrganisationSlug){ // IMPORTANT!!!! If we find this can deploy as aync await then be VERY CAREFUL as turing the functions it appears in into async functions decouples the return so you must add await in whatever functions call them and so forth up the function chain
    
          this.loggingError = true; // from here on the saved user path will now in fact be emailed
    
          if (this.tipper.objectId === "4g278vWUb1" || this.tipper.objectId === "2fBkimFcIw" ){
            // return; // don't record myself
          }
    
          var LRDisplayname = "LR Name not given";
    
          if (this.tipper.displayname !== undefined) {
            LRDisplayname = this.tipper.displayname;
          }
    
          try {
    
            if (this.logRocketIsRunning === true) {
              return; // no need to initialise
            }
    
            if (logRocketOrganisationSlug !== undefined && logRocketOrganisationSlug !== ""){
              LogRocket.init(logRocketOrganisationSlug);
              // console.log("I'm logging at HARI's");
            } else {
                LogRocket.init('fpi3vr/thanku');  //LogRocket.init('thanku-alt-10/thanku-alt-10'); //LogRocket.init('thanku-alt-9/thanku-alt-9'); //LogRocket.init('thanku-alt-8/thanku-alt-8');//LogRocket.init("thanku-alt-7/thanku-alt-7"); //LogRocket.init('thanku-alt-6/thanku-alt-6'); //LogRocket.init('thanku-alt-5/thanku-alt-5'); //LogRocket.init('thanku-alt-4/thanku-alt-4'); //LogRocket.init('thanku-alt-3/thanku-alt-3');//LogRocket.init('thanku-alt-2/thanku-alt-2');//LogRocket.init('thanku-alt/tualt'); //
              // console.log("REGULAR LOGGING");
            }
            this.logRocketIsRunning = true;
            LogRocket.identify(this.tipper.objectId, {
              name: LRDisplayname + ": tipping",
              email: undefined,
              // Add your own custom user variables here, ie:
    
            });
            console.log("LOGROCKET LOADED! on Tipping: " + this.tipperObjectId + " : " + LRDisplayname + " slug: " + logRocketOrganisationSlug);
    
          } catch (e) {
            // console.log("error starting LogRocket tipper id: " + this.tipper.objectId);
            
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "ALERT! LogRocket initialisation error: " + e.message,
              body: "ALERT! LogRocket initialisation error: " + e.message,
            };
    
            Parse.Cloud.run("sendEmail", params);
            // fail silently do nothing
          }
        },
        checkForPriorTipValues(){
    
          let nowInMilliseconds = new Date().getTime();
    
          // console.log("nowInMilliseconds: " + nowInMilliseconds);
    
          this.previousTipTimeInMilliseconds = window.localStorage.getItem("previousTipTimeInMilliseconds") !== null ? window.localStorage.getItem("previousTipTimeInMilliseconds") : undefined; 
    
          if (this.previousTipTimeInMilliseconds === undefined) {
            return; // no comparison to make
          } else if (this.previousTipTimeInMilliseconds < 1000){
            // do nothing, this value was set using the incorrect get UTCMilliseconds which will just give you the milliseconds portion of the date i.e. < 1000
            return;
          }
          
          // console.log("this.previousTipTimeInMilliseconds: " + this.previousTipTimeInMilliseconds);
    
          let compareTime = (+this.previousTipTimeInMilliseconds + (1000 * 60 * 5));
          
          // console.log("compareTime: " + compareTime);
    
    
          if (nowInMilliseconds < compareTime){ 
            
            this.previousRecipientObjectId = window.localStorage.getItem("previousRecipientObjectId") !== null ? window.localStorage.getItem("previousRecipientObjectId") : undefined; 
            
            this.previousRecipientDisplayname = window.localStorage.getItem("previousRecipientDisplayname") !== null ? window.localStorage.getItem("previousRecipientDisplayname") : undefined; 
            
            this.previousRecipientPaymentObjectId = window.localStorage.getItem("previousRecipientPaymentObjectId") !== null ? window.localStorage.getItem("previousRecipientPaymentObjectId") : undefined; 
    
            if (this.previousTipTimeInMilliseconds === undefined) {
              // const params = {
              //   toEmail: "appalert@thanku.app",
              //   subject: "ALERT! previousTipTimeInMilliseconds was undefined BUT still going to trip duplicate condition...",
              //   body: "nowInMilliseconds was : " + nowInMilliseconds + " compareTime was " + compareTime,
              // };
    
              // Parse.Cloud.run("sendEmail", params);      
    
            } else {
              // const params = {
              //   toEmail: "appalert@thanku.app",
              //   subject: "This SHOULD be a GENUINE duplicate condition...",
              //   body: "nowInMilliseconds was : " + nowInMilliseconds + " compareTime was " + compareTime,
              // };
    
              // Parse.Cloud.run("sendEmail", params);      
            }
    
            // no purpose in removing these, they should stand - if for example the user refreshes their screen in a geniune duplciate tip situation, then the earlier tip info will ahve been lost - it only does anything if it's within the 5 minutes - if it's outside the 5 minutes it will be ignored and eventually replaced with the next tip
            // window.localStorage.removeItem("previousRecipientObjectId");
            // window.localStorage.removeItem("previousRecipientDisplayname");
            // window.localStorage.removeItem("previousRecipientPaymentObjectId");
            // window.localStorage.removeItem("previousTipTimeInMilliseconds");        
          } else {
            this.previousTipTimeInMilliseconds = undefined;
    
            // const params = {
            //     toEmail: "appalert@thanku.app",
            //     subject: "This SHOULD be a GENUINE NON duplicate condition...",
            //     body: "nowInMilliseconds was : " + nowInMilliseconds + " compareTime was " + compareTime,
            //   };
    
            //   Parse.Cloud.run("sendEmail", params);   
            
          }
        },
        // makeRecipientCancelImageData(){ // didnt need this in the end when we did it through mini component
    
        //   var canvas = document.createElement('canvas');
        //   canvas.width = 50; // was it's original size
        //   canvas.height = 50; // ditto
        //   var ctx=canvas.getContext("2d");
        //   var img= new Image();
        //   img.src = require('@/assets/cancel.png');;
        //   ctx.drawImage(img,0,0);
        //   canvas.toDataURL();
        //   this.recipientCancelImageData = canvas.toDataURL();
        //   console.log("this.recipientCancelImageData:::: " + this.recipientCancelImageData);
    
        // },
        async doLoadRyft(triggerOnload){
    
            // document.head.innerHTML += '<script src="https://embedded.ryftpay.com/v1/ryft.min.js"<\/script>';
            
            
    
            let ryftScript = document.createElement('script')
            ryftScript.setAttribute('src', 'https://embedded.ryftpay.com/v1/ryft.min.js')
            
            // both this and teleport work with the advantage this only runs on mount not whenever Vue refreshes the DOM
            // this.initRyft();
            
            if (triggerOnload !== undefined && triggerOnload === true){
              ryftScript.onload = () => this.tryInitRyftForAPandGPStatusOnlyResponse(); // //we need the relevant this.recipient.ryftConnAccId 
            }
    
            document.head.appendChild(ryftScript)
    
            
    
        },
        async initRyft(clientSecret, ryftPaymentMethodsJSON, includeWallet, includeSavedCards){
    
          try {
       
            await this.deleteRyftPaySavedCardsGridIfOutsideRyftPayForm(); // assume it has ben moved there before and needs deleting if present because a new one is about to be created

            // let e = {
            //   message: "TEST FAILURE on initRyft",
            // }
            // throw e;

            let rawJson = includeSavedCards === true ? JSON.stringify(ryftPaymentMethodsJSON, null, 2) : undefined;
            let applePay = includeWallet === true ? {
                  merchantName: "Appsanely Limited",
                  merchantCountryCode: "GB"
                } : undefined;

            let googlePay = includeWallet === true ? {
                  merchantName: "Appsanely Limited",
                  merchantCountryCode: "GB"
                } : undefined;

            await Ryft.init({
                publicKey: this.ryftPublishableKey,
                clientSecret: clientSecret, // optional here - this can be supplied on Ryft.attemptPayment() - NO! if you want AP or GP has to be supplied here...
                // accountId: accountId,
                // usage: 'payment', //default, the customer is paying
                customerPaymentMethods: {
                  allowStorage: true, // defaults to true
                  rawJson: rawJson,
                },
                applePay: applePay,
                googlePay: googlePay,
            });
    
            if (await this.ryftiFrameIsPresentWhetherOrNotLoaded() === true){
              this.setRyftiFrameLoadingListener();
            } else {
              console.log("For some reason Ryft iFrame not present...?");
            }
    
    
    
    
            // console.log("Ryft object: " + JSON.stringify(Ryft.currentTarget, null, 2));
            
    
            //  const params = {
            //   toEmail: "appalert@thanku.app",
            //   subject: "ALERT! initRyft inited",
            //   body: "ALERT! initRyft inited: " + JSON.stringify(Ryft, null, 2),
            // };
    
            // Parse.Cloud.run("sendEmail", params);
    
            Ryft.addEventHandler("walletPaymentSessionResult", this.handleWalletPaymentSessionResult);
            Ryft.addEventHandler("cardValidationChanged", this.handleCardValidationChanged);
            Ryft.addEventHandler("paymentMethodSelectionChanged", this.paymentMethodSelectionChanged);
    
            // console.log("Ryft initiated with accountId: " + accountId);
    
            return;
          } catch (e) {
            console.log("ERROR on initRyft::: " + e.message);
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "ALERT! initRyft error",
              body: "ALERT! initRyft error: " + e.message,
            };
    
            Parse.Cloud.run("sendEmail", params);
            if (this.useRyft === true){
              throw e;
            }
          }
        },
       async doSubmitRyftPayment(){
    
        try {
                 
          console.log("doSubmitRyftPayment");
    
          // because of the Ryft sequencing this is where we need to initiate the below
          this.showPaymentSubmitted(); 
          //whichever way it turns out we need to do this
          this.processTokenPayment = false;
          this.blankButton();
    
          this.doRyftAttemptPayment();
    
          return; // below was old way, now we are literally just confirming a previosuly created paymentSession
    
          
        } catch (e) {
          console.log("ERROR in doSubmitRyftPayment: " + e.message);
          const params = {
              toEmail: "appalert@thanku.app",
              subject: "ALERT! doSubmitRyftPayment error: " + e.message,
              body: "ALERT! doSubmitRyftPayment error: " + e.message,
            };
    
          Parse.Cloud.run("sendEmail", params);
          throw e;
        }  
      
          
        },
        paymentMethodSelectionChanged(e) {
          console.log("paymentMethodSelectionChanged: " + JSON.stringify(e, null, 2));
    
          if (e.paymentMethod !== null && e.validation.expirationValid === true){
            this.canPay = true;
            console.log("about to doSubmitRyftPayment");
            this.doSubmitRyftPayment();
          } else {
            this.canPay = false;
          }
          console.log("this.canPay: " + this.canPay);
          // const params = {
          //   toEmail: "appalert@thanku.app",
          //   subject: "ALERT! handleMethodSelectionChanged: ",
          //   body: JSON.stringify(e, null, 2),
          // };
    
          // Parse.Cloud.run("sendEmail", params);
          return;
        
        },
    
        handleWalletPaymentSessionResult(e) {
          console.log("handleWalletPaymentSessionResult: "  + JSON.stringify(e, null, 2));
    
          let paymentSession = e.paymentSession;
          
          this.paymentInProgress = false; console.log("this.paymentInProgress SET FALSE Ryft 2");
          window.localStorage.removeItem("fullThisDataSet");
          console.log("REMOVED fullThisDataSet at handleWalletPaymentSessionResult");
    
          if (paymentSession.status === "Approved" || paymentSession.status === "Captured") {
            // Show the customer your order success page
            console.log("I should now show the customer my order success page")
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "PAID! handleWalletPaymentSessionResult, here are the details",
              body: JSON.stringify(paymentSession, null, 2),
            };
            Parse.Cloud.run("sendEmail", params);
            // return;
    
            // so now we need to deal with handleclientsidepaymentintentconfirm - we are going to have to make sure we have access to the parameters which were passed back from runinitiateStripe..
    
            // AND we should make sure this runs identically whether it came through AP route or card... currently card not working because immediately triggers the whole attemptPayment on returning from runinitiateStripe and therefore the handleclientsidepaymentintentconfirm as well
    
            this.doProcessRyftSucceeded(paymentSession);
    
          } else {
            this.doProcessRyftError(undefined, paymentSession);
          }
          if (paymentSession.lastError) {
            const userFacingError = Ryft.getUserFacingErrorMessage(paymentSession.lastError);
            // Show userFacingError to customer
            console.error("Payment error: " + userFacingError);
    
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "ERROR! handlePaymentResult: " + userFacingError,
              body: "" + userFacingError,
            };
            Parse.Cloud.run("sendEmail", params);
    
            throw paymentSession.lastError;
          }
        },
    
        handleCardValidationChanged(e) {
          console.log("cardValidationChanged: " + JSON.stringify(e, null, 2));
          // Access the button via $refs instead of document.getElementById
          // this.$refs.ryftPayBtn.disabled = !e.isValid; // actually we aren't using their button so...
          
          if (e.isValid === true) {
            this.canPay = true;
            
          } else {
            this.canPay = false;
          }
        },
    
        loadFullThisDataSetToThisInstance(fullThisDataSet){
          try {
            if (fullThisDataSet) {
              // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "1    ALERT! Reloading from fullThisDataSet " , body: ""+ JSON.stringify(fullThisDataSet, null, 2), });  
              Object.entries(fullThisDataSet).forEach(([key, value]) => {
                if (key != "tuid" && key !== "showGetTipsHereButton" && key in this) { // first two are props so already assigned, will get falsish fail
                  this[key] = typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value; // in order to make copies of the objects / arrays in fullThisDataSet which is why then changing those Vue instance values will directly change fullThisDataSet - though it didn't actually matter as fullThisDataSet is discarded at the end of this method, it is useful practice to remind oneself about objects and arrays in Javascript being passed by reference
                } 
              });

              // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "2    ALERT! Reloading from fullThisDataSet " , body: ""+ JSON.stringify(fullThisDataSet, null, 2), });  
              if (this.buttonNumEventObject !== undefined) {
                // we had blanked the tipsArray key so reinstate it with a deep copy
                this.buttonNumEventObject.tipsArray = JSON.parse(JSON.stringify(this.tipsArray));
              }  
              
              // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "3    ALERT! Reloading from fullThisDataSet " , body: ""+ JSON.stringify(fullThisDataSet, null, 2), });  
              for (let tip of this.tipsArray) {
                
                // console.log("tip.recipient.objectId: " + tip.recipient.objectId + "   this.recipient.objectId: " + this.recipient.objectId);
                if (tip.recipient.objectId === this.recipient.objectId){
                  // console.log("tip.recipient.recipientImageDataURLL: " + tip.recipient.recipientImageDataURL);
                  this.recipientImageDataURL = tip.recipient.recipientImageDataURL; // fill this one back in too
                  // console.log("this.recipientImageDataURL: " + this.recipientImageDataURL);
                }
              }
              // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "4    ALERT! Reloading from fullThisDataSet " , body: ""+ JSON.stringify(fullThisDataSet, null, 2), });  
              
            }
          } catch (e) {
            console.log("ERROR in loadFullThisDataSetToThisInstance: " + e.message);
            Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ERROR in loadFullThisDataSetToThisInstance: " + e.message, body: "" + e.message, });  
          }
        },
    
    
      },
    
      computed: {
     
      },
      async created () {
        // `this` points to the view model instance
    
        // /console.log("this.devEnv::: " + this.devEnv);
    
        // JUST CHECKING!
    
        calculateLocalStorageUsage();
    
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
    
        if (window.localStorage.getItem("tutws") !== null) {
          this.tippedWithoutScanning = window.localStorage.getItem("tutws");
        }
        // console.log("TIPPING tutws::: " + window.localStorage.getItem("tutws"));
    
        window.localStorage.removeItem("tutws");
    
        window.localStorage.removeItem("tuconnecttovenue");
    
        // window.localStorage.removeItem("tu"); just for deving
        // window.localStorage.removeItem("tuob");
    
        // // /console.log("TIPPING POST tutws::: " + window.localStorage.getItem("tutws"));
    
        if (window.localStorage.getItem("tutws") === null){
          // /console.log("showing connect");
          this.showConnect = true;
        }
    
        // /console.log("tuid::: " + this.tuid);
        let backgroundcolour = '#EDF2F7';
        // document.querySelector('body').style.backgroundColor = backgroundcolour;
        document.body.style.backgroundColor = backgroundcolour;
        // this.getTUID(); now being passed as a property
    
        // console.log("about to check");
        try {
    
          // window.localStorage.removeItem("fullThisDataSet");
          if (window.localStorage.getItem("fullThisDataSet") !== null) {
            console.log("we have fullThisDataSet!");
            let fullThisDataSet = JSON.parse(window.localStorage.getItem("fullThisDataSet"));
            
            if (fullThisDataSet.executeRebuildUserScreenAndAppState !== undefined) {
    
              // at the moment this is set up for Stripe
    
              // if Logrocket not working on App.vue then we at least need to reload it here, because it otherwise won't be (it gets triggered on returned data normally)
              console.log("executeRebuildUserScreenAndAppState was present");
              window.localStorage.removeItem("fullThisDataSet");
              console.log("REMOVED fullThisDataSet at created executeRebuildUserScreenAndAppState");
              console.log("fullThisDataSet::: " + window.localStorage.getItem("fullThisDataSet"));
              this.executeRebuildUserScreenAndAppState = true;

              // Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "PRE    ALERT! Reloading from fullThisDataSet " , body: ""+ JSON.stringify(fullThisDataSet, null, 2), });  

              this.loadFullThisDataSetToThisInstance(fullThisDataSet);  // running PRE and POST versions proved that fullThisDataSet is being passed into loadFullThisDataSetToThisInstance by reference such that buttonNumEventObject key is being augmented by this method to re-include tipsArray key even though

// Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "MID    ALERT! Reloading from fullThisDataSet " , body: ""+ JSON.stringify(fullThisDataSet, null, 2), });  

              this.serializeTheThisDataSet("checking", true); // DEV ONLY just to check what we got

              Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "POST     ALERT! Reloading from fullThisDataSet: " + this.tipper.objectId, body: ""+ JSON.stringify(fullThisDataSet, null, 2), });  
              console.log("RAN sendEmail for Reloading from fullThisDataSet");
              this.getVenueUserThumbnails(this.linkedUsersArrayObject);
              this.hasTriggeredStatusCheck = false; // this gets us moving with the epsilons
              await this.loadStripeObject("deployReturnedData one user"); 
              this.actuallyDoPayment(this.buttonNumEventObject.buttonNum, this.buttonNumEventObject.event, this.lastAmountChosen);  

            }
          } else {
            console.log("Regular route");
            await this.getUser();
            await this.initTipper();
          }
        } catch (e) {
    
         // console.log("catching this...");
          // /console.log("this.tuid::: " + this.tuid);
    
          this.deployLogRocket(this.logRocketOrganisationSlug);
          if (e.message === "This ThankU QR badge is not yet connected to a recipient's account" && 
            window.localStorage.getItem("tuob") !== null && window.localStorage.getItem("tuob") === "4g278vWUb1"){
    
            // /console.log("about to masterregisteringqrcode...");
            window.localStorage.setItem("masterregisteringqrcode", this.tuid);
            this.popUpMsgTitle = "Let's connect";
            this.popUpMsgBody = e.message;
          } else if (e.message === "Received an error with invalid JSON from Parse: Bad Gateway"){
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = "the ThankU app is temporarily offline, we are sorry for the inconvenience - we expect to be back online shortly.";
          } else {
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = "there was an error getting you started, please email this error message to tech@thanku.app: " + e.message;
          }
          
         
          // /console.log("show me a popup...");
          
          this.showPopUpOk = true;
          // console.log("SHOULD NOW BE SHOWING...");
          return;
        }
        
        if (this.executeRebuildUserScreenAndAppState === false){
          this.deployReturnedData(); // is NOT async
          
          if (this.showGetTipsHereButton === true){
            this.doGetTipsHere();
          }
    
          this.doCheckRecipientConnectedAccountStatus();
    
        }
        
        let viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0');
        // this.checkSize();
    
        console.log("finished created()");
          
      },
      mounted(){
    
    
        // console.log("Used storage: " + usedStorage); 
        this.checkForNetworkConnection();
    
        
    
        clearTimeout(window.localStorage.getItem("networkMsgBoxTimeoutId"));
        document.getElementById('networkMsgBox').style.display = 'none';
    
        this.checkForPriorTipValues();
    
    
        // this.makeRecipientCancelImageData();               
    
      },
    })
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    
    
    .testbutton-container {
        /* margin: auto;  */
        position: relative;
        max-height: 6vh;
        height: 6vh;
        overflow: visible; /* margin: auto; */
    
    }
    
    .contain-an-overlaid-button {
        position: relative; /* Makes it a positioning context */
        width: 100%; /* Full width to match the page layout */
    }
    
</style>
    
    